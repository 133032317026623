import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { FC } from "react";

interface WasPriceConfirmationDialogProps {
  open: boolean;
  loading: boolean;
  perks: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const WasPriceConfirmationDialog: FC<WasPriceConfirmationDialogProps> = ({
  open,
  loading,
  perks,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Values will be loaded directly into the Pricing Database and appear on the website. <br />I have verified the
          values are correct.
          <br />
          Was price overrides are {perks ? "for PERKS" : "PUBLIC"}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Let me Check
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
