import React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Tooltip } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (sku: string, wasPrice: number | null) => void;
}

const AddSkuDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const [currentSku, setCurrentSku] = React.useState("");
  const [currentWasPrice, setCurrentWasPrice] = React.useState<number | null>(null);

  const handleConfirm = () => {
    onConfirm(currentSku, currentWasPrice);
    setCurrentSku("");
    setCurrentWasPrice(null);
  };

  let tooltipMessage = "";
  if (currentSku === "") {
    tooltipMessage = "Enter SKU.";
  } else if (currentWasPrice === null) {
    tooltipMessage = "Enter Was Price.";
  } else if (currentWasPrice < 0) {
    tooltipMessage = "Was Price cannot be negative.";
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add SKU</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="SKU"
          type="text"
          fullWidth
          value={currentSku}
          onChange={(e) => setCurrentSku(e.target.value.trim())}
        />
        <TextField
          margin="dense"
          label="Was Price Override"
          type="number"
          fullWidth
          value={currentWasPrice !== null ? currentWasPrice : ""}
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => {
            const value = e.target.value;
            setCurrentWasPrice(value === "" ? null : parseFloat(value));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Tooltip title={tooltipMessage}>
          <span>
            <Button
              disabled={currentSku === "" || currentWasPrice === null || currentWasPrice < 0}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddSkuDialog;
