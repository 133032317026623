import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MPPricingHistory } from "src/models/product/MPPricingHistory";
import { formatCurrency, formatDateOffset } from "src/utils/formatUtil";

const useStyles = makeStyles((theme) => ({
  cardItem: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,

    "& .subtext": {
      color: theme.palette.grey[600],

      "& strong": {
        fontWeight: 500,
      },
    },
  },
}));

type HistoricPricingCardProps = {
  history: MPPricingHistory;
};

export const HistoricPricingCard: React.FC<HistoricPricingCardProps> = ({ history }: HistoricPricingCardProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.cardItem}>
      <Grid item xs={12} component="h3" paddingBottom={0.5}>
        <strong>Seller: </strong>
        {history.seller.name}
      </Grid>

      <Grid item xs={8} className={"subtext"}>
        <strong>From: </strong>
        {formatDateOffset(history.timestamp)}
      </Grid>

      <Grid item xs={4}>
        <strong>Price: </strong>
        {formatCurrency(history.price.displayPriceInc)}
      </Grid>
    </Grid>
  );
};

export default HistoricPricingCard;
