import React from "react";
import { Grid, GridProps } from "@mui/material";

type SpecificationsProps = GridProps & {
  productSpecifications: any[];
};

export const Specifications: React.FC<SpecificationsProps> = ({
  productSpecifications,
  ...rest
}: SpecificationsProps) => {
  return (
    <Grid container {...rest}>
      {productSpecifications.map((specification: any, idx: number) => {
        const isLast = idx === productSpecifications.length - 1;
        return (
          <React.Fragment key={idx}>
            <Grid item xs={6} className={isLast ? "last" : ""}>
              {specification.name}
            </Grid>
            <Grid item xs={6} className={isLast ? "last" : ""}>
              {specification.values.map((v) => v.value).join(", ")}
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
