import { Grid, GridProps } from "@mui/material";
import { InventoryLocationResponse } from "src/models/product";

type StockProps = GridProps & {
  inventoryTotals: InventoryLocationResponse;
};

export const Stock: React.FC<StockProps> = ({ inventoryTotals, ...rest }: StockProps) => {
  return (
    <Grid container {...rest}>
      <Grid item xs={12} component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
        Stock
      </Grid>

      <Grid item xs={9}>
        Available SOH
      </Grid>
      <Grid item xs={3}>
        <strong>{inventoryTotals?.available || 0}</strong>
      </Grid>

      <Grid item xs={9}>
        Total SOH
      </Grid>
      <Grid item xs={3}>
        {inventoryTotals?.soh || 0}
      </Grid>

      <Grid item xs={9}>
        Purchase Order
      </Grid>
      <Grid item xs={3}>
        {inventoryTotals?.purchaseOrder || 0}
      </Grid>

      <Grid item xs={9}>
        Customer Order
      </Grid>
      <Grid item xs={3}>
        {inventoryTotals?.customerOrder || 0}
      </Grid>

      <Grid item xs={9}>
        Repack
      </Grid>
      <Grid item xs={3}>
        {inventoryTotals?.repack || 0}
      </Grid>

      <Grid item xs={9}>
        Faulty
      </Grid>
      <Grid item xs={3}>
        {inventoryTotals?.faulty || 0}
      </Grid>
    </Grid>
  );
};
