import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { COUNTRIES, useCountry } from "../Context/CountryContext";

export const DatePickerProvider: React.FC = ({ children }) => {
  const { country } = useCountry();

  const timeZone = country === COUNTRIES.AU ? "Australia/Sydney" : "Pacific/Auckland";
  const momentInstance = moment.tz.setDefault(timeZone);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={momentInstance}>
      {children}
    </LocalizationProvider>
  );
};
