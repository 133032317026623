import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Route, RouteProps } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "70px 15px 10px 15px",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 15px 10px 15px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "110px 48px 20px 48px",
    },
  },
}));

interface ContainerRouteProps<T> extends RouteProps {
  component: React.ComponentType<T>;
  additionalProps?: T;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const ContainerRoute = <T extends {}>({
  component: Component,
  maxWidth = "lg",
  additionalProps,
  ...rest
}: ContainerRouteProps<T>) => {
  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={() => (
        <Container maxWidth={maxWidth} component="main" className={`${classes.container} v3-container`}>
          <Component {...additionalProps} />
        </Container>
      )}
    />
  );
};
