import React, { useState, useEffect } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import AsnSummary from "./AsnSummary";
import { Grid } from "@mui/material";
import { GetPurchaseOrdersResponse } from "src/models/product";
import { useIsMobile } from "src/hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 400,
    padding: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

type AsnModalProps = {
  purchaseOrder: GetPurchaseOrdersResponse;
  isActive: boolean;
  callback: () => void;
};

const AsnModal: React.FC<AsnModalProps> = ({ purchaseOrder, isActive, callback }: AsnModalProps) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    callback();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  if (!purchaseOrder) {
    return null;
  }

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={handleClose}>
      <DialogTitle>
        <h2>Advanced Shipping Notices</h2>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <h4>Purchase Order: {purchaseOrder.purchaseOrderId}</h4>
            <h4>Vendor: {purchaseOrder.vendorName}</h4>
            <h4>
              Location: {purchaseOrder.locationName} ({purchaseOrder.locationId})
            </h4>
          </Grid>
          <Grid container direction="row" justifyContent="center">
            {purchaseOrder.asns?.map((asn, index) => {
              return <AsnSummary key={asn.id} friendlyId={index + 1} asn={asn} />;
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AsnModal;
