import React from "react";
import { Grid, GridProps } from "@mui/material";

type DimensionsProps = GridProps & {
  productDimensions: any[];
};

export const Dimensions: React.FC<DimensionsProps> = ({ productDimensions, ...rest }: DimensionsProps) => {
  return (
    <Grid container {...rest}>
      {productDimensions.map((dimension: any, idx: number) => (
        <React.Fragment key={idx}>
          {dimension.type && (
            <Grid item xs={12}>
              <strong>{dimension.type}</strong>
            </Grid>
          )}

          <Grid item xs={6}>
            Height
          </Grid>
          <Grid item xs={6}>
            {dimension.height} mm
          </Grid>

          <Grid item xs={6}>
            Width
          </Grid>
          <Grid item xs={6}>
            {dimension.width} mm
          </Grid>

          <Grid item xs={6}>
            Depth
          </Grid>
          <Grid item xs={6}>
            {dimension.depth} mm
          </Grid>

          <Grid item xs={6} className="last">
            Weight
          </Grid>
          <Grid item xs={6} className="last">
            {dimension.weight} kg
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
