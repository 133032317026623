import React from "react";
import { LocalStorageItem } from "./components/LocalStorageItem";
import { getConsts } from "../../utils/consts";

const Settings: React.FC = () => {
  const consts = getConsts();
  
  return (
    <section>
      <h2>Grids</h2>

      <LocalStorageItem
        id={consts.dataGridIds.inventoryDataGridId}
        title="Product Details > Inventory Grid Layout"
        caption="Column Visibility/Size/Layout Settings."
      />

      <LocalStorageItem
        id={consts.dataGridIds.purchaseOrdersDataGridId}
        title="Product Details > Purchase Order Grid Layout"
        caption="Column Visibility/Size/Layout Settings."
      />

      <LocalStorageItem
        id={consts.dataGridIds.promotionsDataGridId}
        title="Product Details > Promotions Grid Layout"
        caption="Column Visibility/Size/Layout Settings."
      />
    </section>
  );
};

export default Settings;
