import React, { useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { ValidationLabel } from "./ValidationLabel";
import { DropzoneContainer, ImageDropzone } from "../FileUpload";
import {uploadMessageImageAsync } from "src/infrastructure/http/appApiService";
import { useCountry } from "../Context/CountryContext";
import { Image } from "../Product/Image";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { DeleteOutline } from "@mui/icons-material";

type ImageUploadFieldProps = {
  value: any;
  onChange: (value: any) => void;
  formats?: string[];
  maxWidth?: number;
  maxHeight?: number;
  helperText?: React.ReactNode;
  exactSize?: boolean;
};

export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  value,
  onChange,
  formats,
  maxWidth,
  maxHeight,
  helperText,
  exactSize,
}: ImageUploadFieldProps) => {

  const [loadingState, setLoadingState] = useState({ isOpen: false, content: "" });
  const [errorMessage, setErrorMessage] = useState("");

  const setIsLoading = (isOpen: boolean, content: string = "") => {
    setLoadingState({ isOpen: isOpen, content: content || "" });
  };

  const handleImageSelected = async (image: File | null) => {
    setErrorMessage("");

    if (!image) {
      onChange(null);
      return;
    }
    onChange({file: image, isNew: true});
  };

  const handleDeleteImageClick = async () => {
    setErrorMessage("");

    if (value?.id) {
      onChange({ ...value, isDeleted: true });
    } else {
      onChange(null);
    }
  };

  const handleValidationError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
  };

  if (loadingState.isOpen) {
    return (
      <DropzoneContainer>
        <CircularProgress size={20} />
        {loadingState.content && <div>{loadingState.content}</div>}
      </DropzoneContainer>
    );
  }

  if (value && !value.isDeleted) {
    return (
      <>
        <DropzoneContainer padding={1}>
        <Image src={value instanceof File ? URL.createObjectURL(value) : value?.url} maxHeight={maxHeight} />
        </DropzoneContainer>
        <Stack direction="row" spacing={1} marginTop={1} justifyContent="space-between">
          <Box flexGrow={1}>
            <ValidationLabel errorMessage={errorMessage} />
            {!errorMessage && helperText && <small>{helperText}</small>}
          </Box>
          <Box marginTop={1} textAlign="center">
            <Button type="linkWithIcon" onClick={handleDeleteImageClick}>
              <DeleteOutline /> Delete
            </Button>
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <ImageDropzone
        formats={formats}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        exactSize={exactSize}
        onImageSelected={handleImageSelected}
        onValidationError={handleValidationError}
      />
      <ValidationLabel errorMessage={errorMessage} />
      {!errorMessage && helperText && <small>{helperText}</small>}
    </>
  );
};
