import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { numberType, dateTimeType } from "src/utils/dataGridFormat";
import useInventoryTransactions from "src/hooks/context/useInventoryTransactions";
import Modal from "src/components/Modal/Modal";
import DataGrid from "./DataGrid";

const columns: GridColDef[] = [
  { field: "id", headerName: "Id" },
  { field: "locationId", headerName: "Location" },
  { field: "timestamp", headerName: "Created", ...dateTimeType },
  { field: "transactionType", headerName: "Type" },
  { field: "operationType", headerName: "Operation" },
  { field: "status", headerName: "Status" },
  { field: "quantity", headerName: "Qty", ...numberType },
  { field: "createdByUserId", headerName: "User Id" },
  { field: "externalItemIdentifier", headerName: "Identifier", flex: 1 },
  { field: "channel", headerName: "Channel", flex: 1 },
];

type InventoryTransactionsProps = {
  sku: string;
  locationId: number;
  isOpen: boolean;
  onClose: () => void;
};

const InventoryTransactionsModal: React.FC<InventoryTransactionsProps> = ({
  sku,
  locationId,
  isOpen,
  onClose,
}: InventoryTransactionsProps) => {
  const { isLoading, data } = useInventoryTransactions(sku, locationId);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal title="Transactions" isOpen={isOpen} primaryButtonName="Ok" onPrimaryButtonClick={onClose}>
      <DataGrid
        rows={data}
        columns={columns}
        isLoading={isLoading}
        containerWidth="70vw"
        containerHeight="65vh"
        columnVisibilityModel={{
          id: false,
          locationId: false,
          operationType: false,
          status: false,
        }}
        hideFooter
      />
    </Modal>
  );
};

export default InventoryTransactionsModal;
