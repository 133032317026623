import React from "react";
import { GenericItem } from "./GenericItem";
import { Button, Grid, Divider } from "@mui/material";

type LocalStorageItemProps = {
  id: string;
  title: string;
  caption: string;
};

const getSettingValue = (id: string): string => {
  try {
    return localStorage.getItem(id);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const LocalStorageItem: React.FC<LocalStorageItemProps> = ({ id, title, caption }: LocalStorageItemProps) => {
  const [settingValue, setSettingValue] = React.useState(getSettingValue(id));

  const onClick = (key: string) => {
    localStorage.removeItem(key);
    setSettingValue(undefined);
  };

  if (!settingValue) {
    return null;
  }

  return (
    <Grid container marginY={2}>
      <Grid item xs={9}>
        <GenericItem title={title} caption={caption} />
      </Grid>

      <Grid item xs={3} textAlign="right">
        <Button onClick={() => onClick(id)}>Reset to Default</Button>
      </Grid>

      <Grid item xs={12} marginTop={1}>
        <Divider />
      </Grid>
    </Grid>
  );
};
