import { Check, Close } from "@mui/icons-material";
import { boolToYesNo } from "src/utils/formatUtil";

type BooleanDisplayProps = {
  value: boolean;
  useIcons?: boolean;
  size?: number;
};

export const BooleanDisplay: React.FC<BooleanDisplayProps> = ({
  value,
  useIcons = false,
  size = 14,
}: BooleanDisplayProps) => {
  if (useIcons) {
    return (
      <div style={{ height: size }}>
        {value ? <Check sx={{ fontSize: size }} /> : <Close sx={{ fontSize: size }} />}
      </div>
    );
  }

  return <>{boolToYesNo(value)}</>;
};
