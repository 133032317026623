import { GridFilterModel, GridLogicOperator } from "@mui/x-data-grid-pro";
import _ from "lodash";

export const upsertFilters = (filters: GridFilterModel, column: string, operator: string, value: any): GridFilterModel => {
  if (!filters) {
    filters = getDefaultFilters();
  }

  let updatedFilters = { ...filters };

  _.remove(updatedFilters.items, (i: any) => {
    return i.field === column;
  });

  let maxId = _.maxBy(updatedFilters.items, 'id')?.id;
  maxId = maxId ? Number(maxId) + 1 : 1;

  updatedFilters.items.push({ id: maxId, field: column, operator: operator, value: value });
  return updatedFilters;
};

export const removeFilters = (filters: GridFilterModel, column: string): GridFilterModel => {
  if (!filters) {
    filters = getDefaultFilters();
  }

  const updatedFilters = { ...filters };

  _.remove(updatedFilters.items, (i: any) => {
    return i.field === column;
  });

  return updatedFilters?.items?.length === 0 ? getDefaultFilters() : updatedFilters;
}

export const getDefaultFilters = (): GridFilterModel => {
  return {
    items: [],
    logicOperator: GridLogicOperator.And
  };
};