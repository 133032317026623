import { GlobalState } from "little-state-machine"
import { ProductList } from "src/models/appData"

export const updateMyLists = (
  state: GlobalState,
  payload: ProductList[]
) => {
  if (!payload) {
    return {
      ...state,
      myLists: []
    };
  }

  return {
    ...state,
    myLists: [
      ...payload
    ]
  };
}
