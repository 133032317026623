import { Grid, GridProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";
import { CurrencyDisplay } from "../CurrencyDisplay";
import Permission from "src/components/Permission/Permission";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { formatDateOffset } from "src/utils/formatUtil";
import { UserProfile } from "src/infrastructure/auth/UserProfile";

type PriceProps = GridProps & {
  product: GetProductResponse;
  userProfile: UserProfile;
};

export const Price: React.FC<PriceProps> = ({ product, userProfile, ...rest }: PriceProps) => {
  return (
    <Grid container {...rest}>
      <Grid item xs={12} component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
        Price
      </Grid>

      <Grid item xs={6} md={8} lg={6}>
        Ticket Price
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        <CurrencyDisplay value={product.pricing.ticketPrice} />
      </Grid>

      {product.pricing.promotionPrice !== null && (
        <>
          <Grid item xs={6} md={8} lg={6}>
            {product.pricing.promotionPrice === product.pricing.endOfLifePrice ? "Clearance Price" : "Promotion Price"}
          </Grid>

          <Grid item xs={6} md={4} lg={6}>
            <CurrencyDisplay as="strong" value={product.pricing.promotionPrice} />
          </Grid>
        </>
      )}

      {!product.isMarketplace && (
        <>
          <Permission userRoles={userProfile.roles} componentName={UserRoles.ProductStaffPrice}>
            <Grid item xs={6} md={8} lg={6}>
              Staff Price
            </Grid>
            <Grid item xs={6} md={4} lg={6}>
              <CurrencyDisplay value={product.pricing.staffPrice} />
            </Grid>
          </Permission>

          <Permission userRoles={userProfile.roles} componentName={UserRoles.ProductGoCost}>
            <Grid item xs={6} md={8} lg={6}>
              Go Cost
            </Grid>
            <Grid item xs={6} md={4} lg={6}>
              <CurrencyDisplay value={product.pricing.goCost} />
            </Grid>
          </Permission>

          <Permission userRoles={userProfile.roles} componentName={UserRoles.ProductGoSell}>
            <Grid item xs={6} md={8} lg={6}>
              Go Sell
            </Grid>
            <Grid item xs={6} md={4} lg={6}>
              <CurrencyDisplay value={product.pricing.goSellPrice} />
            </Grid>
          </Permission>
        </>
      )}

      <Grid item xs={6} md={8} lg={6}>
        Last Updated
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {formatDateOffset(product.pricing.pricingLastUpdated, "DD/MM/YY h:mm A")}
      </Grid>
    </Grid>
  );
};
