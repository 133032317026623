import { useState } from "react";
import { Grid, Link } from "@mui/material";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid-pro";
import { isNumeric } from "validator";
import { GetProductResponse, GetPurchaseOrdersResponse } from "src/models/product";
import DataGrid from "./DataGrid";
import { getConsts } from "src/utils/consts";
import { getDefaultFilters, removeFilters, upsertFilters } from "src/utils/dataGridFilterUtil";
import { TextField } from "src/components/Form/TextField";
import usePurchaseOrders from "src/hooks/http/usePurchaseOrders";
import { dateType, numberType } from "src/utils/dataGridFormat";
import AsnModal from "src/pages/Product/Desktop/components/AsnModal";

type PurchaseOrdersTabProps = {
  product: GetProductResponse;
};

const PurchaseOrdersTab: React.FC<PurchaseOrdersTabProps> = ({ product }: PurchaseOrdersTabProps) => {
  const { isLoading, data } = usePurchaseOrders(product.sku);

  const [locationFilterText, setLocationFilterText] = useState("");
  const [currentPurchaseOrder, setCurrentPurchaseOrder] = useState<GetPurchaseOrdersResponse>();
  const [filters, setFilters] = useState<GridFilterModel>(getDefaultFilters());
  const consts = getConsts();

  const defaultColumns: GridColDef[] = [
    {
      field: "purchaseOrderId",
      headerName: "PO#",
      renderCell: (params) => renderPoNumber(params.value, params.row.numberOfAsns),
    },
    { field: "locationId", headerName: "Loc Id" },
    { field: "locationName", headerName: "Location", flex: 1 },
    { field: "vendorName", headerName: "Supplier", flex: 1 },
    { field: "quantity", headerName: "Qty", ...numberType },
    { field: "receivedQuantity", headerName: "Rec.", ...numberType },
    { field: "remainingQuantity", headerName: "Remain", ...numberType },
    { field: "dateCreated", headerName: "Created", ...dateType },
    { field: "dateIssued", headerName: "Released", ...dateType },
    { field: "dateExpected", headerName: "Expected", ...dateType },
    { field: "status", headerName: "Status" },
  ];

  const renderPoNumber = (purchaseOrderId: string, numberOfAsns: number) => {
    if (numberOfAsns > 0) {
      return (
        <Link color="primary" onClick={() => setPurchaseOrder(purchaseOrderId)}>
          {purchaseOrderId}
        </Link>
      );
    }

    return <span>{purchaseOrderId}</span>;
  };

  const setPurchaseOrder = (purchaseOrderId) => {
    const po = data.find((m) => m.purchaseOrderId === purchaseOrderId);
    setCurrentPurchaseOrder(po);
  };

  const callback = () => {
    setCurrentPurchaseOrder(null);
  };

  const onLocationFilterChange = (text: string) => {
    setLocationFilterText(text);

    setFilters(removeFilters(filters, "locationId"));
    setFilters(removeFilters(filters, "locationName"));

    if (!text) {
      return;
    }

    const columnName = isNumeric(text) ? "locationId" : "locationName";
    setFilters(upsertFilters(filters, columnName, "contains", text.trim()));
  };

  return (
    <>
      <Grid container marginTop={4} marginBottom={2} alignItems="center">
        <Grid item xs={4}>
          <TextField
            label="Search for Location"
            value={locationFilterText}
            onChange={(e) => onLocationFilterChange(e)}
          />
        </Grid>
      </Grid>

      <DataGrid
        isLoading={isLoading}
        dataGridId={consts.dataGridIds.purchaseOrdersDataGridId}
        defaultPageSize={300}
        columns={defaultColumns}
        rows={data}
        getRowId={(row) => row.dataGridRowId}
        filterModel={filters}
        onFilterModelChange={(model) => setFilters(model)}
        hideFooter
      />

      <AsnModal
        purchaseOrder={currentPurchaseOrder}
        isActive={currentPurchaseOrder?.purchaseOrderId ? true : false}
        callback={callback}
      />
    </>
  );
};

export default PurchaseOrdersTab;
