import { useEffect, useState, useRef } from "react";
import { fetchProductDetailAsync } from "../../infrastructure/http/appApiService";
import { useCountry } from "../../components/Context/CountryContext";
import { useCurrentUserStore } from "src/hooks/useCurrentUserStore";
import { GetProductResponse } from "src/models/product";
import { getSubTypeLabel } from "src/models/messaging/MessageSubtypeLabels";
import { MessageSubtypes } from "src/models/messaging/MessageSubtypes";
import { ProductMessageResponse } from "src/models/product/ProductMessageResponse";

export const useProductDetails = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState<GetProductResponse>(null);

  const isMounted = useRef(true);
  const { country } = useCountry();
  const { currentLocationId } = useCurrentUserStore();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) return;
        setIsLoading(true);
        const product = await fetchProductDetailAsync(id, country, currentLocationId);
        if (isMounted.current) { // Ensure the component is still mounted
          const mappedProduct: GetProductResponse = {
            ...product,
            messages: product.messages.map((message: ProductMessageResponse) => ({
              ...message,
              subType: getSubTypeLabel(message.subType as MessageSubtypes)
            }))
          };
          setProduct(mappedProduct);
          setError(null);
          setIsLoading(false);
        }
      } catch (e) {
        if (isMounted.current) { // Ensure the component is still mounted

          setProduct(null);
          setError(e.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id, currentLocationId, country]);

  return { isLoading, error, product };
}