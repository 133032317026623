import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";

interface Props extends Omit<TooltipProps, "children"> {
  children: React.ReactElement;
}

const TooltipWrapper: React.FC<Props> = ({ children, ...tooltipProps }) => {
  return (
    <Tooltip {...tooltipProps}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default TooltipWrapper;
