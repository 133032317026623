import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProductFlagResponse } from "src/models/product/ProductFlagResponse";
import { formatDateOffset } from "src/utils/formatUtil";

const useStyles = makeStyles((theme) => ({
  flagItem: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,

    "& .subtext": {
      color: theme.palette.grey[600],

      "& strong": {
        fontWeight: 500,
      },
    },
  },
}));

type FlagItemProps = {
  flag: ProductFlagResponse;
};

export const FlagItem: React.FC<FlagItemProps> = ({ flag }: FlagItemProps) => {
  const classes = useStyles();

  if (!flag.start && !flag.finish) {
    return (
      <Grid container className={classes.flagItem}>
        <Grid item xs={12} fontWeight="bold">
          {flag.name}
        </Grid>
        <Grid item xs={12} className="subtext" fontWeight="500">
          No specified start or end date
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.flagItem}>
      <Grid item xs={12} fontWeight="bold">
        {flag.name}
      </Grid>
      <Grid item xs={6} className="subtext">
        <strong>Start date:</strong> {formatDateOffset(flag.start, "DD/MM/YYYY")}
      </Grid>
      <Grid item xs={6} className="subtext">
        <strong>End date:</strong> {formatDateOffset(flag.finish, "DD/MM/YYYY")}
      </Grid>
    </Grid>
  );
};
