import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog, Grid, CircularProgress, DialogContent } from "@mui/material";

type LoadingDialogProps = {
  isOpen: boolean;
  message: string;
};

const LoadingDialog: React.FC<LoadingDialogProps> = ({ isOpen, message }: LoadingDialogProps) => {
  return (
    <Dialog aria-labelledby="loading-dialog-title" open={isOpen}>
      <DialogTitle id="loading-dialog-title">Processing</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress size={50} color="secondary" />
          </Grid>
          <Grid item marginX={4}>
            {message}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
