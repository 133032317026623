export const resolvePermission = (permissionName: string, userRoles: string[]): { permissionName?: string, hasPermission: boolean } => {
  if (!userRoles || userRoles.length === 0) {
    return { hasPermission: false };
  }

  const role = userRoles.find(r => r.includes(permissionName));
  return { permissionName: role, hasPermission: !!role };
};

export const hasPermission = (permissionName: string, userRoles: string[]): boolean => {
  const { hasPermission } = resolvePermission(permissionName, userRoles);
  return hasPermission;
};