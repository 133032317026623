import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCountry } from "src/components/Context/CountryContext";
import { fetchMPPricingHistoryAsync } from "src/infrastructure/http/appApiService";
import { GetProductResponse } from "src/models/product";
import { MPPricingHistoryResponse } from "src/models/product/MPPricingHistoryResponse";
import DataGrid from "../Desktop/components/DataGrid";
import { GridColDef } from "@mui/x-data-grid-pro";
import { currencyType, dateTimeType } from "src/utils/dataGridFormat";
import { useIsMobile } from "src/hooks/useIsMobile";
import HistoricPricingCard from "../Mobile/components/HistoricPricingCard";

type HistoricPricingProps = {
  product: GetProductResponse;
};

export const HistoricPricing: React.FC<HistoricPricingProps> = ({ product }: HistoricPricingProps) => {
  const { country } = useCountry();

  const isMobile = useIsMobile();

  const [priceHistory, setHistory] = useState<MPPricingHistoryResponse>();
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "sellerName",
      headerName: "Seller Name",
      valueGetter: ({ row }) => row.seller.name,
      flex: 1,
    },
    {
      field: "timestamp",
      headerName: "From",
      ...dateTimeType,
      flex: 1,
    },
    {
      field: "displayPriceInc",
      headerName: "Price",
      ...currencyType,
      valueGetter: ({ row }) => row.price.displayPriceInc,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    const getResults = async () => {
      try {
        setIsLoading(true);
        const result = await fetchMPPricingHistoryAsync(product.sku, country);
        setHistory(result);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };

    getResults();
  }, []);

  return !isMobile ? (
    <Grid container justifyContent="center" marginTop={5}>
      <Grid item width="50%">
        {priceHistory && (
          <DataGrid
            columns={columns}
            rows={priceHistory.history}
            isLoading={isLoading}
            getRowId={(row) => row.dataGridRowId}
            defaultPageSize={10}
            autoHeight
            hideFooter
          ></DataGrid>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center" marginTop={3}>
      <Grid item>
        {priceHistory && priceHistory.history.map((history) => <HistoricPricingCard history={history} />)}
      </Grid>
    </Grid>
  );
};

export default HistoricPricing;
