import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Accordion, getAccordionItem } from "../../Shared/Accordion";
import { GetProductResponse } from "src/models/product";
import {
  ProductDetails,
  SupplierDetails,
  KeyFeatures,
  Specifications,
  ExtendedDescription,
} from "../../Shared/Details";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginBottom: theme.spacing(3),
  },
  detailsGrid: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    backgroundColor: theme.palette.grey[200],
    maxWidth: "500px",
    marginBottom: theme.spacing(3),

    "& > div": {
      padding: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },

    "& .last": {
      borderBottom: "none",
    },
  },
}));

type DetailsTabProps = {
  product: GetProductResponse;
};

export const DetailsTab: React.FC<DetailsTabProps> = ({ product }: DetailsTabProps) => {
  const classes = useStyles();
  const [activeItems, setActiveItems] = useState([0, 1]);

  const productSpecifications = product.specifications.filter((s: any) => s.isEnabled);

  const accordionItems = [];

  if (productSpecifications && productSpecifications.length > 0) {
    accordionItems.push(
      getAccordionItem(
        "Specifications",
        <Specifications productSpecifications={productSpecifications} className={classes.detailsGrid} />
      )
    );
  }

  if (product.extendedDescription) {
    accordionItems.push(getAccordionItem("Extended Description", <ExtendedDescription product={product} />));
  }

  return (
    <Grid container justifyContent="center" marginTop={4} columns={8}>
      <Grid item md={5} className={classes.gridItem}>
        <ProductDetails product={product} className={classes.detailsGrid} showTitle />
      </Grid>

      {!product.isMarketplace && (
        <Grid item md={5} className={classes.gridItem}>
          <SupplierDetails product={product} className={classes.detailsGrid} showTitle />
        </Grid>
      )}

      {product.keyFeatures && product.keyFeatures.length > 0 && (
        <Grid item md={5} className={classes.gridItem}>
          <KeyFeatures product={product} className={classes.detailsGrid} showTitle />
        </Grid>
      )}

      <Grid item md={5} marginTop={-3}>
        <Accordion
          id="details-accordion"
          items={accordionItems}
          activeState={activeItems}
          onActiveStateChange={setActiveItems}
        />
      </Grid>
    </Grid>
  );
};
