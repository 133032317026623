export enum UserRoles {
  AccessAU = "Access.AU",
  AccessNZ = "Access.NZ",

  ProductDefaultAccess = "Product.DefaultAccess",
  ProductStaffPrice = "Product.StaffPrice",
  ProductGoCost = "Product.GoCost",
  ProductGoSell = "Product.GoSell",
  ProductOnlineDetails = "Product.OnlineDetails",
  ProductPurchaseOrders = "Product.PurchaseOrders",
  ProductManage = "Product.Manage",
  ProductManageReadWrite = "Product.Manage.ReadWrite",
  ProductBrandImages = "Product.BrandImages",
  ProductBrandImagesReadWrite = "Product.BrandImages.ReadWrite",
  ProductSEO = "Product.SEO",
  ProductMessaging = "Product.Messaging",

  PricingWasPrices = "Pricing.WasPrices",
  PricingWasPricesReadWrite = "Pricing.WasPrices.ReadWrite",
  PricingActivePromotions = "Pricing.ActivePromotions",
  PricingFuturePromotions = "Pricing.FuturePromotions",
  PricingExpiredPromotions = "Pricing.ExpiredPromotions",
  PricingHistoricPricing = "Pricing.HistoricPricing",

  InventoryDetails = "Inventory.Details",
  InventoryTotals = "Inventory.Totals",
}
