import React, { FC, useRef } from "react";
import { styled } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";

type DropzoneContainerProps = {
  padding?: number;
};

export const DropzoneContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "padding",
})<DropzoneContainerProps>(({ theme, padding }) => ({
  border: `2px dashed ${theme.palette.grey[300]}`,
  padding: theme.spacing(padding ?? 3),
  cursor: "pointer",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

export type FileDropzoneProps = {
  accept?: string;
  subText?: string;
  onFileSelected: (file: File | null) => void;
};

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    fileInputRef?.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const { files } = event.dataTransfer;
    processFileSelection(files.item(0));
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    processFileSelection(event.target.files[0]);
  };

  const processFileSelection = (file: File | null) => {
    props.onFileSelected(file);
  };

  return (
    <DropzoneContainer onClick={handleClick} onDrop={handleDrop} onDragOver={handleDragOver}>
      <>
        <h3>
          Drag and drop file here or{" "}
          <Button type="link" onClick={handleClick}>
            <h3>select file</h3>
          </Button>
        </h3>
        {props.subText && <small>{props.subText}</small>}
      </>

      <input
        type="file"
        ref={fileInputRef}
        accept={props.accept}
        onChange={onFileSelected}
        style={{ display: "none" }}
      ></input>
    </DropzoneContainer>
  );
};
