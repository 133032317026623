import React from "react";
import { useCountry } from "../Context/CountryContext";
import { TextField } from "../Form/TextField";
import { useIsMobile } from "src/hooks/useIsMobile";

type SearchBoxProps = {
  searchText: string;
  setSearchText: (text: string) => void;
  onSearch: (value: string) => void;
};

export const SearchBox: React.FC<SearchBoxProps> = ({ searchText, setSearchText, onSearch }: SearchBoxProps) => {
  const isMobile = useIsMobile();
  const { country } = useCountry();

  const handleInputChange = (newValue: string) => {
    setSearchText(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch(searchText);
    }
  };

  return (
    <TextField
      id="search-textfield"
      name="default-textfield"
      value={searchText}
      label={isMobile ? `Search ${country}` : "Search By: SKU | Description | Artist | Model | Brand"}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};
