import { IconButton } from "@mui/material";
import { PlaylistAdd } from "@mui/icons-material";

type AddToListButtonProps = {
  onClick: () => void;
  size?: "small" | "medium" | "large";
};

export const AddToListButton: React.FC<AddToListButtonProps> = ({ onClick, size }: AddToListButtonProps) => {
  const handlePinClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the event from bubbling up to the parent, i.e. the row click event on the product cards
    event.stopPropagation();
    onClick();
  };

  return (
    <IconButton size={size} onClick={handlePinClick}>
      <PlaylistAdd />
    </IconButton>
  );
};
