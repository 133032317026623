import { FC } from "react";
import { Box, styled } from "@mui/material";
import Modal from "@jbhi-fi/lanyard-ui/components/Modal/Modal";
import { Button } from "../Form/Button";

export type ConfirmModalState = {
  show: boolean;
  title?: string;
  message?: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export type ConfirmModalProps = {
  state: ConfirmModalState;
};

const StyledModalBodyContainer = styled("div")`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const ConfirmModal: FC<ConfirmModalProps> = ({ state }: ConfirmModalProps) => {
  const onConfirmClick = () => {
    state.onConfirm?.();
  };

  const onCancelClick = () => {
    state.onCancel?.();
  };

  if (!state.show) {
    return <></>;
  }

  return (
    <Modal title={state.title} show={state.show} closable={false} onClose={() => {}}>
      <StyledModalBodyContainer>{state.message}</StyledModalBodyContainer>

      <Box marginTop={4} paddingTop={3} borderTop={1} display="flex" justifyContent="space-between">
        <Button type="link" onClick={onCancelClick}>
          {state.cancelLabel}
        </Button>
        <Button type="primary" onClick={onConfirmClick}>
          {state.confirmLabel}
        </Button>
      </Box>
    </Modal>
  );
};
