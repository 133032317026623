import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { Accordion, getAccordionItem } from "../../Shared/Accordion";
import { Image } from "src/components/Product/Image";
import { GetProductResponse } from "src/models/product";
import {
  ProductDetails,
  SupplierDetails,
  KeyFeatures,
  Specifications,
  ExtendedDescription,
} from "../../Shared/Details";

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,

    "& > div": {
      padding: "2px",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },

    "& .last": {
      borderBottom: "none",
    },
  },
}));

type DetailsTabProps = {
  product: GetProductResponse;
};

export const DetailsTab: React.FC<DetailsTabProps> = ({ product }: DetailsTabProps) => {
  const classes = useStyles();
  const [activeItems, setActiveItems] = useState([0, 1]);

  const productSpecifications = product.specifications.filter((s: any) => s.isEnabled);

  const accordionItems = [
    getAccordionItem("Product Details", <ProductDetails product={product} className={classes.detailsGrid} />),
  ];

  if (!product.isMarketplace) {
    accordionItems.push(
      getAccordionItem("Supplier Details", <SupplierDetails product={product} className={classes.detailsGrid} />)
    );
  }

  if (product.keyFeatures && product.keyFeatures.length > 0) {
    accordionItems.push(
      getAccordionItem("Key Features", <KeyFeatures product={product} className={classes.detailsGrid} />)
    );
  }

  if (productSpecifications && productSpecifications.length > 0) {
    accordionItems.push(
      getAccordionItem(
        "Specifications",
        <Specifications productSpecifications={productSpecifications} className={classes.detailsGrid} />
      )
    );
  }

  if (product.extendedDescription) {
    accordionItems.push(getAccordionItem("Extended Description", <ExtendedDescription product={product} />));
  }

  return (
    <>
      <Box textAlign="center" marginTop={2}>
        <Image src={product?.thumbnailUrl} maxHeight={150} />
      </Box>

      <Accordion
        id="details-accordion"
        items={accordionItems}
        activeState={activeItems}
        onActiveStateChange={setActiveItems}
        withBodyBackground={true}
      />
    </>
  );
};
