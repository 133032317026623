import { useState, useEffect } from "react";
import { fetchReleaseNotesAsync } from "src/infrastructure/http/appApiService";
import { COUNTRIES } from "src/components/Context/CountryContext";

const useReleaseNotes = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        setIsLoading(true);
        const response = await fetchReleaseNotesAsync(COUNTRIES.AU);
        setData(response);
        setIsLoading(false);
      } catch (e) {
        console.log(e.message);
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchReleaseNotes();
  }, []);

  return { isLoading, data, error };
};

export default useReleaseNotes;
