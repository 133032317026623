import { useStateMachine } from "little-state-machine";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, List, ListItem } from "@mui/material";
import { ArrowForwardIos, PlaylistAdd } from "@mui/icons-material";
import { EmptyListState } from "../../../components/EmptyState/EmptyListState";

const useStyles = makeStyles((theme) => ({
  resultList: {
    marginBottom: theme.spacing(1),

    "& li": {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderBottom: 0,
      padding: theme.spacing(1),
    },

    "& li:last-child": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));

type MyListsProps = {
  onRowClick: (row: any) => void;
};

const MyLists: React.FC<MyListsProps> = ({ onRowClick }: MyListsProps) => {
  const classes = useStyles();
  const { state } = useStateMachine();

  if (!state.myLists || state.myLists.length === 0) {
    return (
      <EmptyListState
        icon={PlaylistAdd}
        title="Looks like you have no lists saved..."
        message='To create lists, tap the "add to list" icon at the top of the product detail page for easy access.'
      />
    );
  }

  return (
    <List className={classes.resultList} disablePadding>
      {state.myLists.map((list, idx) => (
        <ListItem key={idx} onClick={() => onRowClick(list)}>
          <Grid container alignItems="center" flexWrap="nowrap">
            <Grid item marginX={1} flexGrow={1}>
              <p>
                {list.name} ({list.numberOfValues})
              </p>
            </Grid>
            <Grid item height={24}>
              <ArrowForwardIos />
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default MyLists;
