import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { ProductDimensionResponse } from "src/models/product/ProductDimensionResponse";

type DesktopDimensionsProps = {
  dimensions: ProductDimensionResponse[];
};

export const DesktopDimensions: React.FC<DesktopDimensionsProps> = ({ dimensions }: DesktopDimensionsProps) => {
  return (
    <Grid container>
      <Grid item xs={12} component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
        Dimensions
      </Grid>
      <Stack
        direction="row"
        sx={{
          maxWidth: "350px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Stack spacing={1}>
          <div>
            <strong>Specs</strong>
          </div>

          <div>Height</div>

          <div>Width</div>

          <div>Depth</div>

          <div>Weight</div>
        </Stack>
        {dimensions
          .filter((d: any) => d.height || d.width || d.depth || d.weight)
          .map((dimension: any, idx: number) => (
            <Stack spacing={1} key={idx}>
              {dimension.type && (
                <div>
                  <strong>{dimension.type}</strong>
                </div>
              )}

              <div>{dimension.height} mm</div>

              <div>{dimension.width} mm</div>

              <div>{dimension.depth} mm</div>

              <div>{dimension.weight} kg</div>
            </Stack>
          ))}
      </Stack>
    </Grid>
  );
};
