import { useState, ReactNode, useEffect, FC } from "react";
import CountryContext, { Country, COUNTRIES } from "./CountryContext";

interface CountryProviderProps {
  children: ReactNode;
}

const CountryProvider: FC<CountryProviderProps> = ({ children }) => {
  const storedCountry = localStorage.getItem("country") as Country;
  const [country, setCountry] = useState<Country>(storedCountry);
  const [canSwitchCountries, setCanSwitchCountries] = useState<boolean>(false);

  useEffect(() => {
    if (country) localStorage.setItem("country", country);
  }, [country]);

  const changeCountry = () => {
    if (!country) return;
    setCountry((prevCountry) => {
      const newCountry = prevCountry === COUNTRIES.NZ ? COUNTRIES.AU : COUNTRIES.NZ;
      window.location.href = `/${newCountry.toLowerCase()}`;
      return newCountry;
    });
  };

  const value = {
    country,
    setCountry,
    canSwitchCountries,
    setCanSwitchCountries,
    changeCountry,
  };

  return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>;
};

export default CountryProvider;
