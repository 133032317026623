import { Grid, Paper, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddToListButton } from "src/components/Product/AddToListButton";
import { Image } from "src/components/Product/Image";
import { PinProductButton } from "src/components/Product/PinProductButton";
import { useIsMobile } from "src/hooks/useIsMobile";
import { SearchResult } from "src/models/search";
import { formatCurrency } from "src/utils/formatUtil";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: (props: any) => (props.isMobile ? "auto" : 200),
    marginTop: 20,
    marginBottom: 20,
    padding: theme.spacing(1.5),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  description: {
    fontWeight: 800,
    fontSize: 16,
  },
  detailsList: {
    fontSize: (props: any) => (props.isMobile ? 12 : 14),

    "& strong": {
      fontWeight: 500,
      marginRight: theme.spacing(0.5),
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  ticketPrice: {
    fontWeight: 400,
    fontSize: (props: any) => (props.isMobile ? 14 : 16),
    position: "absolute",
    bottom: 7,
    right: 12,
  },
}));

type ProductCardProps = {
  product: SearchResult;
  onRowClick: (sku: string) => void;
  onAddToListClick: (sku: string) => void;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onRowClick,
  onAddToListClick,
}: ProductCardProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const Title = () => (
    <Grid container direction="row" justifyContent="center" alignItems="start">
      <Grid item xs={8} sm={9} className={classes.description}>
        {product.description}
      </Grid>
      <Grid item xs={4} sm={3} textAlign="end" whiteSpace="nowrap">
        <PinProductButton sku={product.sku} size="medium" />
        <AddToListButton onClick={() => onAddToListClick(product.sku)} size="medium" />
      </Grid>
    </Grid>
  );

  const Details = () => (
    <>
      <Stack className={classes.detailsList}>
        <div>
          <strong>Sku:</strong>
          {product.sku}
        </div>
        <div>
          <strong>Model:</strong>
          {product.model}
        </div>
        {product.artist && product.department === "MUSIC" && (
          <div>
            <strong>Artist:</strong>
            {product.artist}
          </div>
        )}
        <div>
          <strong>Department:</strong>
          {product.department}
        </div>
        <div>
          <strong>Season:</strong>
          {product.season}
        </div>
        <div>
          <strong>Barcode:</strong>
          {product.barcode}
        </div>
      </Stack>

      <span className={classes.ticketPrice}>{formatCurrency(product.ticketPrice)}</span>
    </>
  );

  return (
    <Paper className={classes.paper} elevation={3} onClick={() => onRowClick(product.sku)}>
      <Title />

      <Grid container alignItems="center" flexGrow={1}>
        <Grid item xs={3} sm={2} md={3}>
          <Image src={product.thumbnailUrl} maxHeight={isMobile ? 65 : 130} />
        </Grid>
        <Grid item xs={9} sm={10} md={9}>
          <Details />
        </Grid>
      </Grid>
    </Paper>
  );
};
