import React, { useState, useEffect, useContext } from "react";
import IdleTimer from "react-idle-timer";
import { Redirect, Router, Switch } from "react-router-dom";
import { withAITracking, useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { LicenseInfo } from "@mui/x-license-pro";
import { JbAppBar } from "./components/AppBar/JbAppBar";
import PageContext from "./components/Context/PageContext";
import { getConsts, setRouterPath } from "./utils/consts";
import { SearchBox } from "./components/AppBar/SearchBox";
import { fetchSearchAsync } from "./infrastructure/http/appApiService";
import { useLoading } from "./hooks/context/useLoading";
import useInitialAppData from "./hooks/context/useInitialAppData";
import { initAppInsight, getReactPlugin, getAppInsights, initializeTelemtry } from "./utils/telemetryService";
import AzureAdApp from "./AzureAdApp";
import { DrawerMenu } from "./components/DrawerMenu/DrawerMenu";
import { ProductDetail } from "./pages/Product/ProductDetail";
import { Splash } from "./components/Loading/Splash";
import Help from "./pages/Help/Help";
import Settings from "./pages/Settings/Settings";
import BrandImages from "./pages/BrandImages/BrandImages";
import { Searching } from "./pages/Searching/Searching";
import AppLogo from "./images/AppLogo";
import ReleaseNotesFull from "./pages/ReleaseNotes/ReleaseNotesFull";
import { ReleaseNotesModal } from "./components/ReleaseNotes/ReleaseNotesModal";
import { useCountry } from "./components/Context/CountryContext";
import WasPrice from "./pages/WasPrice/WasPrices";
import { ContainerRoute } from "./components/Router/ContainerRoute";
import { UserProfile } from "./infrastructure/auth/UserProfile";
import { ProductList } from "./pages/ProductList/ProductList";
import { SearchState, getDefaultSearchState } from "./models/search";
import JBHistoricPricing from "./pages/JBHistoricPricing/JBHistoricPricing";
import { Messaging, MessageDetails } from "./pages/Messaging";

import "./styles/main.css";

const history = createBrowserHistory({});
initAppInsight(history);
const reactPlugin = getReactPlugin();

export type AppProps = {
  logout: () => void;
  error: any;
  userPhoto: string;
  userProfile: UserProfile;
};

const App: React.FC<AppProps> = (props: AppProps) => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIALUIPRO_LICENSE_KEY);

  const { country } = useCountry();

  const [contextData] = useContext(PageContext);
  const { appData } = contextData;

  // Initialize App Insights Telemtry
  if (getAppInsights().queue !== undefined) {
    getAppInsights().queue.push(initializeTelemtry);
  } else {
    initializeTelemtry(
      contextData?.userProfile?.staffId,
      contextData?.userProfile?.displayName,
      contextData?.userProfile?.storeNumber
    );
  }

  const appInsights = useAppInsightsContext();
  const trackList = useTrackEvent(appInsights, "UserListClick", null);
  const trackPins = useTrackEvent(appInsights, "UserPinClick", null);

  const { isLoading } = useInitialAppData();

  // Logout
  let idleTimeout = parseInt(process.env.REACT_APP_IDLE_TIMEOUT) * 1000 * 60;

  // Menu
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Search states
  const pageSize = 10;
  const [searchText, setSearchText] = useState("");
  const [searchState, setSearchState] = useState<SearchState>(getDefaultSearchState());
  const [errorMessage, setErrorMessage] = useState(null);

  const progressBar = useLoading();
  const consts = getConsts();

  const onIdle = () => {
    props.logout();
  };

  // The onScan event is raised by the native mobile app wrapper when a barcode is scanned with the PDA's hardware scanner
  useEffect(() => {
    setRouterPath(country.toLowerCase());
    document.addEventListener("onScan", onScan);
    document.addEventListener("onTrackPins", onTrackPins);
    document.addEventListener("onTrackLists", onTrackLists);

    return () => {
      document.removeEventListener("onScan", onScan);
      document.removeEventListener("onTrackPins", onTrackPins);
      document.removeEventListener("onTrackLists", onTrackLists);
    };
  }, []);

  const onTrackPins = () => {
    trackPins(null);
  };

  const onTrackLists = () => {
    trackList(null);
  };

  // Search functions
  const onScan = async (e) => {
    setSearchText(e.data.BarcodeValue);
    onSearchAsync(e.data.BarcodeValue);
    window.scrollTo(0, 0);
  };

  const onSearchAsync = async (query: string) => {
    if (!query) {
      return;
    }

    try {
      window.scrollTo(0, 0);
      setSearchText(query);
      progressBar.show("Loading...");
      const searchResponse = await fetchSearchAsync(query, 0, 0, pageSize, country);
      setSearchState({
        totalRows: searchResponse.totalRows,
        jbSearchState: {
          ...searchResponse.jbResults,
          page: 0,
        },
        mpSearchState: {
          ...searchResponse.mpResults,
          page: 0,
        },
      });
      history.push(consts.routerPath.home);
    } catch (e) {
      console.log("error: " + e);
      setErrorMessage("An error has occurred while searching, please refresh or try again later.");
    } finally {
      progressBar.hide();
    }
  };

  const onJbPageChangeAsync = async (newJbPage: number) => {
    try {
      const searchResponse = await fetchSearchAsync(
        searchText,
        newJbPage,
        searchState.mpSearchState.page,
        pageSize,
        country
      );
      setSearchState({
        ...searchState,
        jbSearchState: {
          rows: searchResponse.jbResults.rows,
          results: [...searchState.jbSearchState.results, ...searchResponse.jbResults.results],
          page: newJbPage,
        },
      });
    } catch (e) {
      console.log("error: " + e);
      setErrorMessage("An error has occurred while searching, please refresh or try again later.");
    }
  };

  const onMpPageChangeAsync = async (newMpPage: number) => {
    try {
      const searchResponse = await fetchSearchAsync(
        searchText,
        searchState.jbSearchState.page,
        newMpPage,
        pageSize,
        country
      );
      setSearchState({
        ...searchState,
        mpSearchState: {
          rows: searchResponse.mpResults.rows,
          results: [...searchState.mpSearchState.results, ...searchResponse.mpResults.results],
          page: newMpPage,
        },
      });
    } catch (e) {
      console.log("error: " + e);
      setErrorMessage("An error has occurred while searching, please refresh or try again later.");
    }
  };

  const onMenuClick = () => {
    setMenuOpen(true);
  };

  const onMenuItemSelected = (path: string) => {
    setSearchText("");
    setSearchState(getDefaultSearchState());
    setMenuOpen(false);
    history.push(path);
  };

  if (props.error) {
    return (
      <Splash title="Product App" secondaryText="Unknown error occurred." showProgress={false} icon={<AppLogo />} />
    );
  }

  if (isLoading) {
    return <Splash title="Product App" secondaryText="Loading..." showProgress={true} icon={<AppLogo />} />;
  }

  return (
    <IdleTimer onIdle={onIdle} debounce={250} timeout={idleTimeout}>
      <DrawerMenu
        isMenuOpen={isMenuOpen}
        userProfile={contextData.userProfile}
        onDrawerClose={() => setMenuOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        onLogout={() => props.logout()}
      />

      <JbAppBar
        userPhoto={props.userPhoto}
        userProfile={props.userProfile}
        onMenuClick={() => onMenuClick()}
        logout={() => props.logout()}
        searchBar={<SearchBox searchText={searchText} setSearchText={setSearchText} onSearch={onSearchAsync} />}
      >
        <Router history={history}>
          <Switch>
            <Redirect exact from={`/product/:sku`} to={`/${country.toLowerCase()}/product/:sku`} />
            <ContainerRoute
              maxWidth="xl"
              path={consts.routerPath.home}
              exact
              component={Searching}
              additionalProps={{
                pageSize,
                errorMessage,
                searchState,
                onJbPageChange: onJbPageChangeAsync,
                onMpPageChange: onMpPageChangeAsync,
              }}
            />
            <Redirect exact from="/" to={consts.routerPath.home} />
            <ContainerRoute
              maxWidth={false}
              exact path={`${consts.routerPath.product}/:sku`}
              component={ProductDetail}
              additionalProps={{
                userProfile: contextData.userProfile,
              }}
            />
            <ContainerRoute path={`${consts.routerPath.lists}/:id`} component={ProductList} />
            <ContainerRoute path={consts.routerPath.help} component={Help} />
            <ContainerRoute path={consts.routerPath.settings} component={Settings} />
            <ContainerRoute path={consts.routerPath.brandImages} component={BrandImages} />
            <ContainerRoute exact path={consts.routerPath.messaging} component={Messaging} />
            <ContainerRoute exact path={`${consts.routerPath.messaging}/messages`} component={Messaging} />
            <ContainerRoute path={consts.routerPath.newMessage} component={MessageDetails} maxWidth="lg" />
            <ContainerRoute path={consts.routerPath.editMessage} component={MessageDetails} maxWidth="lg" />
            <ContainerRoute path={consts.routerPath.copyMessage} component={MessageDetails} maxWidth="lg" />
            <ContainerRoute maxWidth={false} path={consts.routerPath.wasPrice} component={WasPrice} />
            <ContainerRoute path={consts.routerPath.historicPricing} component={JBHistoricPricing} />
            <ContainerRoute path={consts.routerPath.releaseNotes} component={ReleaseNotesFull} />
          </Switch>
        </Router>
      </JbAppBar>

      <ReleaseNotesModal releaseNotes={appData?.releaseNotes} />
    </IdleTimer>
  );
};

export default AzureAdApp(withAITracking(reactPlugin, App));
