import * as yup from "yup";
import { InferType } from "yup";
import { isGreaterThanOrEqual } from "./dateValidators";
import {
  MessageTypes,
  messageSubtypesOptions,
  messageTypesOptions,
  conditionTypeOptions,
  servicesSubtypesOptions,
  specialOfferSubtypesOptions,
  MessageSubtypes,
} from "src/models/messaging";
import { messageImageSchema } from "./messageImageSchema";
import { messageSpecificationSchema } from "./messageSpecificationSchema";
import { validateDescriptionTags } from "./descriptionValidationUtils";

export const createMessageSchema = yup
  .object({
    id: yup.string().nullable(),
    type: yup
      .string()
      .label("Type")
      .required()
      .oneOf(messageTypesOptions.map((option) => option.value)),
    subType: yup
      .string()
      .label("Subtype")
      .when("type", {
        is: MessageTypes.Message,
        then: (schema) => schema.required().oneOf(messageSubtypesOptions.map((option) => option.value)),
        otherwise: (schema) => schema.notRequired(),
      })
      .when("type", {
        is: MessageTypes.Service,
        then: (schema) => schema.required().oneOf(servicesSubtypesOptions.map((option) => option.value)),
        otherwise: (schema) => schema.notRequired(),
      })
      .when("type", {
        is: MessageTypes.SpecialOffer,
        then: (schema) => schema.required().oneOf(specialOfferSubtypesOptions.map((option) => option.value)),
        otherwise: (schema) => schema.notRequired(),
      }),
    hasDefaultOnlineTag: yup.boolean().default(false),
    cashbackAmount: yup
      .number()
      .label("Cashback amount")
      .when("subType", {
        is: MessageSubtypes.Cashback,
        then: (schema) => schema.required().min(0),
        otherwise: (schema) => schema.notRequired(),
      }),
    name: yup.string().label("Name").required(),
    heading: yup.string().label("Heading").required(),
    shortDescription: yup
      .string()
      .label("Short description")
      .nullable(),
    description: yup
      .string() 
      .label("Description")
      .required()
      .test({
        name: "validate-description-tags",
        message: ({ value }) => {
          const { disclaimerValidation, ctaValidation, chatValidation } = validateDescriptionTags(value);
          if (!disclaimerValidation.isValid || !ctaValidation.isValid || !chatValidation.isValid ) {
            return 'Description is not valid. Please check the errors.';
          }
          return true;
        },
        test: (value) => {
          const { disclaimerValidation, ctaValidation, chatValidation } = validateDescriptionTags(value);
          return disclaimerValidation.isValid && ctaValidation.isValid && chatValidation.isValid;
        },
      }),
    tileImage: messageImageSchema.clone().default(undefined).nullable(),
    heroBannerImage: messageImageSchema.clone().default(undefined).nullable(),
    messageImage: messageImageSchema.clone().default(undefined).nullable(),
    startDate: yup
      .string()
      .label("Start date")
      .nullable(),
    startTime: yup.string().label("Start time"),
    finishDate: yup
      .string()
      .label("Finish date") 
      .nullable()
      .when(
        ["startDate", "startTime", "finishTime"],
        isGreaterThanOrEqual("Finish date & time must be same or after start date & time")
      ),
    finishTime: yup.string().label("Finish time"),
    start: yup.string().nullable(),
    finish: yup.string().nullable(),
    priority: yup.number().label("Priority").required().min(1).max(20),
    onlineTag: yup
      .string()
      .label("Online tag")
      .nullable(),
    conditionTypes: yup
      .array()
      .of(yup.string().oneOf(conditionTypeOptions.map((option) => option.value)))
      .label("Condition types")
      .required(),
    skuConditions: yup.array().default([]),
    specificationConditions: yup.array().of(messageSpecificationSchema.clone()).default([]),
  })
  .required();

export type CreateMessageModel = InferType<typeof createMessageSchema>;
