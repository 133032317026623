import moment from "moment";
import { formatCurrency, formatDateOffset } from "./formatUtil";
import { GridColDef } from "@mui/x-data-grid-pro";

const formatDate = (value: any) => {
  if (!value) {
    return null;
  }

  return moment(value).format("DD/MM/YYYY");
};

const formatDateTime = (value: any) => {
  if (!value) {
    return null;
  }

  return formatDateOffset(value);
};

export const dateType = {
  type: "date",
  valueFormatter: ({ value }) => formatDate(value),
} as GridColDef;

export const dateTimeType = {
  type: "date",
  valueFormatter: ({ value }) => formatDateTime(value),
} as GridColDef;

export const numberType = {
  type: "number",
} as GridColDef;

export const booleanType = {
  type: "boolean",
} as GridColDef;

export const currencyType = {
  type: "number",
  valueFormatter: ({ value }) => formatCurrency(value),
} as GridColDef;
