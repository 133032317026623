import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, LinearProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    height: "100dvh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "42pt",
    fontWeight: "300",
    textAlign: "center",
  },
  bar: {
    backgroundColor: theme.palette.custom.jbAppBar.background,
  },
}));

type SplashProps = {
  title: string;
  icon: React.ReactNode;
  secondaryText: string;
  showProgress: boolean;
};

export const Splash: React.FC<SplashProps> = ({ title, icon, secondaryText, showProgress }: SplashProps) => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" rowSpacing={4}>
        <Grid item xs={12}>
          <div className={classes.title}>{title}</div>
        </Grid>

        <Grid item xs={12} textAlign="center">
          {icon}
        </Grid>

        {showProgress && (
          <Grid item xs={10}>
            <LinearProgress className={classes.bar} />
          </Grid>
        )}

        {secondaryText && (
          <Grid item xs={12} textAlign="center" style={{ overflowWrap: "break-word" }}>
            {secondaryText}
          </Grid>
        )}
      </Grid>
    </main>
  );
};
