import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { Box, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  progress: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(2),

    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.yellow,
    },
  },
}));

type PagingProps = {
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (newPage: number) => void;
};

export const Paging: React.FC<PagingProps> = ({ page, pageSize, rowCount, onPageChange }: PagingProps) => {
  const classes = useStyles();

  let currentRowCount = (page + 1) * pageSize;
  if (currentRowCount > rowCount) {
    currentRowCount = rowCount;
  }

  const progress = (currentRowCount / rowCount) * 100;

  return (
    <Box justifyContent="center" textAlign="center">
      <small>
        Showing {currentRowCount} of {rowCount}
      </small>
      <LinearProgress variant="determinate" value={progress} className={classes.progress} />

      {currentRowCount < rowCount && (
        <Button type="primary" onClick={() => onPageChange(page + 1)} style={{ width: "auto" }}>
          Load more
        </Button>
      )}
    </Box>
  );
};
