import { styled } from "@mui/material";
import DOMPurify from "dompurify";

const StyledHtmlContainer = styled("div")`
  * {
    margin: 0;
    padding: 0;
  }

  p, hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

interface SanitizeHtmlProps extends React.HTMLAttributes<HTMLDivElement> {
  html: string;
  options?: any;
}

export const SanitizeHtml = ({ html, options, ...props }: SanitizeHtmlProps) => {
  const defaultOptions = {
    ALLOWED_TAGS: ["p", "b", "strong", "i", "em", "a", "br", "hr"],
    ALLOWED_ATTR: ["href"],
  };

  const sanitize = (dirty, options) => ({
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
  });

  return <StyledHtmlContainer dangerouslySetInnerHTML={sanitize(html, options)} {...props} />;
};
