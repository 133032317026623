import { Moment } from "moment";

type TooltipParams = {
  values: any[];
  endDate: Moment | null;
  startDate: Moment | null;
  comment: string;
  errors: string[];
};

export const useTooltipMessages = (params: TooltipParams) => {
  let generateTooltipMessage = [];

  if (params.values.length === 0) {
    generateTooltipMessage.push("Add SKUs and WasPrices");
  }

  if (params.startDate == null) {
    generateTooltipMessage.push("Choose a Start Date");
  }

  if (params.endDate == null) {
    generateTooltipMessage.push("Choose an End Date");
  }

  if (params.comment === "") {
    generateTooltipMessage.push("Add a comment");
  }

  if (params.errors.length > 0) {
    generateTooltipMessage = [...generateTooltipMessage, ...params.errors];
  }

  const noSkusTooltip =
    params.values.length === 0 ? "Add some SKUs using the Bulk Upload or Add Sku before proceeding" : "";

  const generateTooltip =
    params.values.length === 0 || !params.endDate || !params.startDate || params.comment === "" ? (
      <ul>
        {generateTooltipMessage.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    ) : null;

  return {
    noSkusTooltip,
    generateTooltip,
  };
};
