type CurrencyDisplayProps = {
  as?: keyof JSX.IntrinsicElements;
  value: number;
  [key: string]: any; // To allow any other prop
};

export const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({
  as: Element = "div",
  value,
  ...props
}: CurrencyDisplayProps) => {
  if (!value) {
    return <Element {...props}></Element>;
  }

  return <Element {...props}>${value.toFixed(2)}</Element>;
};
