import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { GetProductResponse} from "src/models/product";
import { boolToYesNo, formatDateOffset, pascalEnumToString } from "src/utils/formatUtil";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { FlagItem } from "./FlagItem";
import DataGrid from "../../Desktop/components/DataGrid";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useState } from "react";
import React from "react";
import { dateTimeType } from "src/utils/dataGridFormat";
import { EditOutlined } from "@mui/icons-material";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { useIsMobile } from "src/hooks/useIsMobile";
import { ConfirmModal, ConfirmModalState } from "src/components/Modal";
import { useHistory } from "react-router-dom";
import { getConsts } from "src/utils/consts";

type OnlineTabProps = {
  product: GetProductResponse;
  userProfile: UserProfile;
};

const useStyles = makeStyles((theme) => ({
  desktopGrid: {
    alignContent: "flex-start",

    [theme.breakpoints.up("md")]: {
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(3),
    },

    "& .flags div:last-child": {
      borderBottom: "none !important",
    },
  },
  availabilityGrid: {
    "& h3": {
      marginBottom: `${theme.spacing(2)} !important`,
    },

    "& div": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1),

        "&.last": {
          borderBottom: "none !important",
        },
      },

      [theme.breakpoints.down("md")]: {
        background: theme.palette.grey[200],
        padding: theme.spacing(0.5),

        "&:nth-child(2)": {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
        },

        "&:nth-child(3)": {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
        },

        "&:nth-child(even)": {
          borderRight: "none",
          borderLeft: `1px solid ${theme.palette.grey[400]}`,
        },

        "&:nth-child(odd)": {
          borderLeft: "none",
          borderRight: `1px solid ${theme.palette.grey[400]}`,
        },
      },
    },
  },
  onlineSubtitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: `${theme.spacing(2)} !important`,
    marginTop: `${theme.spacing(2)} !important`,

    "& svg": {
      marginRight: theme.spacing(0.5),
    },

    "& .active": {
      color: theme.palette.secondary.main,
    },
  },
}));

export const OnlineTab: React.FC<OnlineTabProps> = ({ product, userProfile }: OnlineTabProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const consts = getConsts();

  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({
    title: "Are you sure you want to edit this template for all SKUs?",
    message: (
      <>
        <strong>Please note:</strong> This is an <i>active</i> template. Editing it will affect the template for all the
        SKUs associated with this. Are you sure you want to edit it?
      </>
    ),
    show: false,
  });

  const isMobile = useIsMobile();

  const templateColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "subType",
      headerName: "Subtype",
      flex: 1,
    },
    {
      field: "start",
      headerName: "Start",
      ...dateTimeType,
      flex: 1.5,
    },
    {
      field: "finish",
      headerName: "Finish",
      ...dateTimeType,
      flex: 1.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {hasPermission(UserRoles.ProductMessaging, userProfile.roles)  && (
                <Button
                type={"iconOnly"}
                icon={<EditOutlined />}
                onClick={() => {
                  setConfirmModalState({
                    ...confirmModalState,
                    show: true,
                    confirmLabel: "Yes",
                    cancelLabel: "No, go back",
                    onConfirm: () => {
                      history.push(`/${consts.page.messaging}/${row.id}/edit`);
                    },
                    onCancel: () => {
                      setConfirmModalState({ ...confirmModalState, show: false });
                    },
                  });
                }}
              />
            )} 
          </>
        );
      },
    },
  ];

  const activeFlags = product.flags.filter((m) => m.isActive);
  const inActiveFlags = product.flags.filter((m) => !m.isActive);

  return (
    <Grid container marginTop={{ md: 2 }} alignItems="start" justifyContent="space-evenly">
      {!product.isMarketplace && (
        <Grid
          container
          item
          xs={12}
          md={5}
          lg={4}
          marginTop={2}
          className={`${classes.desktopGrid} ${classes.availabilityGrid}`}
        >
          <Grid item xs={12} component="h3">
            Availability
          </Grid>

          <Grid item xs={6}>
            Overall Status
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.overallStatus)}
          </Grid>

          <Grid item xs={6}>
            Can Buy Online
          </Grid>
          <Grid item xs={6}>
            {boolToYesNo(product.availability.canBuyOnline)}
          </Grid>

          <Grid item xs={6}>
            Product Lifecycle
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.productLifecycle)}
          </Grid>

          <Grid item xs={6}>
            Delivery Status
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.deliveryStatus)}
          </Grid>

          <Grid item xs={6}>
            Cash & Carry Status
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.cashNcarryStatus)}
          </Grid>

          <Grid item xs={6}>
            Delivery From Online Warehouse Only
          </Grid>
          <Grid item xs={6}>
            {boolToYesNo(product.availability.deliveryFromOnlineWarehouseOnly)}
          </Grid>

          <Grid item xs={6}>
            Last Updated
          </Grid>
          <Grid item xs={6}>
            {formatDateOffset(product.availability.timestamp, "DD/MM/YYYY HH:MM a")}
          </Grid>

          <Grid item xs={6}>
            Display Product
          </Grid>
          <Grid item xs={6}>
            {boolToYesNo(product.availability.displayProduct)}
          </Grid>

          <Grid item xs={6}>
            Fulfilment
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.fulfilment)}
          </Grid>

          <Grid item xs={6}>
            Delivery Constraint
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.deliveryConstraint)}
          </Grid>

          <Grid item xs={6}>
            Click & Collect Status
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.clickNcollectStatus)}
          </Grid>

          <Grid item xs={6}>
            Ranging
          </Grid>
          <Grid item xs={6}>
            {pascalEnumToString(product.availability.ranging)}
          </Grid>

          <Grid item xs={6} className="last">
            Can Pre Order
          </Grid>
          <Grid item xs={6} className="last">
            {boolToYesNo(product.availability.canPreOrder)}
          </Grid>
        </Grid>
      )}
      {!product.isMarketplace && (
        <Grid container item xs={12} md={5} lg={4} marginTop={2} className={classes.desktopGrid}>
          <Grid item xs={12} component="h3">
            Flags
          </Grid>

          <Grid item xs={12} component="h3" marginTop={0} className={classes.onlineSubtitle}>
            <CheckCircleOutlineIcon className="active" />
            Active
          </Grid>

          <Grid item xs={12} className="flags">
            {activeFlags.map((flag, index) => (
              <FlagItem key={index} flag={flag} />
            ))}
          </Grid>

          <Grid item xs={12} component="h3" marginTop={2} className={classes.onlineSubtitle}>
            <BlockIcon />
            Not Active
          </Grid>

          <Grid item xs={12} className="flags">
            {inActiveFlags.map((flag, index) => (
              <FlagItem key={index} flag={flag} />
            ))}
          </Grid>
        </Grid>
      )}

      {!isMobile && product?.messages.length > 0 && (
        
        <Grid container item xs={8} marginTop={2}>
          <Grid item xs={12} component="h3">
            Product Messaging
          </Grid>
          <DataGrid
            columns={templateColumns}
            rows={product.messages} 
            isLoading={isLoading}
            getRowId={(row) => row.id}
            hideFooter
            autoHeight
            slots={{
              toolbar: null,
            }}
            marginTop={2}
          />
        </Grid>
      )}
      <ConfirmModal state={confirmModalState} />
    </Grid>
  );
};

export default OnlineTab;
