import React from "react";
import { Box, BoxProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";
import { SanitizeHtml } from "../SanitizeHtml";

type ExtendedDescriptionProps = BoxProps & {
  product: GetProductResponse;
};

export const ExtendedDescription: React.FC<ExtendedDescriptionProps> = ({
  product,
  ...rest
}: ExtendedDescriptionProps) => {
  return (
    <Box paddingX={0.5} marginY={0.5} {...rest}>
      <SanitizeHtml html={product.extendedDescription} />
    </Box>
  );
};
