export enum MessageTypes {
  Message = "Messages",
  SpecialOffer = "SpecialOffers",
  Service = "Services",
}

export const messageTypesOptions = [
  { value: MessageTypes.Message, label: "Message" },
  { value: MessageTypes.SpecialOffer, label: "Special Offer" },
  { value: MessageTypes.Service, label: "Service" },
];
