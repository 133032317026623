import { ReactNode, FC } from "react";
import { resolvePermission } from "src/infrastructure/auth/PermissionUtils";

type PermissionProps = {
  userRoles: string[];
  componentName: string;
  children?: ReactNode;
};

const Permission: FC<PermissionProps> = ({ userRoles, componentName, children }: PermissionProps) => {
  const { permissionName, hasPermission } = resolvePermission(componentName, userRoles);

  if (!hasPermission) {
    return null;
  }

  if (permissionName.endsWith(".Read")) {
    return <div style={{ pointerEvents: "none", opacity: "0.4" }}>{children}</div>;
  }

  return <>{children}</>;
};

export default Permission;
