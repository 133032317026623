import { Badge } from "@mui/material";
import React from "react";

type ProductCountBadgeProps = {
  count: number;
  marginX?: number;
};

export const ProductCountBadge: React.FC<ProductCountBadgeProps> = (props: ProductCountBadgeProps) => {
  return (
    <Badge
      color="info"
      badgeContent={props.count}
      sx={{ marginX: props.marginX }}
      slotProps={{
        badge: {
          style: {
            position: "relative",
            backgroundColor: "#005EAB",
            transform: "none",
            transition: "none",
          },
        },
      }}
      showZero
    />
  );
};
