const product = "product";
const results = "results";
const lists = "lists";
const help = "help";
const settings = "settings";
const brandImages = "brandImages";
const wasPrice = "wasPrice";
const releaseNotes = "releaseNotes";
const historicPricing = "historicPricing";
const messaging = "messaging";
const messages = "messages";
let country = "";

const createConsts = (country) => {
  const routerPaths = {
    home: `/${country}`,
    product: `/${country}/${product}`,
    results: `${country}/${results}`,
    lists: `/${lists}`,
    help: `/${help}`,
    settings: `/${settings}`,
    brandImages: `/${country}/${brandImages}`,
    wasPrice: `/${country}/${wasPrice}`,
    releaseNotes: `/${releaseNotes}`,
    historicPricing: `/${country}/${historicPricing}`,
    messaging: `/${country}/${messaging}`,
    newMessage: `/${country}/${messaging}/${messages}/new`,
    editMessage: `/${country}/${messaging}/${messages}/:id/edit`,
    copyMessage: `/${country}/${messaging}/${messages}/:id/copy`
  };

  const page = {
    home: `/${country}`,
    product: `/${country}/${product}`,
    results: `${country}/${results}`,
    lists: `${lists}`,
    help: `${help}`,
    settings: `${settings}`,
    brandImages: `${country}/${brandImages}`,
    wasPrice: `${country}/${wasPrice}`,
    historicPricing: `${country}/${historicPricing}`,
    releaseNotes: `${releaseNotes}`,
    messaging: `${country}/${messaging}/${messages}`,
  };

  return {
    apiProcessWaitTime: [1000, 1000, 1000, 1000, 1000],
    page: page,
    routerPath: routerPaths,
    dataGridIds: {
      inventoryDataGridId: "inventoryDataGrid",
      purchaseOrdersDataGridId: "purchaseOrdersDataGrid",
      promotionsDataGridId: "promotionsDataGrid",
    },
  };
};

let consts = createConsts(country);

export const setRouterPath = (c) => {
  country = c;
  consts = createConsts(c);
};

export const getConsts = () => consts;
