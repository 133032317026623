import usePromos from "src/hooks/http/usePromos";
import { GetProductResponse } from "src/models/product";
import { dateType, currencyType } from "src/utils/dataGridFormat";
import DataGrid from "./DataGrid";
import { GridColDef } from "@mui/x-data-grid-pro";
import { getConsts } from "src/utils/consts";

type PromotionTabProps = {
  product: GetProductResponse;
};

const PromotionTab: React.FC<PromotionTabProps> = ({ product }: PromotionTabProps) => {
  const { isLoading, data } = usePromos(product.sku);
  const consts = getConsts();

  const promoColumns: GridColDef[] = [
    { field: "id", headerName: "Id" },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "dateFrom", headerName: "From", ...dateType },
    { field: "dateTo", headerName: "To", ...dateType },
    { field: "price", headerName: "Price", ...currencyType },
  ];

  return (
    <DataGrid
      isLoading={isLoading}
      dataGridId={consts.dataGridIds.promotionsDataGridId}
      defaultPageSize={100}
      marginTop={4}
      columns={promoColumns}
      rows={data}
      hideFooter
    />
  );
};

export default PromotionTab;
