import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CopyLinkMenuItem } from "../MenuItems/CopyLinkMenuItem";
import { OpenLinkMenuItem } from "../MenuItems/OpenLinkMenuItem";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { COUNTRIES } from "../Context/CountryContext";
import { isAndroid } from "react-device-detect";

type ProductActionMenuProps = {
  sku: string;
};

export const ProductActionMenu: React.FC<ProductActionMenuProps> = ({ sku }: ProductActionMenuProps) => {
  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="actions"
        aria-haspopup="true"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <MoreVert color="inherit" />
      </IconButton>

      <Menu
        id="actions-menu"
        aria-labelledby="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: { paddingY: 0 },
        }}
      >
        <CopyLinkMenuItem />
        {shouldHideForNZ && !isAndroid && (
          <OpenLinkMenuItem text="View product on JB website" url={`https://jbhi.fi/${sku}`} />
        )}
      </Menu>
    </>
  );
};
