import React from "react";
import { UserProfile } from "src/infrastructure/auth/UserProfile";

type ContextDataType = {
  accessToken?: any;
  appData?: any;
  userSettings?: any;
  userProfile?: UserProfile;
};

type PageContextType = [ContextDataType, React.Dispatch<React.SetStateAction<ContextDataType>>];

const PageContext = React.createContext<PageContextType>([{}, () => { }]);

export default PageContext;
