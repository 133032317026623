import { useState } from "react";
import { Grid } from "@mui/material";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid-pro";
import Checkbox from "@jbhi-fi/lanyard-ui/components/Checkbox";
import { isNumeric } from "validator";
import { GetProductResponse } from "src/models/product";
import DataGrid from "./DataGrid";
import { booleanType, numberType } from "src/utils/dataGridFormat";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { COUNTRIES } from "src/components/Context/CountryContext";
import { getConsts } from "src/utils/consts";
import InventoryTransactionsModal from "./InventoryTransactionsModal";
import { getDefaultFilters, removeFilters, upsertFilters } from "src/utils/dataGridFilterUtil";
import { TextField } from "src/components/Form/TextField";

type TransactionsModalState = {
  isOpen: boolean;
  locationId?: number;
  sku?: string;
};

type SohTabProps = {
  product: GetProductResponse;
};

const SohTab: React.FC<SohTabProps> = ({ product }: SohTabProps) => {
  const [locationFilterText, setLocationFilterText] = useState("");
  const [filters, setFilters] = useState<GridFilterModel>(getDefaultFilters());
  const [availableOnly, setAvailableOnly] = useState(false);
  const [warehouseOnly, setWarehouseOnly] = useState(false);
  const [transactionsModalState, setTransactionsModalState] = useState<TransactionsModalState>({ isOpen: false });

  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);
  const consts = getConsts();

  const defaultColumns: GridColDef[] = [
    {
      field: "locationId",
      headerName: "Id",
      width: 60,
    },
    {
      field: "locationName",
      headerName: "Location",
      width: 180,
    },
    {
      field: "available",
      headerName: "Avail Soh",
      ...numberType,
    },
    {
      field: "soh",
      headerName: "Soh",
      ...numberType,
    },
    {
      field: "inTransit",
      headerName: "In Transit",
      ...numberType,
    },
    {
      field: "purchaseOrder",
      headerName: "Po",
      ...numberType,
    },
    {
      field: "customerOrder",
      headerName: "Co",
      ...numberType,
    },
    {
      field: "faulty",
      headerName: "Faulty",
      ...numberType,
    },
    {
      field: "jbrl",
      headerName: "SOR",
      ...numberType,
    },
    {
      field: "fraudCheck",
      headerName: "Fraud Check",
      ...numberType,
    },
    {
      field: "pendingAdjustSub",
      headerName: "Adjust-",
      ...numberType,
    },
    {
      field: "display",
      headerName: "Display",
      ...numberType,
    },
    {
      field: "repack",
      headerName: "Repack",
      ...numberType,
    },
    {
      field: "transferOut",
      headerName: "Trf Out",
      ...numberType,
    },
    {
      field: "pendingCredit",
      headerName: "Pending Credit",
      ...numberType,
    },
    {
      field: "pendingReceive",
      headerName: "Receive+",
      ...numberType,
    },
    {
      field: "buffer",
      headerName: "Buffer",
      ...numberType,
    },
    shouldHideForNZ
      ? {
          field: "isRanged",
          headerName: "Ranged",
          ...booleanType,
        }
      : null,
    {
      field: "state",
      headerName: "State",
    },
    {
      field: "areaManager",
      headerName: "Area Manager",
    },
    {
      field: "locationType",
      headerName: "Type",
    },
    {
      field: "distanceKms",
      headerName: "KMs",
      ...numberType,
    },
    {
      field: "isAvailableForDelivery",
      headerName: "Is Avail Delivery",
      ...booleanType,
    },
    {
      field: "isAvailableForClickAndCollect",
      headerName: "Is Avail Click & Collect",
      ...booleanType,
    },
    {
      field: "isAvailableForCashAndCarry",
      headerName: "Is Avail Cash & Carry",
      ...booleanType,
    },
    {
      field: "availableForDelivery",
      headerName: "Avail Delivery",
      ...numberType,
    },
    {
      field: "availableForClickAndCollect",
      headerName: "Avail Click & Collect",
      ...numberType,
    },
    {
      field: "availableForCashAndCarry",
      headerName: "Avail Cash & Carry",
      ...numberType,
    },
    {
      field: "onlineAvailable",
      headerName: "Avail Online",
      ...numberType,
    },
  ];

  const inventoryColumns = defaultColumns.filter((c) => c !== null);

  const onCellClick = (e: any) => {
    setTransactionsModalState({ isOpen: true, sku: product.sku, locationId: e.row.locationId });
  };

  const onLocationFilterChange = (text: string) => {
    setLocationFilterText(text);

    setFilters(removeFilters(filters, "locationId"));
    setFilters(removeFilters(filters, "locationName"));

    if (!text) {
      return;
    }

    const columnName = isNumeric(text) ? "locationId" : "locationName";
    setFilters(upsertFilters(filters, columnName, "contains", text.trim()));
  };

  const onAvailableOnlyChange = (e) => {
    const value = e.target.checked;
    setAvailableOnly(value);

    if (value) {
      setFilters(upsertFilters(filters, "available", ">=", "1"));
    } else {
      setFilters(removeFilters(filters, "available"));
    }
  };

  const onWarehouseOnlyChange = (e) => {
    const value = e.target.checked;
    setWarehouseOnly(value);

    if (value) {
      setFilters(upsertFilters(filters, "locationType", "contains", "Warehouse"));
    } else {
      setFilters(removeFilters(filters, "locationType"));
    }
  };

  return (
    <>
      <Grid container marginTop={4} marginBottom={2} alignItems="center">
        <Grid item xs={4}>
          <TextField
            label="Search for Location"
            value={locationFilterText}
            onChange={(e) => onLocationFilterChange(e)}
          />
        </Grid>

        <Grid item marginX={4}>
          <Checkbox
            id="available-only"
            name="available-only"
            label="Available Only"
            checked={availableOnly}
            onChange={onAvailableOnlyChange}
          />
        </Grid>

        <Grid item marginX={4}>
          <Checkbox
            id="warehouse-only"
            name="warehouse-only"
            label="Warehouse Only"
            checked={warehouseOnly}
            onChange={onWarehouseOnlyChange}
          />
        </Grid>
      </Grid>

      <DataGrid
        isLoading={false}
        dataGridId={consts.dataGridIds.inventoryDataGridId}
        defaultPageSize={300}
        columns={inventoryColumns}
        rows={product.inventory}
        getRowId={(row) => row.locationId}
        onCellClick={onCellClick}
        filterModel={filters}
        onFilterModelChange={(model) => setFilters(model)}
        initialState={{
          pinnedColumns: {
            left: ["locationId", "locationName"],
          },
        }}
        hideFooter
      />

      <InventoryTransactionsModal
        sku={transactionsModalState.sku}
        locationId={transactionsModalState.locationId}
        isOpen={transactionsModalState.isOpen}
        onClose={() => setTransactionsModalState({ isOpen: false })}
      />
    </>
  );
};

export default SohTab;
