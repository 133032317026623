import React, { useState } from "react";
import { Box, BoxProps, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Tab, Tabs } from "@jbhi-fi/lanyard-ui/components/Tabs";
import { PinProductButton } from "src/components/Product/PinProductButton";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { GetProductResponse } from "src/models/product";
import { ProductActionMenu } from "src/components/Product/ProductActionMenu";
import { TabTitle } from "../Shared/TabTitle";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { MyListsSelect } from "src/components/Lists/MyListsSelect";
import { Image } from "src/components/Product/Image";
import { General, InventoryTotals, Price, Stock } from "../Shared/ProductInfo";
import { useCurrentUserStore } from "src/hooks/useCurrentUserStore";
import { AddToListButton } from "src/components/Product/AddToListButton";
import PromotionTab from "./components/PromotionTab";
import { ProductManagementTab } from "./components/ProductManagementTab";
import SohTab from "./components/SohTab";
import PurchaseOrdersTab from "./components/PurchaseOrdersTab";
import { OnlineTab } from "../Shared/Online/OnlineTab";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { COUNTRIES } from "src/components/Context/CountryContext";
import { DetailsTab } from "./components/DetailsTab";
import HistoricPricing from "../Shared/HistoricPricing";
import { MarketplaceTag } from "src/components/Product/MarketplaceTag";
import { DesktopDimensions } from "./components/DesktopDimensions";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: `${theme.spacing(1)} !important`,
    marginBottom: `${theme.spacing(1)} !important`,
  },
}));

enum DesktopProductTab {
  SOH = "SOH",
  PurchaseOrders = "PurchaseOrders",
  Promotions = "Promotions",
  Online = "Online",
  Details = "Details",
  Manage = "Manage",
  PricingHistory = "PricingHistory",
}

type DesktopProductDetailProps = {
  product: GetProductResponse;
  userProfile: UserProfile;
};

export const DesktopProductDetail: React.FC<DesktopProductDetailProps> = ({
  product,
  userProfile,
}: DesktopProductDetailProps) => {
  const classes = useStyles();
  const [myListsOpen, setMyListsOpen] = useState(false);

  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);
  const { inventoryLocationId, isSupportOffice } = useCurrentUserStore();
  const inventoryTotalForLocation = product?.inventory?.find((m) => m.locationId === inventoryLocationId);

  const hasInventoryTotalsPermission = hasPermission(UserRoles.InventoryTotals, userProfile.roles);
  const numberOfColumns = hasInventoryTotalsPermission ? 2 : 3;
  const shouldShowLocalStock = !product.isMarketplace && !isSupportOffice;

  const productTabs = [
    !product.isMarketplace
      ? {
          tab: DesktopProductTab.SOH,
          title: "SOH",
          permission: null,
          component: <SohTab product={product} />,
        }
      : null,
    shouldHideForNZ && !product.isMarketplace
      ? {
          tab: DesktopProductTab.PurchaseOrders,
          title: "Purchase Orders",
          shortTitle: "PO",
          minWidth: 160,
          permission: UserRoles.ProductPurchaseOrders,
          component: <PurchaseOrdersTab product={product} />,
        }
      : null,
    !product.isMarketplace
      ? {
          tab: DesktopProductTab.Promotions,
          title: "Promotions",
          permission: null,
          component: <PromotionTab product={product} />,
        }
      : null,
    {
      tab: DesktopProductTab.Online,
      title: "Online",
      permission: UserRoles.ProductOnlineDetails,
      component: <OnlineTab product={product} userProfile={userProfile} />,
    },
    {
      tab: DesktopProductTab.Details,
      title: "Details",
      permission: null,
      component: <DetailsTab product={product} />,
    },
    shouldHideForNZ && !product.isMarketplace
      ? {
          tab: DesktopProductTab.Manage,
          title: "Manage",
          permission: UserRoles.ProductSEO,
          component: <ProductManagementTab product={product} />,
        }
      : null,
    product.isMarketplace
      ? {
          tab: DesktopProductTab.PricingHistory,
          title: "Pricing History",
          permission: null,
          component: <HistoricPricing product={product} />,
        }
      : null,
  ].filter(Boolean);

  const boxProps: BoxProps = {
    padding: 2,
    flex: 1,
    bgcolor: (theme) => theme.palette.grey[100],
  };

  return (
    <>
      <Grid container padding={3} marginBottom={2} border={(theme) => `solid 1px ${theme.palette.grey[400]}`}>
        <Grid item sm={9}>
          {product.isMarketplace && (
            <Box>
              <MarketplaceTag />
            </Box>
          )}
          <h1 className={classes.title}>{product.description}</h1>
        </Grid>

        <Grid item sm={3} textAlign="right">
          <PinProductButton sku={product.sku} />
          <AddToListButton onClick={() => setMyListsOpen(true)} />
          <ProductActionMenu sku={product.sku} />
        </Grid>

        <Grid item sm={12}>
          <strong>SKU:</strong>&nbsp;
          {product.sku}
        </Grid>
        <Grid item sm={12}>
          <strong>PLU:</strong>&nbsp;
          {product.plu}
        </Grid>

        <Grid item sm={3} alignSelf="center">
          <Box textAlign="center" paddingRight={4}>
            <Image src={product?.thumbnailUrl} maxHeight={220} />
          </Box>
        </Grid>

        <Grid item sm={9}>
          <Box display="grid" gap={2} gridTemplateColumns={`repeat(${numberOfColumns}, 1fr)`}>
            <Box {...boxProps} display="flex">
              <Grid item xs={!shouldShowLocalStock ? 12 : 7}>
                <Price product={product} userProfile={userProfile} />
              </Grid>
              {shouldShowLocalStock && (
                <Grid item xs={5}>
                  <Stock inventoryTotals={inventoryTotalForLocation} />
                </Grid>
              )}
            </Box>

            {!product.isMarketplace && (
              <Box {...boxProps}>
                <DesktopDimensions dimensions={product.dimensions} />
              </Box>
            )}

            <Box {...boxProps}>
              <General product={product} isRanged={inventoryTotalForLocation?.isRanged} showTitle />
            </Box>

            {hasInventoryTotalsPermission && !product.isMarketplace && (
              <Box {...boxProps}>
                <InventoryTotals product={product} showTitle />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Tabs name="tabs" activeTab={DesktopProductTab.SOH}>
        {productTabs.map((tab) => {
          if (tab.permission === null || hasPermission(tab.permission, userProfile.roles)) {
            return (
              <Tab
                key={tab.tab}
                label={<TabTitle title={tab.title} shortTitle={tab.shortTitle} minWidth={tab.minWidth} />}
                value={tab.tab}
                variant="Modern"
              >
                {tab.component}
              </Tab>
            );
          }

          return <React.Fragment key={tab.tab}></React.Fragment>;
        })}
      </Tabs>

      <MyListsSelect open={myListsOpen} handleClose={() => setMyListsOpen(false)} sku={product.sku} />
    </>
  );
};
