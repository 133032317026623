import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useCurrentUserStore } from "src/hooks/useCurrentUserStore";
import { PromotionItem } from "./PromotionItem";
import { Accordion, getAccordionItem } from "../../Shared/Accordion";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { GetProductResponse } from "src/models/product";
import { Promotion } from "src/models/product";
import { General, InventoryTotals, Price, Stock } from "../../Shared/ProductInfo";
import { Dimensions } from "../../Shared/Details";

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    backgroundColor: `${theme.palette.grey[200]}`,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: "none",

    "& > div": {
      padding: "2px",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },

    "& .last": {
      borderBottom: "none",
    },
  },
}));

type ProductInfoTabProps = {
  product: GetProductResponse;
  promos: Promotion[];
  userProfile: UserProfile;
};

export const ProductInfoTab: React.FC<ProductInfoTabProps> = ({
  product,
  userProfile,
  promos,
}: ProductInfoTabProps) => {
  const classes = useStyles();
  const { inventoryLocationId, isSupportOffice } = useCurrentUserStore();
  const inventoryTotals = product?.inventory?.find((m) => m.locationId === inventoryLocationId);
  const productDimensions = product.dimensions.filter((d: any) => d.height || d.width || d.depth || d.weight);
  const shouldShowLocalStock = !product.isMarketplace && !isSupportOffice;

  const accordionItems = [
    getAccordionItem("General", <General product={product} isRanged={inventoryTotals?.isRanged} marginBottom={2} />),
  ];

  if (productDimensions && productDimensions.length > 0) {
    accordionItems.push(
      getAccordionItem(
        "Dimensions",
        <Dimensions productDimensions={productDimensions} className={classes.detailsGrid} />
      )
    );
  }

  if (hasPermission(UserRoles.InventoryTotals, userProfile.roles) && !product.isMarketplace) {
    accordionItems.push(getAccordionItem("Inventory Totals", <InventoryTotals product={product} marginBottom={2} />));
  }

  if (!product.isMarketplace) {
    const getPromotionsSection = (title: string, promosInSection: Promotion[]): any => {
      return getAccordionItem(
        title,
        <>
          {promosInSection.map((promo, idx) => (
            <PromotionItem key={idx} promo={promo} />
          ))}
        </>
      );
    };

    if (hasPermission(UserRoles.PricingActivePromotions, userProfile.roles)) {
      const activePromotions = promos
        .filter((promo) => promo.dateFrom.isBefore(moment()) && promo.dateTo.isAfter(moment()))
        .sort((a, b) => a.dateTo.diff(b.dateTo));

      accordionItems.push(getPromotionsSection("Active Promotions", activePromotions));
    }

    if (hasPermission(UserRoles.PricingFuturePromotions, userProfile.roles)) {
      const futurePromotions = promos
        .filter((promo) => promo.dateFrom.isAfter(moment()))
        .sort((a, b) => a.dateFrom.diff(b.dateFrom) || a.dateTo.diff(b.dateTo));

      accordionItems.push(getPromotionsSection("Future Promotions", futurePromotions));
    }

    if (hasPermission(UserRoles.PricingExpiredPromotions, userProfile.roles)) {
      const expiredPromotions = promos
        .filter((promo) => promo.dateTo.isBefore(moment()))
        .sort((a, b) => a.dateTo.diff(b.dateTo));

      accordionItems.push(getPromotionsSection("Expired Promotions", expiredPromotions));
    }
  }

  return (
    <>
      <Grid container marginTop={2} paddingBottom={2} borderBottom={(theme) => `solid 1px ${theme.palette.grey[400]}`}>
        {shouldShowLocalStock ? (
          <Grid item xs={6} borderRight={(theme) => `solid 1px ${theme.palette.grey[400]}`}>
            <Price product={product} userProfile={userProfile} paddingRight={1} />
          </Grid>
        ) : (
          <Price product={product} userProfile={userProfile} />
        )}

        {shouldShowLocalStock && (
          <Grid item xs={6} borderRight={0}>
            <Stock inventoryTotals={inventoryTotals} paddingLeft={1} />
          </Grid>
        )}
      </Grid>

      <Accordion id="product-info-accordion" items={accordionItems} defaultActiveIndex={0} />
    </>
  );
};
