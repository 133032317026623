import React from "react";
import { Snackbar, Fade } from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";

type SnackbarAlertProps = {
  onClose: () => void;
  message: string;
  open: boolean;
  severity: AlertColor;
};

export const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  onClose,
  message,
  open,
  severity,
}: SnackbarAlertProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
      autoHideDuration={4000}
      TransitionComponent={Fade}
      style={{ bottom: 40 }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ fontSize: 16 }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
