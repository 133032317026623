import { createContext, useContext, useEffect } from 'react';

export enum COUNTRIES {
  NZ = 'NZ',
  AU = 'AU',
}

export type Country = keyof typeof COUNTRIES;

interface CountryContextProps {
  country: Country;
  setCountry: (country: Country) => void;
  canSwitchCountries: boolean;
  setCanSwitchCountries: (canSwitchCountries: boolean) => void;
  changeCountry: () => void;
}

const CountryContext = createContext<CountryContextProps | undefined>(undefined);

export const useCountry = (): CountryContextProps => {
  const context = useContext(CountryContext);

  useEffect(() => {
    if (context) {
      const path = window.location.pathname.toLowerCase();
      if (path.startsWith('/nz')) {
        context.setCountry(COUNTRIES.NZ);
      } else if (path.startsWith('/au')) {
        context.setCountry(COUNTRIES.AU);
      }
    }
  }, []);

  if (!context) {
    throw new Error('useCountry must be used within a CountryProvider');
  }

  return context;
};

export default CountryContext;