const swapArrayLocs = (arr: any[], index1: number, index2: number) => {
  [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
};

const mergeColumn = (columns, columnUpdated) => {
  try {
    // Map attributes that always exist in changes
    let updatedColumns = columns.map(c => {
      return {
        ...c,
        width: c.field === columnUpdated.colDef.field ? columnUpdated.colDef.width : c.width ?? 130,
        hide: c.field === columnUpdated.colDef.field ? columnUpdated.colDef.hide : c.hide ?? false
      }
    });

    // Resort columns
    if (columnUpdated.oldIndex) {
      swapArrayLocs(updatedColumns, columnUpdated.oldIndex, columnUpdated.targetIndex)
    }

    return updatedColumns;
  } catch (e) {
    return columns;
  }
};

export const getDataGridColumns = (dataGridId, defaultColumns) => {
  try {
    var userColumns = JSON.parse(localStorage.getItem(dataGridId));
    if (!userColumns) {
      return defaultColumns;
    }
    // Merge default columns with user columns.
    const columns = defaultColumns.map((item, i) => Object.assign({}, item, userColumns[i]));
    localStorage.setItem(dataGridId, JSON.stringify(columns));
    return columns;
  } catch (e) {
    return defaultColumns;
  }
};

export const saveDataGridColumns = (dataGridId, defaultColumns, columnUpdated) => {
  try {
    var userColumns = JSON.parse(localStorage.getItem(dataGridId));
    let columns = defaultColumns;
    if (userColumns) {
      columns = userColumns;
    }
    const updatedColumns = mergeColumn(columns, columnUpdated);
    localStorage.setItem(dataGridId, JSON.stringify(updatedColumns));
  } catch (e) {
    console.log(e);
  }
};
