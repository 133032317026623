import { useEffect, useState } from "react";
import { useStateMachine } from "little-state-machine";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import {
  Badge,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { updateMyLists } from "src/store/actions";
import { sanitiseString } from "src/utils/stringUtils";
import { useCountry } from "../Context/CountryContext";
import { deleteMyListItemAsync, fetchMyListsAsync, updateMyListAsync } from "src/infrastructure/http/appApiService";

type MyListsSelectProps = {
  sku: string;
  open: boolean;
  handleClose: () => void;
};

export const MyListsSelect: React.FC<MyListsSelectProps> = ({ sku, open, handleClose }: MyListsSelectProps) => {
  const [checked, setChecked] = useState([]);
  const [defaultChecked, setDefaultChecked] = useState([]);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { country } = useCountry();
  const { state, actions } = useStateMachine({ updateMyLists });

  useEffect(() => {
    if (open) {
      const defaultSelected = state.myLists.filter((m) => m.values.some((a) => a === sku));
      const defaultSelectedNames = defaultSelected?.map((m) => m.name) ?? [];
      setChecked(defaultSelectedNames);
      setDefaultChecked(defaultSelectedNames);
    }
  }, [open, sku, state.myLists]);

  const updateListsAsync = async (addedNames: string[], removedNames: string[], sku: string) => {
    try {
      setIsLoading(true);

      for (let i = 0; i < addedNames.length; i++) {
        await updateMyListAsync(addedNames[i], sku, country);
      }

      for (let i = 0; i < removedNames.length; i++) {
        await deleteMyListItemAsync(removedNames[i], sku, country);
      }

      const myLists = await fetchMyListsAsync(country);
      actions.updateMyLists(myLists);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (value) => () => {
    const isChecked = checked.includes(value);
    const newChecked = isChecked ? checked.filter((item) => item !== value) : [...checked, value];
    setChecked(newChecked);
  };

  const saveNewList = () => {
    const myLists = [
      ...state.myLists,
      { id: sanitiseString(newListName), name: newListName, values: [], numberOfValues: 0 },
    ];
    actions.updateMyLists(myLists);
  };

  const onSaveClick = async () => {
    const removedListNames = defaultChecked.filter((m) => !checked.includes(m));
    const addedListNames = checked.filter((m) => !defaultChecked.includes(m));

    if (isNewOpen && newListName) {
      saveNewList();
      addedListNames.push(newListName);
    }

    await updateListsAsync(addedListNames, removedListNames, sku);

    resetState();
    handleClose();
  };

  const onCancelClick = () => {
    resetState();
    handleClose();
  };

  const resetState = () => {
    setChecked([]);
    setDefaultChecked([]);
    setNewListName("");
    setIsNewOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle fontSize={20}>Lists</DialogTitle>
      <DialogContent>
        {isLoading && (
          <Box textAlign="center">
            <CircularProgress color="inherit" />
          </Box>
        )}

        {!isLoading && (
          <>
            <List sx={{ width: "100%" }}>
              {state.myLists?.map((list) => {
                const labelId = `checkbox-list-label-${list.name}`;
                return (
                  <ListItem
                    key={list.name}
                    secondaryAction={<Badge color="primary" badgeContent={list.numberOfValues} />}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(list.name)} dense style={{ paddingLeft: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(list.name) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          style={{ padding: 0 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={list.name}
                        primaryTypographyProps={{ fontSize: 16, fontWeight: "normal" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}

              {!isNewOpen && (
                <ListItem key="create-new-list" disablePadding>
                  <ListItemButton role={undefined} onClick={() => setIsNewOpen(true)} dense style={{ paddingLeft: 0 }}>
                    <ListItemIcon>
                      <AddOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary="Create new list"
                      primaryTypographyProps={{ fontSize: 16, fontWeight: "bold" }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>

            {isNewOpen && (
              <TextField
                id="newList"
                label="List name"
                variant="outlined"
                onChange={(e) => setNewListName(e.target.value)}
                inputProps={{ autoCorrect: "off" }}
                helperText="Your item will be saved to this list"
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                fullWidth
              />
            )}
          </>
        )}
      </DialogContent>

      {!isLoading && (
        <DialogActions
          sx={(theme) => ({
            justifyContent: "space-between",
            paddingY: 2,
            paddingX: 3,
            borderTop: 1,
            borderTopColor: theme.palette.grey[300],
          })}
        >
          <Button type="link" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSaveClick} style={{ width: "auto" }}>
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
