import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { dateStrToMoment, formatMoment } from "src/utils/formatUtil";

const useStyles = makeStyles((theme) => ({
  versionNumber: {
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 800,

    "& small": {
      fontSize: 18,
    },
  },
  featureGrid: {
    marginBottom: 20,
  },
  featureTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

type ReleaseNoteDetailsProps = {
  releaseNotes: any[];
};

export const ReleaseNoteDetails: React.FC<ReleaseNoteDetailsProps> = ({ releaseNotes }: ReleaseNoteDetailsProps) => {
  const classes = useStyles();

  return (
    <>
      {releaseNotes.map((m, i) => {
        return (
          <Grid key={i}>
            <div className={classes.versionNumber}>
              Version {m.version}{" "}
              <small>{m.releaseDate && formatMoment(dateStrToMoment(m.releaseDate), "DD/MM/YYYY")}</small>
            </div>
            {m.notes.map((n, idxn) => {
              return (
                <Grid key={idxn} className={classes.featureGrid}>
                  <div className={classes.featureTitle}>{n.title}</div>
                  <ul>
                    {n.body.map((b, idxb) => {
                      return <li key={idxb}>{b}</li>;
                    })}

                    {n.imageUrls?.map((im, idxm) => {
                      return <img key={idxm} src={im} style={{ maxHeight: "auto", maxWidth: "100%" }} alt="" />;
                    })}
                  </ul>
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </>
  );
};
