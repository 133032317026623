import { Box, Grid, GridProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";

type SupplierDetailsProps = GridProps & {
  product: GetProductResponse;
  showTitle?: boolean;
};

export const SupplierDetails: React.FC<SupplierDetailsProps> = ({
  product,
  showTitle,
  ...rest
}: SupplierDetailsProps) => {
  return (
    <>
      {showTitle && (
        <Box component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
          Supplier Details
        </Box>
      )}

      <Grid container {...rest}>
        <Grid item xs={5}>
          Name
        </Grid>
        <Grid item xs={7}>
          {product.primaryVendor.name}
        </Grid>

        <Grid item xs={5} className="last">
          Phone Number
        </Grid>
        <Grid item xs={7} className="last">
          {product.primaryVendor.phoneNumber}
        </Grid>
      </Grid>
    </>
  );
};
