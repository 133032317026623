import { useState, useEffect } from "react";
import { fetchPurchaseOrdersAsync } from "../../infrastructure/http/appApiService";
import { useCountry } from "../../components/Context/CountryContext";
import { GetPurchaseOrdersResponse } from "src/models/product";

const usePurchaseOrders = (id: string) => {
  const [data, setData] = useState<GetPurchaseOrdersResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { country } = useCountry();

  useEffect(() => {
    if (!id) return;

    const fetchData = async (sku: string) => {
      try {
        setIsLoading(true);
        const response = await fetchPurchaseOrdersAsync(sku, country);
        setData(response);
        setIsLoading(false);
      } catch (e) {
        console.log(e.message);
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData(id);
  }, [id, country]);

  return { isLoading, data, error };
};

export default usePurchaseOrders;
