import { useState, useEffect } from "react";
import { fetchPromosAsync } from "../../infrastructure/http/appApiService";
import { useCountry } from "../../components/Context/CountryContext";
import { Promotion } from "src/models/product";
import { useCurrentUserStore } from "../useCurrentUserStore";

const usePromos = (sku: string) => {
  const [data, setData] = useState<Promotion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { country } = useCountry();
  const { currentLocationId } = useCurrentUserStore();

  useEffect(() => {
    if (!sku)
      return;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchPromosAsync(sku, currentLocationId, country);
        setData(response);
      } catch (e) {
        console.log(e.message);
        setError(e.message);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sku, currentLocationId, country]);

  return { isLoading, data, error };
};

export default usePromos;