import { FormControl, InputLabel, ListSubheader, ListSubheaderProps, MenuItem, Select, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ProductSpecifications, productSpecificationsOptions } from "src/models/messaging";

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    lineHeight: '48px', // Increased line height for better spacing
  }));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: '0.875rem',
    paddingLeft: theme.spacing(4),
  }));
  

function MyListSubheader(props: ListSubheaderProps) {
    return <StyledListSubheader {...props} />;
  }
MyListSubheader.muiSkipListHighlight = true;

const optionsWithIds = productSpecificationsOptions.map((option, index) => ({
    ...option,
    id: `${option.name}-${option.value}-${index}`
}));
  
const GroupedSpecificationsDropdown: React.FC = () => {
    const { control } = useFormContext();
  
    return (
      <FormControl fullWidth>
        <InputLabel id="grouped-select-label">Specifications</InputLabel>
        <Controller
          name="specificationConditions"
          control={control}
          render={({ field }) => (
            <Select
              labelId="grouped-select-label"
              id="grouped-select"
              label="Specifications"
              {...field}
              onChange={(event) => {
                const selectedOption = optionsWithIds.find(
                  (option) => option.id === event.target.value
                );
                field.onChange(selectedOption ? [selectedOption] : []);
              }}
              value={field.value.length > 0 ? optionsWithIds.find(option => 
                option.name === field.value[0].name && 
                option.value === field.value[0].value
              )?.id : ""}
              displayEmpty
              fullWidth
            >
              {Array.from(new Set(optionsWithIds.map(option => option.category))).map(category => [
                <MyListSubheader key={category}>{category}</MyListSubheader>,
                ...optionsWithIds
                  .filter(option => option.category === category)
                  .map(option => (
                    <StyledMenuItem key={option.id} value={option.id}>
                      {option.label}
                    </StyledMenuItem>
                  ))
              ])}
            </Select>
          )}
        />
      </FormControl>
    );
  };

  export default GroupedSpecificationsDropdown;