import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material";

const StyledTitle = styled("h3")({
  textAlign: "center",
});

type TabTitleProps = {
  title: string;
  shortTitle?: string;
  minWidth?: number;
};

const FixedTabTitle: React.FC<TabTitleProps> = ({ title }: TabTitleProps) => {
  return <StyledTitle>{title}</StyledTitle>;
};

const ResponsiveTabTitle: React.FC<TabTitleProps> = ({ title, shortTitle, minWidth }: TabTitleProps) => {
  const [renderedTitle, setRenderedTitle] = useState(shortTitle);
  const ref = React.createRef<HTMLHeadingElement>();

  const onResize = useCallback(() => {
    const parentWidth = ref?.current?.parentElement?.clientWidth ?? 0;
    const newTitle = parentWidth < minWidth ? shortTitle : title;
    setRenderedTitle(newTitle);
  }, [ref, title, shortTitle, minWidth]);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return <StyledTitle ref={ref}>{renderedTitle}</StyledTitle>;
};

export const TabTitle: React.FC<TabTitleProps> = ({ title, shortTitle, minWidth }: TabTitleProps) => {
  if (!shortTitle) {
    return <FixedTabTitle title={title} />;
  }

  return <ResponsiveTabTitle title={title} shortTitle={shortTitle} minWidth={minWidth} />;
};
