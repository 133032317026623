import { styled } from "@mui/material";
import ToggleButtonGroup, { ToggleButton, ToggleButtonProps } from "@jbhi-fi/lanyard-ui/components/ToggleButton";

const SmallToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  border: "none !important",
  paddingLeft: `${theme.spacing(2)} !important`,
  paddingRight: `${theme.spacing(2)} !important`,
  height: "35px !important",

  "&[aria-pressed='false']": {
    backgroundColor: `${theme.palette.grey[300]} !important`,

    "& span": {
      color: `${theme.palette.grey[600]} !important`,
      fontWeight: "normal !important",
    },
  },

  "&.left": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },

  "&.right": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

type SquareSwitchProps = {
  currentCountry: string;
  onChange: () => void;
  leftText?: string;
  rightText?: string;
};

const SquareSwitch: React.FC<SquareSwitchProps> = ({
  currentCountry,
  onChange,
  leftText,
  rightText,
}: SquareSwitchProps) => {
  return (
    <ToggleButtonGroup activeButton={currentCountry} onChange={onChange} name="Tenant Country">
      <SmallToggleButton label={leftText} value={leftText} className="left" />
      <SmallToggleButton label={rightText} value={rightText} className="right" />
    </ToggleButtonGroup>
  );
};

export default SquareSwitch;
