import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { openInNewTab } from "../../../../utils/linkUtil";
import { formatDateOffset, getNthString } from "../../../../utils/formatUtil";
import { LocalShippingOutlined } from "@mui/icons-material";
import { PurchaseOrderAsn } from "src/models/product";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 4,
  },
  topContainer: {
    padding: 32,
    minWidth: 260,
  },
  gridItemOffset: {
    marginTop: 16,
  },
}));

const onTrackClick = (url) => {
  openInNewTab(url);
};

type AsnSummaryProps = {
  friendlyId: number;
  asn: PurchaseOrderAsn;
};

const AsnSummary: React.FC<AsnSummaryProps> = ({ friendlyId, asn }: AsnSummaryProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.topContainer} container item direction="column" xs={12} md={6}>
      <Grid container item direction="row">
        <LocalShippingOutlined className={classes.icon} />
        <h2>
          {friendlyId}
          {getNthString(friendlyId)} Shipment
        </h2>
      </Grid>
      <Grid item>
        <p>Carrier: {asn.carrier}</p>
        <p>Consignment Number: {asn.consignmentNoteNumber}</p>
        <p>Date Shipped: {formatDateOffset(asn.dateShipped, "ddd, DD/MM/YYYY")}</p>
        <p>Date Expected: {formatDateOffset(asn.dateDeliveryExpected, "ddd, DD/MM/YYYY")}</p>
        {asn.invoiceNumber && <p>Invoice Number: {asn.invoiceNumber}</p>}
        <p>Sku: {asn.sku}</p>
        <p>Quantity: {asn.quantity}</p>
        <p>Total Packages On Shipment: {asn.totalNumberOfPackages}</p>
      </Grid>
      <Grid item className={classes.gridItemOffset}>
        {asn.consignmentTrackingUrl && (
          <Button type="primary" onClick={() => onTrackClick(asn.consignmentTrackingUrl)} fullWidth>
            Track
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default AsnSummary;
