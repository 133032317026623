import { isMobile, isAndroid } from "react-device-detect";

declare global {
  interface Window { BrowserInterface?: any; }
}

export const openInNewTab = (url: string) => {
  if (isMobile && isAndroid) {
    try {
      window.BrowserInterface?.OpenBrowser(url);
    } catch (e) {
      console.log(e);
    }
  } else {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
}