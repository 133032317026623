import { Box, SvgIconProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: `${theme.spacing(2)} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
}));

type EmptyListProps = {
  icon: React.ComponentType<SvgIconProps>;
  title: React.ReactNode;
  message: React.ReactNode;
  paddingX?: number | string;
  paddingY?: number | string;
};

export const EmptyListState: React.FC<EmptyListProps> = (props: EmptyListProps) => {
  const classes = useStyles();

  return (
    <Box
      border={1}
      borderColor={(theme) => theme.palette.grey[400]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX={props.paddingX ?? 4}
      paddingY={props.paddingY ?? 4}
      textAlign="center"
    >
      <props.icon sx={{ fontSize: 46 }} />
      <h2 className={classes.title}>{props.title}</h2>
      <small>{props.message}</small>
    </Box>
  );
};
