import { Accordion as JbAccordion } from "@jbhi-fi/lanyard-ui/components/Accordion";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  styledAccordion: {
    "& .accordion-item": {
      border: "none",
    },
    "& .accordion-head": {
      paddingRight: 0,
      paddingLeft: 0,

      "& .Accordion_headOpenClose__iw66k70": {
        marginLeft: theme.spacing(1),
      },
    },
    "& .accordion-content": {
      backgroundColor: (props: any) => (props.withBodyBackground ? theme.palette.grey[200] : "#fff"),
      borderBottom: `1px solid ${theme.palette.grey[400]} !important`,
      padding: 0,

      "&.active": {
        border: (props: any) => (props.withBodyBackground ? `solid 1px ${theme.palette.grey[400]}` : "none"),
      },
    },
  },
}));

type AccordionProps = {
  id: string;
  items: any[];
  defaultActiveIndex?: number | null;
  withBodyBackground?: boolean | null;
  activeState?: Array<number> | null;
  onActiveStateChange?: (state: any) => void;
  unmountOnClose?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
  id,
  items,
  defaultActiveIndex,
  withBodyBackground,
  activeState,
  onActiveStateChange,
  unmountOnClose,
}: AccordionProps) => {
  const classes = useStyles({ withBodyBackground });

  return (
    <JbAccordion
      id={id}
      items={items}
      multiOpen={true}
      className={classes.styledAccordion}
      accordionItemClassname="accordion-item"
      defaultActiveIndex={defaultActiveIndex}
      activeState={activeState}
      onActiveStateChange={onActiveStateChange}
      unmountOnClose={unmountOnClose}
    ></JbAccordion>
  );
};

export const getAccordionItem = (title: string, content: React.ReactNode): any => ({
  head: <h3>{title}</h3>,
  closeIcon: <RemoveOutlined />,
  openIcon: <AddOutlined />,
  content: <>{content}</>,
});
