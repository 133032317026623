import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
  },
  primaryButton: {
    textTransform: "none",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    minWidth: "100px",
  },
  secondaryButton: {
    textTransform: "none",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    borderWidth: 0,
    minWidth: "100px",
  },
}));

type ModalProps = {
  isOpen: boolean;
  title?: string;
  primaryButtonName: string;
  secondaryButtonName?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  children?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  primaryButtonName,
  secondaryButtonName,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  children,
}: ModalProps) => {
  const classes = useStyles();

  const primaryButtonAction = () => {
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    }
  };

  const secondaryButtonAction = () => {
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogTitle>
        {title && (
          <Box>
            <h2 className={classes.title}>{title}</h2>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item>
            {secondaryButtonName && (
              <Button className={classes.secondaryButton} onClick={secondaryButtonAction}>
                {secondaryButtonName}
              </Button>
            )}
          </Grid>

          <Grid item>
            <Button className={classes.primaryButton} variant="contained" onClick={primaryButtonAction}>
              {primaryButtonName}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
