import { isNumeric, isEmail } from "validator";
import { encode } from "base64-arraybuffer";
import { UserProfile } from "./UserProfile";

const fetchAuthenticatedUrl = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

const fetchPhoto = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 200) {
    return response.arrayBuffer();
  } else {
    return null;
  }
};

const getUserProfileAsync = async (accessToken) => {
  const graphProfileEndpoint = "https://graph.microsoft.com/v1.0/me";

  const jbProfileFields = [
    "displayName",
    "givenName",
    "surname",
    "jobTitle",
    "officeLocation",
    "employeeId",
    "onPremisesExtensionAttributes",
    "usageLocation",
    "country",
    "mail",
  ];

  const jbProfileRequestEndpoint = graphProfileEndpoint + "?$select=" + jbProfileFields.join(",");
  const graphProfile = await fetchAuthenticatedUrl(jbProfileRequestEndpoint, accessToken);

  let staffProfile = {} as UserProfile;
  staffProfile.staffId = getStaffId(graphProfile);

  if (graphProfile.onPremisesExtensionAttributes && graphProfile.onPremisesExtensionAttributes.extensionAttribute9) {
    const countryAndStoreNumber = graphProfile.onPremisesExtensionAttributes.extensionAttribute9; // in the format "AU10"

    const storeLocationMatch = countryAndStoreNumber?.match(/^([A-Za-z]+)/);
    staffProfile.storeLocation = storeLocationMatch ? storeLocationMatch : "";
    staffProfile.storeNumber = parseInt(countryAndStoreNumber?.match(/([0-9]+)$/)[0]);
  }
  staffProfile.officeLocation = graphProfile.officeLocation;
  staffProfile.displayName = graphProfile.displayName;
  staffProfile.givenName = graphProfile.givenName;
  staffProfile.surname = graphProfile.surname;
  return staffProfile;
};

const getUserPhotoAsync = async (accessToken) => {
  const graphProfileEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
  const photoBinary = await fetchPhoto(graphProfileEndpoint, accessToken);
  const photo = photoBinary ? encode(photoBinary) : null;

  return photo;
};

const getStaffId = (graphProfile) => {
  const extensionAttribute15 = graphProfile.onPremisesExtensionAttributes?.extensionAttribute15;
  const { employeeId } = graphProfile;

  const staffIdFromEmployeeId = extensionAttribute15
    ? extensionAttribute15
    : employeeId
      ? parseInt(employeeId, 10).toString()
      : null;

  const staffIdFromEmail = getStaffIdFromEmail(graphProfile.mail);

  if (!(staffIdFromEmail || staffIdFromEmployeeId)) {
    throw new Error("User does not have email and employeeId");
  } else {
    return staffIdFromEmployeeId ?? staffIdFromEmail;
  }
};

const getStaffIdFromEmail = (email) => {
  if (!(email && isEmail(email))) {
    return null;
  }
  const emailPrefix = email.substring(0, email.indexOf("@"));
  if (!isNumeric(emailPrefix)) {
    return null;
  }
  return emailPrefix;
};

export { getUserProfileAsync, getUserPhotoAsync, getStaffId };
