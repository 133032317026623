import React from "react";
import { MenuItem } from "@mui/material";
import { openInNewTab } from "../../utils/linkUtil";

type OpenLinkMenuItemProps = {
  text: string;
  url: string;
};

export const OpenLinkMenuItem: React.FC<OpenLinkMenuItemProps> = ({ text, url }: OpenLinkMenuItemProps) => {
  return (
    <MenuItem id="open-link" onClick={() => openInNewTab(url)} sx={{ fontSize: 14 }}>
      {text}
    </MenuItem>
  );
};
