import { SearchResults } from "./SearchResult";

export type SearchResultState = SearchResults & { page: number };

export type SearchState = {
  totalRows: number;
  jbSearchState: SearchResultState;
  mpSearchState: SearchResultState;
};

export const getDefaultSearchState = (): SearchState => ({
  totalRows: 0,
  jbSearchState: { rows: 0, results: [], page: 0 },
  mpSearchState: { rows: 0, results: [], page: 0 },
});