import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Stack, MenuItem, Tooltip, Alert, ListSubheader } from "@mui/material";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { DatePicker, TimeField } from "@mui/x-date-pickers";
import TextField from "@jbhi-fi/lanyard-ui/components/TextField/TextField";
import Radio from "@jbhi-fi/lanyard-ui/components/Radio/Radio";
import { AutoCompleteType, InputType } from "@jbhi-fi/lanyard-ui/components/TextField/TextField.types";
import {
  Button,
  DatePickerProvider,
  ImageUploadField,
  SelectField,
  StyledSelect,
  ValidationLabel,
} from "src/components/Form";
import {
  MessageTypes,
  MessageSubtypes,
  ConditionTypes,
  conditionTypeOptions,
  messageTypesOptions,
  messageSubtypesOptions,
  specialOfferSubtypesOptions,
  servicesSubtypesOptions,
  allSubtypesOptions,
  productSpecificationsOptions,
} from "src/models/messaging";
import { getConsts } from "src/utils/consts";
import { CreateMessageModel } from "src/validationSchemas";
import { ProductSelect } from "./ProductSelect";
import moment from "moment";
import  MessageRichTextEditor  from "./MessageRichTextEditor";
import { getSubTypeLabel } from "src/models/messaging/MessageSubtypeLabels";
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import GroupedSpecificationsDropdown from "./GroupedSpecificationsDropdown";

const useStyles = makeStyles((theme) => ({
  onlineTagInput: {
    flexGrow: 1,
    "& input[disabled]": { color: theme.palette.grey[400] },
  },
}));

const gridBorder = {
  border: 1,
  borderColor: "grey.400",
} as Grid2Props;

const gridSpacing = {
  paddingY: 4,
  paddingX: 10,
  marginTop: 3,
  marginX: 0,
  marginBottom: 0,
  spacing: 3,
} as Grid2Props;

type MessageFormProps = {
  onFormSubmit: (data: CreateMessageModel) => void;
  onFormError: (data: any) => void;
};

export const MessageForm: React.FC<MessageFormProps> = (props: MessageFormProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [isOnlineTagDisabled, setIsOnlineTagDisabled] = useState(true);

  const formContext = useFormContext();
  const { dirtyFields } = formContext.formState;
  const typeWatch = formContext.watch("type");
  const subTypeWatch = formContext.watch("subType");
  const hasDefaultOnlineTagWatch = formContext.watch("hasDefaultOnlineTag");
  const conditionTypesWatch = formContext.watch("conditionTypes") as ConditionTypes[];
  const startTimeWatch = formContext.watch("startTime");
  const endTimeWatch = formContext.watch("endTime");
  const messageIdWatch = formContext.watch("id");
  const isDirtyAlt = dirtyFields && !!Object.keys(dirtyFields).length;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
 
  const isServiceOrSpecialOffer = typeWatch === MessageTypes.Service || typeWatch === MessageTypes.SpecialOffer;

  useEffect(() => {
    if (!isDirtyAlt) return;

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirtyAlt])


  useEffect(() => {
    const subType = allSubtypesOptions.find((option) => option.value === subTypeWatch);
    const hasDefaultOnlineTag = !!subType?.defaultOnlineTag; 
    const currentonlineTag = formContext.getValues("onlineTag"); 
    const currentHasCustomOnlineTag = formContext.getValues("hasCustomOnlineTag"); 
    const hasCustomOnlineTag = currentHasCustomOnlineTag === undefined && !!currentonlineTag && currentonlineTag !== subType?.defaultOnlineTag; 
    
    let onlineTagValue = currentonlineTag;
    if (hasDefaultOnlineTag && !hasCustomOnlineTag) {
      onlineTagValue = subType?.defaultOnlineTag ?? "";
      setIsOnlineTagDisabled(true);
    }

    if (!hasDefaultOnlineTag)
    {
      onlineTagValue = '';
      setIsOnlineTagDisabled(false);
    }

    formContext.setValue("hasDefaultOnlineTag", hasDefaultOnlineTag);
    formContext.setValue("onlineTag", onlineTagValue);
    formContext.setValue("hasCustomOnlineTag", hasCustomOnlineTag);
    if (!formContext.getValues("priority"))
    {
      formContext.setValue("priority", subType?.defaultPriority ?? 1);
    }
  }, [subTypeWatch]);

  // useEffect(() => {
  //   formContext.resetField("skuConditions");
  //   formContext.resetField("specificationConditions");
  // }, [conditionTypesWatch]);

  useEffect(() => {
    const noSpecificationConditions = formContext.getValues("specificationConditions").length === 0;
    const noSkuConditions = formContext.getValues("skuConditions").length === 0;

    if (noSpecificationConditions && noSkuConditions) {
      const conditionType = typeWatch === MessageTypes.Message 
        ? ConditionTypes.Specification 
        : ConditionTypes.Sku;
        
      formContext.setValue("conditionTypes", [conditionType]);
    }
  }, [typeWatch]);

  const getSubtypeOptions = () => {
    const options = (() => {
      switch (typeWatch) {
        case MessageTypes.Message:
          return messageSubtypesOptions;
        case MessageTypes.SpecialOffer:
          return specialOfferSubtypesOptions;
        case MessageTypes.Service:
          return servicesSubtypesOptions;
        default:
          return [];
      }
    })();
  
    return options.map(option => ({
      ...option,
      label: getSubTypeLabel(option.value as MessageSubtypes)
    }));
  }

  const handleOnlineTagEditClick = () => {
    if (isOnlineTagDisabled) {
      setIsOnlineTagDisabled(false);
      return;
    }

    const subType = allSubtypesOptions.find((option) => option.value === subTypeWatch);
    formContext.setValue("onlineTag", subType?.defaultOnlineTag ?? "");
    setIsOnlineTagDisabled(true);
  };

  const onCancelClick = () => {
      const consts = getConsts();
      history.push(consts.routerPath.messaging);
    
  };

  const onSaveClick = () => {
    formContext.handleSubmit((data) => {
      props.onFormSubmit(data);
      setIsFormSubmitted(true);
    }, props.onFormError)();
  };

  const isPastDate = (date) => {
    return moment(date).isBefore(moment().startOf('day'));
  };

  const isBeforeStartDate = (date, startDate) => {
    return startDate && moment(date).isBefore(moment(startDate, "DD/MM/YYYY"), 'day');
  };
  
  return (
    <DatePickerProvider>
      <Prompt
        when={isDirtyAlt && !isFormSubmitted}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form>
        <Grid container {...gridBorder} {...gridSpacing}>
          <Grid xs={12}>
            <h2>Template details</h2>
          </Grid>

          <Grid xs={6}>
            <label>Template type</label>
            <Controller
              name="type"
              control={formContext.control}
              render={({ field }) => {
                return (
                  <SelectField
                    name={field.name}
                    label="Type"
                    value={field.value ?? ""}
                    options={messageTypesOptions}
                    onChange={field.onChange}
                    hasError={!!formContext.formState.errors?.type}
                    errorMessage={formContext.formState.errors?.type?.message?.toString()}
                  />
                );
              }}
            />
          </Grid>

          <Grid xs={6}>
            <label>Subtype</label>
            <Controller
              name="subType"
              control={formContext.control}
              render={({ field }) => (
                <SelectField
                  name={field.name ?? ""}
                  label="Subtype"
                  value={field.value ?? ""}
                  options={getSubtypeOptions()}
                  onChange={field.onChange}
                  hasError={!!formContext.formState.errors?.subType}
                  errorMessage={formContext.formState.errors?.subType?.message?.toString()}
                />
              )}
            />
          </Grid>

          {subTypeWatch === MessageSubtypes.Cashback && (
            <>
              <Grid xs={6}>
                <label>Cashback amount</label>
                <Controller
                  name="cashbackAmount"
                  control={formContext.control}
                  render={({ field }) => (
                    <TextField
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type={InputType.number}
                      min="0"
                      autoComplete={AutoCompleteType.off}
                      hasError={!!formContext.formState.errors?.cashbackAmount}
                      errorMsg={formContext.formState.errors.cashbackAmount?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid xs={6}></Grid>
            </>
          )}

          <Grid xs={12}>
            <label>Template name</label>
            <Controller
              name="name"
              control={formContext.control}
              render={({ field }) => (
                <TextField
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  autoComplete={AutoCompleteType.off}
                  hasError={!!formContext.formState.errors?.name}
                  errorMsg={formContext.formState.errors.name?.message?.toString()}
                />
              )}
            />
            {!formContext.formState.errors.name && <small>This name is for internal use</small>}
          </Grid>

          <Grid xs={6}>
            <label>Start date</label>
            <Controller
              name="startDate"
              control={formContext.control}
              render={({ field }) => (
                <DatePicker
                  name={field.name}
                  value={field.value ? moment(field.value, "DD/MM/YYYY") : null}
                  onChange={(e) => field.onChange(e ? moment(e).format("DD/MM/YYYY") : null)}
                  format="DD/MM/YYYY"
                  shouldDisableDate={isPastDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!formContext.formState.errors?.startDate,
                      helperText: formContext.formState.errors?.startDate?.message?.toString(),
                    },
                    day: {
                      sx: {
                        "&.Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.38)",
                            backgroundColor: "transparent",
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid xs={6}>
            <label>Start time</label>
            <Controller
              name="startTime"
              control={formContext.control}
              defaultValue={ !!messageIdWatch && !startTimeWatch ? "" : moment().startOf("day").format("HH:mm:ss")} // Default to 12:00 AM
              render={({ field }) => (
                <TimeField
                  name={field.name}
                  value={field.value ? moment(field.value, "HH:mm:ss") : null}
                  onChange={(e) => field.onChange(e?.format("HH:mm:ss") ?? "")}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!formContext.formState.errors?.startTime,
                      helperText: formContext.formState.errors?.startTime?.message?.toString(),
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid xs={6}>
            <label>Finish date</label>
            <Controller
              name="finishDate"
              control={formContext.control}
              render={({ field }) => (
                <DatePicker
                  name={field.name}
                  value={field.value ? moment(field.value, "DD/MM/YYYY") : null}
                  onChange={(e) => field.onChange(e ? moment(e).format("DD/MM/YYYY") : null)}
                  format="DD/MM/YYYY"
                  shouldDisableDate={(date) => {
                    const startDate = formContext.getValues("startDate");
                    return isPastDate(date) || isBeforeStartDate(date, startDate);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!formContext.formState.errors?.finishDate,
                      helperText: formContext.formState.errors?.finishDate?.message?.toString(),
                    },
                    day: {
                      sx: {
                        "&.Mui-disabled": {
                          color: "gray",
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid xs={6}>
            <label>Finish time</label>
            <Controller
              name="finishTime"
              control={formContext.control}
              defaultValue={ !!messageIdWatch && !endTimeWatch ? "" : moment().endOf("day").format("HH:mm:ss")} // Default to 12:00 AM
              render={({ field }) => (
                <TimeField
                  name={field.name}
                  value={field.value ? moment(field.value, "HH:mm:ss") : null}
                  onChange={(e) => field.onChange(e?.format("HH:mm:ss") ?? "")}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!formContext.formState.errors?.finishTime,
                      helperText: formContext.formState.errors?.finishTime?.message?.toString(),
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid xs={6}>
            <label>Priority</label>
            <Controller
            name="priority"
            control={formContext.control}
            render={({ field }) => (
              <TextField
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={(e) => {
                  const value = Math.max(1, Math.min(20, Math.floor(Number(e.target.value))));
                  field.onChange(value);
                }}
                type={InputType.number}
                autoComplete={AutoCompleteType.off}
                hasError={!!formContext.formState.errors?.priority}
                errorMsg={formContext.formState.errors.priority?.message?.toString()}
                
              />
            )}
          />
          </Grid>
          <Grid xs={6}></Grid>

          {isServiceOrSpecialOffer && (
            <Grid xs={6}>
              <Stack direction="column">
              <Stack direction="row" alignItems="center" spacing={1}>
                <label>Online Tag</label>
                <Tooltip 
                  title="Add a custom text tag or select from: JB Gift Card; Cashback; Bonus Offer; Competition; Signed Offer. Leave blank for no tag display" 
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontWeight: 'normal',
                      },
                    },
                  }}
                ><InfoIconOutlined sx ={{color: '#212121'}} fontSize="small" />
                </Tooltip>
              </Stack>
                <Stack direction="row" spacing={2}>
                  <Controller
                    name="onlineTag"
                    control={formContext.control}
                    render={({ field }) => (
                      <TextField
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        autoComplete={AutoCompleteType.off}
                        hasError={!!formContext.formState.errors?.onlineTag}
                        errorMsg={formContext.formState.errors.onlineTag?.message?.toString()}
                        disabled={isOnlineTagDisabled}
                        className={classes.onlineTagInput}
                      />
                    )}
                  />
                  {hasDefaultOnlineTagWatch && (
                    <Button type="secondary" onClick={handleOnlineTagEditClick}>
                      {isOnlineTagDisabled ? "Edit" : "Reset to default"}
                    </Button>
                  )}
                </Stack>
                {!hasDefaultOnlineTagWatch && !formContext.formState.errors.onlineTag && (
                  <small>There is no default online tag for this subtype</small>
                )}
              </Stack>
            </Grid>
          )}

          <Grid xs={12}>
            <label>Heading</label>
            <Controller
              name="heading"
              control={formContext.control}
              render={({ field }) => (
                <TextField
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  autoComplete={AutoCompleteType.off}
                  hasError={!!formContext.formState.errors?.heading}
                  errorMsg={formContext.formState.errors.heading?.message?.toString()}
                />
              )}
            />
            {!formContext.formState.errors.heading && <small>This title will be displayed in the website</small>}
          </Grid>

          {isServiceOrSpecialOffer && (
            <Grid xs={12}>
              <label>Short description</label>
              <Controller
                name="shortDescription"
                control={formContext.control}
                render={({ field }) => (
                  <TextField
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    autoComplete={AutoCompleteType.off}
                    hasError={!!formContext.formState.errors?.shortDescription}
                    errorMsg={formContext.formState.errors.shortDescription?.message?.toString()}
                  />
                )}
              />
            </Grid>
          )}

          <Grid  xs={12}>
            <label>Description</label>
            <Controller
              name="description"
              control={formContext.control}
              render={({ field }) => (
                <MessageRichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!formContext.formState.errors?.description}
                  errorMessage={formContext.formState.errors.description?.message?.toString()}
                  minHeight={200}
                  isTypeMessage={typeWatch === MessageTypes.Message}
                />
          )}
        />
      </Grid>

          {typeWatch === MessageTypes.Message && (
            <Grid xs={12}>
              <label>Image</label>
              <Controller
                name="messageImage"
                control={formContext.control}
                render={({ field }) => (
                  <ImageUploadField value={field.value?.file || field.value} onChange={field.onChange} maxWidth={1244} maxHeight={600} />
                )}
              />
            </Grid>
          )}

          {isServiceOrSpecialOffer && (
            <>
              <Grid xs={4}>
                <label>Tile image</label>
                <Controller
                  name="tileImage"
                  control={formContext.control}
                  render={({ field }) => (
                    <ImageUploadField
                      value={field.value?.file || field.value}
                      onChange={field.onChange}
                      maxWidth={120}
                      maxHeight={120}
                      helperText="This image will be the tile anchor"
                      exactSize
                    />
                  )}
                />
              </Grid>

              <Grid xs={8}>
                <label>Hero banner image</label>
                <Controller
                  name="heroBannerImage"
                  control={formContext.control}
                  render={({ field }) => (
                    <ImageUploadField
                      value={field.value?.file || field.value}
                      onChange={field.onChange}
                      maxWidth={1380}
                      maxHeight={400}
                      helperText="This image will be displayed in the more details modal"
                      exactSize
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container {...gridBorder} {...gridSpacing}>
          <Grid xs={12}>
            <h2>Product information</h2>
          </Grid>

          <Grid xs={12}>
            <label>Search by:</label>
            <Controller
              name="conditionTypes"
              control={formContext.control}
              render={({ field }) => (
                <Stack display="inline-flex" direction="row" spacing={3} marginLeft={3}>
                  {conditionTypeOptions.map(({ value, label }) => (
                    // TODO: Replace with checkbox for phase 2 so it supports multiple selection
                    <Radio
                      key={`${field.name}-${value}`}
                      id={`${field.name}-${value}`}
                      name={field.name}
                      label={label}
                      value={value}
                      onChange={(e) => formContext.setValue("conditionTypes", [e.target.value])}
                      checked={conditionTypesWatch.includes(value)}
                    />
                  ))}
                </Stack>
              )}
            />
            <ValidationLabel errorMessage={formContext.formState.errors?.conditionTypes?.message} />
          </Grid>

          {conditionTypesWatch.includes(ConditionTypes.Specification) && (
            <Grid xs={6}>
              <GroupedSpecificationsDropdown />
            </Grid>
          )}

          {conditionTypesWatch.includes(ConditionTypes.Sku) && (
            <Grid xs={12}>
              <Controller
                name="skuConditions"
                control={formContext.control}
                render={({ field }) => (
                  <ProductSelect
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    hasError={!!formContext.formState.errors?.skuConditions}
                    errorMessage={formContext.formState.errors.skuConditions?.message?.toString()}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <Grid container marginTop={3}>
          <Grid xs={12} display="flex" justifyContent="space-between">
            <Button type="link" onClick={onCancelClick}>
              Cancel
            </Button>
            <Button type="primary" onClick={onSaveClick}>
              Save
            </Button>
          </Grid>
        </Grid>

        <input type="hidden" {...formContext.register("id")} />
        <input type="hidden" {...formContext.register("start")} />
        <input type="hidden" {...formContext.register("finish")} />
      </form>
    </DatePickerProvider>
  );
};
