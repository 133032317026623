import { FC } from "react";
import { styled } from "@mui/material";
import Modal from "@jbhi-fi/lanyard-ui/components/Modal/Modal";

export type AlertModalState = {
  show: boolean;
  title?: string;
  type?: "info" | "error" | "warning" | "success";
  message?: React.ReactNode;
  onClose?: () => void;
};

export type AlertModalProps = {
  state: AlertModalState;
  onClose: () => void;
};

const StyledModalBodyContainer = styled("div")`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const AlertModal: FC<AlertModalProps> = ({ state, onClose }: AlertModalProps) => {
  if (!state.show) {
    return <></>;
  }

  const onModalClosed = () => {
    executeCallback(state.onClose);
    executeCallback(onClose);
  };

  const executeCallback = (callback?: () => void) => {
    if (callback) {
      callback();
    }
  };

  return (
    <Modal
      title={state.title}
      type={state.type}
      children={<StyledModalBodyContainer>{state.message}</StyledModalBodyContainer>}
      show={state.show}
      onClose={onModalClosed}
    />
  );
};
