import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Paper, Stack } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GetProductListProductResponse } from "src/models/appData";
import { formatCurrency } from "src/utils/formatUtil";
import { Image } from "src/components/Product/Image";
import { useIsMobile } from "src/hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: (props: any) => (props.isMobile ? 160 : 190),
    marginTop: 20,
    marginBottom: 20,
    padding: theme.spacing(1.5),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  description: {
    fontWeight: 800,
    fontSize: 16,
  },
  detailsList: {
    fontSize: (props: any) => (props.isMobile ? 12 : 14),

    "& strong": {
      fontWeight: 500,
      marginRight: theme.spacing(0.5),
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  ticketPrice: {
    fontWeight: 400,
    fontSize: 16,
    position: "absolute",
    bottom: 10,
    right: 20,
  },
}));

type ProductCardProps = {
  product: GetProductListProductResponse;
  onRowClick: (sku: string) => void;
  onProductDeleted: (sku: string) => void;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onRowClick,
  onProductDeleted,
}: ProductCardProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const Title = () => (
    <Grid container direction="row" justifyContent="center" alignItems="center" flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <span className={classes.description}>{product.description}</span>
      </Grid>
      <Grid item textAlign="end">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onProductDeleted(product.sku);
          }}
          size="small"
        >
          <DeleteOutline />
        </IconButton>
      </Grid>
    </Grid>
  );

  const Details = () => (
    <>
      <Stack className={classes.detailsList}>
        <div>
          <strong>Sku:</strong>
          {product.sku}
        </div>
        <div>
          <strong>Model:</strong>
          {product.model}
        </div>
        <div>
          <strong>Department:</strong>
          {product.department}
        </div>
        <div>
          <strong>Season:</strong>
          {product.season}
        </div>
        <div>
          <strong>Barcode:</strong>
          {product.barcode}
        </div>
      </Stack>

      <span className={classes.ticketPrice}>{formatCurrency(product.ticketPrice)}</span>
    </>
  );

  if (isMobile) {
    return (
      <Paper className={classes.paper} elevation={3} onClick={() => onRowClick(product.sku)}>
        <Title />

        <Grid container alignItems="center">
          <Grid item xs={3} sm={2}>
            <Image src={product.thumbnailUrl} maxHeight={64} />
          </Grid>
          <Grid item xs={9} sm={10}>
            <Details />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper} elevation={3} onClick={() => onRowClick(product.sku)}>
      <Grid container>
        <Grid item md={3}>
          <Image src={product.thumbnailUrl} maxHeight={160} />
        </Grid>

        <Grid item md={9}>
          <Title />
          <Details />
        </Grid>
      </Grid>
    </Paper>
  );
};
