import { COUNTRIES, Country } from "src/components/Context/CountryContext";
import { apiTokenRequest, authProvider } from "src/infrastructure/auth/AuthProvider";

interface Headers {
  'x-jbg-country'?: string;
  'x-jbg-company'?: string;
}

const getAuthHeadersAsync = async () => {
  const token = await authProvider.getToken(apiTokenRequest);
  const headers = {
    Authorization: "Bearer " + token.accessToken,
  };
  return headers;
};

export const getMTHeaders = (country: Country = COUNTRIES.AU): Headers => {
  const headers: Headers = {
    'x-jbg-country': country,
    'x-jbg-company': 'JBH'
  };
  return headers;
};

export const getBaseHeaders = async (country: Country) => {
  const mtHeaders = getMTHeaders(country);
  const authHeaders = await getAuthHeadersAsync();
  return {
    ...mtHeaders,
    ...authHeaders,
  };
};

export const getHeadersWithContentType = async (country: Country, contentType: string = 'application/json') => {
  const headers = await getBaseHeaders(country);
  return {
    "Content-Type": contentType,
    ...headers,
  };
};