import React from "react";
import { FileDropzone } from "./FileDropzone";

type ImageDropzoneProps = {
  formats?: string[];
  maxHeight?: number;
  maxWidth?: number;
  exactSize?: boolean;
  onImageSelected: (image: File | null) => void;
  onValidationError: (errorMessage: string) => void;
};

export const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  formats = ["PNG", "JPG", "JPEG"],
  maxWidth,
  maxHeight,
  exactSize,
  onImageSelected,
  onValidationError,
}: ImageDropzoneProps) => {
  const shouldValidateSize = !!maxWidth && !!maxHeight;

  const getSizeText = (): string => {
    if (!shouldValidateSize) {
      return null;
    }

    const parts = [];

    if (maxWidth) {
      parts.push(`${maxWidth}`);
    }

    if (maxWidth && maxHeight) {
      parts.push("x");
    }

    if (maxHeight) {
      parts.push(`${maxHeight}`);
    }

    if (maxWidth || maxHeight) {
      parts.push("px");
    }

    return parts.join(" ");
  };

  const accept = formats.map((format) => `.${format.toLowerCase()}`).join(", ");
  const subTextParts = [`Must be ${formats.join(", ")}`, getSizeText()];

  const subText = subTextParts.filter(Boolean).join(", ");

  const isValidSize = (actualSize: number, expectedSize: number): boolean => {
    return exactSize ? actualSize === expectedSize : actualSize <= expectedSize;
  };

  const getErrorMessage = (attribute: string, expectedSize: number): string => {
    return exactSize
      ? `Image ${attribute} must be exactly ${expectedSize}px`
      : `Image ${attribute} must not exceed ${expectedSize}px`;
  };

  const validateImageSize = (image: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectUrl = URL.createObjectURL(image);

      img.onload = () => {
        if (maxHeight && !isValidSize(img.height, maxHeight)) {
          reject(getErrorMessage("height", maxHeight));
        }

        if (maxWidth && !isValidSize(img.width, maxWidth)) {
          reject(getErrorMessage("width", maxWidth));
        }

        URL.revokeObjectURL(objectUrl);
        resolve();
      };

      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        reject("Failed to load the image.");
      };

      img.src = objectUrl;
    });
  };

  const handleImageSelected = (image: File | null) => {
    if (!image) {
      onImageSelected(null);
      return;
    }

    if (image.size === 0) {
      onValidationError("The selected image is empty");
      return;
    }

    if (!shouldValidateSize) {
      onImageSelected(image);
    }

    validateImageSize(image)
      .then(() => {
        onImageSelected(image);
      })
      .catch((error) => {
        onValidationError(error);
      });
  };

  return <FileDropzone accept={accept} subText={subText} onFileSelected={handleImageSelected} />;
};
