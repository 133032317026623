import React, { FC } from "react";
import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select, styled } from "@mui/material";
import { ValidationLabel } from "./ValidationLabel";

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    border: 1px solid ${({ theme }) => theme.palette.grey[400]};
    border-radius: 0;
    box-shadow: 2px 2px 0 0 var(--palette-primary-shadow__1yqyf5p6);
    transition: box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1);
    padding: 0.8rem 0.625rem 0.75rem;

    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }

  & fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & * > em {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;

type SelectFieldProps = {
  name: string;
  multiple?: boolean;
  label: string;
  value: any;
  options: Array<{ value: any; label: string }>;
  onChange: (value: any) => void;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
};

export const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const isEmptyValue = (value: any): boolean => {
    return value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0);
  };

  const getLabel = (value: any): string => {
    return props.options.find((option) => option.value === value)?.label || "";
  };

  const handleRenderValue = (selected: any): React.ReactNode => {
    if (isEmptyValue(selected)) {
      return <em>{props.label}</em>;
    }

    return props.multiple === true ? selected.map(getLabel).join(", ") : getLabel(selected);
  };

  return (
    <>
      <StyledSelect
        id={props.name}
        name={props.name}
        multiple={props.multiple}
        displayEmpty
        fullWidth
        autoComplete="off"
        value={props.value}
        label={props.label}
        input={<OutlinedInput />}
        renderValue={handleRenderValue}
        onChange={(e) => props.onChange(e.target.value)}
        error={props.hasError}
        disabled={props.disabled}
      >
        <MenuItem disabled value="">
          <em>{props.label}</em>
        </MenuItem>
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && <Checkbox checked={props.value.indexOf(option.value) >= 0} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </StyledSelect>
      <ValidationLabel errorMessage={props.errorMessage} />
    </>
  );
};
