import { Box, Grid, GridProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";

type KeyFeaturesProps = GridProps & {
  product: GetProductResponse;
  showTitle?: boolean;
};

export const KeyFeatures: React.FC<KeyFeaturesProps> = ({ product, showTitle, ...rest }: KeyFeaturesProps) => {
  return (
    <>
      {showTitle && (
        <Box component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
          Key Features
        </Box>
      )}
      <Grid container {...rest}>
        {product.keyFeatures.map((feature: any, idx: number) => (
          <Grid item key={idx} xs={12} className={idx === product.keyFeatures.length - 1 ? "last" : ""}>
            {feature}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
