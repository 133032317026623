import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { Autocomplete, Box, Stack, TextField, styled, Pagination, Button as MuiButton, Dialog, DialogTitle, DialogContent, Typography, Link, DialogActions, Snackbar, Alert, CircularProgress } from "@mui/material";
import { debounce } from "@mui/material/utils";
import { Description, DownloadingOutlined, SearchOutlined } from "@mui/icons-material";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { ValidationLabel } from "src/components/Form";
import { EmptyListState } from "src/components/EmptyState/EmptyListState";
import { SearchResult } from "src/models/search";
import { fetchMessagingProductsAsync, fetchMessagingProductsBySkusAsync } from "src/infrastructure/http/appApiService";
import { useCountry } from "src/components/Context/CountryContext";
import { ProductRow } from "./ProductRow";
import Papa from "papaparse";
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';

const AutoCompleteTextField = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 0;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

const MAX_SKUS = 5000;

type ProductSelectProps = {
  name: string;
  value: string[];
  onChange: (value: string[]) => void;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
};

const CustomRemoveButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  color: theme.palette.error.main,
  textTransform: 'none',
  padding: '6px 16px',
}));

const CustomDownloadButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  color: theme.palette.primary.main,
  textTransform: 'none',
  padding: '0px 28px',
  lineHeight: '0',
  marginBottom: '-4px',
  fontWeight: 'bold',
  justifyContent: 'flex-start',
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  }
}));

export const ProductSelect: React.FC<ProductSelectProps> = ({
  name,
  value,
  onChange,
  hasError,
  errorMessage,
  disabled,
}: ProductSelectProps) => {
  const { country } = useCountry();

  const [productOptions, setProductOptions] = useState<SearchResult[]>([]);
  const [productSearchValue, setProductSearchValue] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<SearchResult[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>(errorMessage);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [allSkus, setAllSkus] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' as 'error' | 'success' });

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const showToast = useCallback((message: string, severity: 'error' | 'success' = 'error') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false, message: ''}));
  };

  const fetchProducts = useMemo(
    () =>
      debounce(async (searchText: string, callback: (results?: SearchResult[]) => void) => {
        try {
          const results = await fetchMessagingProductsAsync(searchText, 0, 20, country);
          callback(results.results);
        } catch (error) {
          callback(undefined);
        }
      }, 600),
    [country]
  );

  const checkSkuLimit = useCallback((skus: string[]) => {
    if (skus.length > MAX_SKUS) {
      showToast(`Total SKUs exceed the maximum limit of ${MAX_SKUS}. Please remove some SKUs.`);
      return false;
    }
    setValidationMessage("");
    return true;
  }, []);

  const handleBulkSkuUpload = useCallback(async (skus: string[], pageToFetch: number) => {
    if (skus.length === 0) {
      setSelectedProducts([]);
      setTotalCount(0);
      setPage(1);
      return;
    }
  
    try {
      setIsLoading(true);
      const results = await fetchMessagingProductsBySkusAsync(skus, country, pageToFetch, pageSize);
      setSelectedProducts(results.results);
      setTotalCount(results.totalCount);
      
      // Ensure we don't set the page beyond the available pages
      const maxPage = Math.ceil(results.totalCount / pageSize);
      setPage(Math.min(pageToFetch, maxPage));
    } catch (error) {
      setValidationMessage(`An error occurred while fetching products. Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  }, [country, pageSize]);

  useEffect(() => {
    if (!value || value.length === 0) {
      setSelectedProducts([]);
      setTotalCount(0);
      setAllSkus([]);
      setPage(1);
      return;
    }
  
    if (JSON.stringify(value) !== JSON.stringify(allSkus)) {
      if (checkSkuLimit(value)) {
        setAllSkus(value);
        handleBulkSkuUpload(value, page);
      }
    }
  }, [value, country, handleBulkSkuUpload, page, checkSkuLimit]);

  useEffect(() => {
    if (!productSearchValue) {
      setProductOptions([]);
      return;
    }

    setIsLoading(true);

    fetchProducts(productSearchValue, (results?: SearchResult[]) => {
      let newProductOptions: SearchResult[] = results ?? [];

      if (allSkus.length > 0) {
        newProductOptions = newProductOptions.filter((option) => !allSkus.includes(option.sku));
      }

      setProductOptions(newProductOptions);
      setIsLoading(false);
    });
  }, [productSearchValue, fetchProducts, allSkus]);

  const handleProductAdded = useCallback((newValue: SearchResult) => {
    const totalSkus = value.length;
    if (totalSkus >= MAX_SKUS) {
      showToast(`Cannot add more SKUs. Maximum limit of ${MAX_SKUS} SKUs reached.`);
      return;
    }
    const newSkus = [...value, newValue.sku];
    onChange(newSkus);
    setShowOptions(false);
    setProductSearchValue("");
  }, [value, onChange]);

  const handleProductRemoved = useCallback((product: SearchResult) => {
    const newSkus = value.filter((sku) => sku !== product.sku);
    
    setAllSkus(newSkus);
    onChange(newSkus);
  
    const newTotalCount = totalCount - 1;
    const newTotalPages = Math.ceil(newTotalCount / pageSize);

    let pageToFetch = page;
    if (page > newTotalPages) {
      pageToFetch = newTotalPages;
    }
  
    handleBulkSkuUpload(newSkus, pageToFetch);
  }, [value, onChange, totalCount, page, pageSize, handleBulkSkuUpload]);

  const handleBulkUpload = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleModalConfirm = useCallback(() => {
    setIsModalOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (downloadUrl && downloadLinkRef.current) {
      downloadLinkRef.current.click();
      URL.revokeObjectURL(downloadUrl);
      setDownloadUrl(null);
    }
  }, [downloadUrl]);

  const handleDownloadSKUs = useCallback(async () => {
    if (allSkus.length === 0) {
      showToast("No SKUs to download");
      return;
    }

    setIsDownloading(true);

    try {
      const results = await fetchMessagingProductsBySkusAsync(allSkus, country, 1, allSkus.length);
      
      const csvData = results.results.map(product => ({
        SKU: product.sku,
        Description: product.description
      }));

      const csv = Papa.unparse(csvData, {
        header: false
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);

      setTimeout(() => {
        showToast("SKU list downloaded successfully", 'success');
      }, 500);
    } catch (error) {
      console.error("Error downloading SKUs:", error);
      showToast("Error downloading SKU list");
    } finally {
      setIsDownloading(false);
    }
  }, [allSkus, country, showToast]);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          setValidationMessage("");

          const newSkus = results.data
                            .map((row: any[]) => row[0])
                            .filter((sku: any) => typeof sku === 'string' && sku.trim() !== "")

          const updatedSkus = [...new Set([...value, ...newSkus])]; // Merge and deduplicate
  
          if (updatedSkus.length > MAX_SKUS) {
            showToast(`Total SKUs would exceed the maximum limit of ${MAX_SKUS}. Please remove some SKUs before adding more.`);
            return;
          }
  
          onChange(updatedSkus as string[]);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          showToast("Error parsing CSV file");
        }
      });
    }
    if (event.target) {
      event.target.value = '';
    }
  }, [value, onChange]);

  const handleClearAllSkus = useCallback(() => {
    setAllSkus([]);
    setSelectedProducts([]);
    setTotalCount(0);
    setPage(1);
    onChange([]);
  }, [onChange]);

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, newPage: number) => {
    handleBulkSkuUpload(allSkus, newPage);
  }, [allSkus, handleBulkSkuUpload]);

  return (
    <>
      <Stack direction="column" spacing={2}>
      <Stack direction="row" marginBottom={0} justifyContent="right">
      <CustomDownloadButton
              onClick={handleDownloadSKUs}
              disabled={isLoading || isDownloading || allSkus.length === 0}
              startIcon={isDownloading ? <CircularProgress size={16} /> : <DownloadIcon fontSize="small" />}
            >
              {isDownloading ? 'Downloading...' : 'Export all SKUs'}
            </CustomDownloadButton>

            <a
              ref={downloadLinkRef}
              href={downloadUrl || '#'}
              download="sku_list.csv"
              style={{ display: 'none' }}
            >
          </a>

        </Stack>
        <Stack direction="row" spacing={2} marginBottom={2} marginTop={0} justifyContent="center">
          <Autocomplete
            id={`products-autocomplete-${name}`}
            open={showOptions}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
            filterOptions={(x) => x}
            options={productOptions}
            autoComplete
            inputValue={productSearchValue}
            filterSelectedOptions
            loading={isLoading}
            loadingText="Searching for products..."
            noOptionsText="No products found"
            style={{ boxShadow: "2px 2px 0 0 var(--palette-primary-shadow__1yqyf5p6)", flexGrow: 1 }}
            onInputChange={(_, newInputValue) => {
              setProductSearchValue(newInputValue);
              setShowOptions(newInputValue !== "");
            }}
            renderInput={(params) => <AutoCompleteTextField {...params} placeholder="Search by SKU" fullWidth />}
            renderOption={(_, option) => {
              const isAdded = allSkus.includes(option.sku);
              return (
                <ProductRow
                  key={option.sku}
                  product={option}
                  isAdded={isAdded}
                  onClick={isAdded ? handleProductRemoved : handleProductAdded}
                />
              );
            }}
            slotProps={{
              popper: {
                disablePortal: true,
                placement: "bottom",
              },
            }}
          />
          <Button type="secondary" onClick={handleBulkUpload} disabled={isLoading || isDownloading}>
              Bulk Upload
          </Button>  
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".csv"
            onChange={handleFileChange}
          />
        </Stack>

  
        {value.length > 0 ? (
        <Box padding={3} border={1} borderColor={(theme) => theme.palette.grey[400]}>
          {isLoading && selectedProducts.length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100px">
              <CircularProgress />
            </Box>
          ) : (
            selectedProducts.map((product) => (
              <ProductRow key={product.sku} product={product} isAdded={true} onClick={handleProductRemoved} />
            ))
          )}
        </Box>
      ) : (
        <EmptyListState
          icon={SearchOutlined}
          title="No SKUs added"
          message="Please add SKUs by searching from the field above or uploading a CSV file"
        />
      )}
  
        {selectedProducts.length > 0 && (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CustomRemoveButton
              startIcon={<DeleteIconOutlined style={{color: '#d32f2f'}} />}
              onClick={handleClearAllSkus}
              disabled={isLoading || selectedProducts.length === 0}
            >
              Remove all SKUs
            </CustomRemoveButton>
            
            {totalCount > pageSize && (
              <Pagination
                count={Math.ceil(totalCount / pageSize)}
                page={page}
                onChange={handlePageChange}
                disabled={isLoading}
              />
            )}
           <div style={{ width: '175px'}}></div>
          </Stack>
        )}
      </Stack>
      <ValidationLabel errorMessage={validationMessage} />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>Bulk Upload SKUs</DialogTitle>
        <DialogContent>
          <Typography>
            Please upload a <strong>CSV</strong> file containing skus only. 
            <Link 
              href="https://jbhuiprdv3prod.blob.core.windows.net/public/sample-bulk-upload.csv" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Download sample CSV template here
            </Link>.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>Please note:</strong> Bulk upload feature will <strong>append</strong> new skus from CSV onto existing sku list, duplicated skus will only be added once, changes will only take effect after saving the message template.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button type="secondary" onClick={handleModalClose}>Cancel</Button>
          <Button type="primary" onClick={handleModalConfirm}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};