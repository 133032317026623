import React, { memo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Grid, CardActions, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const useStyles = makeStyles((theme) => ({
  avatarBig: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

type UserDetailProps = {
  userProfile: any;
  userPhoto: string;
  logout: () => void;
};

export const UserDetail: React.FC<UserDetailProps> = memo((props: UserDetailProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  if (!props.userProfile) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <Avatar src={`data:image/png;base64,${props.userPhoto}`} onClick={handleMenu} />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Avatar className={classes.avatarBig} src={`data:image/png;base64,${props.userPhoto}`} />
              </Grid>
              <Grid item>
                <div>{props.userProfile.displayName}</div>
                <div>{props.userProfile.staffId}</div>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justifyContent="center" alignItems="center">
              <Button variant="contained" color="primary" onClick={props.logout}>
                Sign out
              </Button>
            </Grid>
          </CardActions>
        </Card>
      </Popover>
    </React.Fragment>
  );
});
