import { useMemo, useState } from "react";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { CircularProgress, Divider, Grid } from "@mui/material";
import _ from "lodash";
import { TextField } from "src/components/Form/TextField";
import { GetProductResponse } from "src/models/product";
import { Accordion } from "../../Shared/Accordion";
import usePurchaseOrders from "src/hooks/http/usePurchaseOrders";
import { formatDateOffset } from "src/utils/formatUtil";

type PurchaseOrdersTabProps = {
  product: GetProductResponse;
};

export const PurchaseOrdersTab: React.FC<PurchaseOrdersTabProps> = ({ product }: PurchaseOrdersTabProps) => {
  const { isLoading, data } = usePurchaseOrders(product.sku);

  const [searchText, setSearchText] = useState("");

  const purchaseOrders = useMemo(() => {
    let dataList = _.chain(data);

    if (searchText) {
      const search = searchText.toLowerCase();
      dataList = dataList.filter((item) => _.includes(item.locationName?.toLowerCase(), search));
    }

    return dataList.value();
  }, [data, searchText]);

  const accordionItems = purchaseOrders?.map((purchaseOrder) => ({
    head: (
      <Grid container columns={12}>
        <Grid item xs={12}>
          <strong>
            {purchaseOrder.locationId} {purchaseOrder.locationName}
          </strong>
        </Grid>

        <Grid item xs={8}>
          PO: <strong>{purchaseOrder.purchaseOrderId}</strong>
        </Grid>
        <Grid item xs={4}>
          Quantity: <strong>{purchaseOrder.quantity}</strong>
        </Grid>
      </Grid>
    ),
    closeIcon: <RemoveOutlined />,
    openIcon: <AddOutlined />,
    content: (
      <Grid container padding={1}>
        <Grid item xs={4}>
          Supplier
        </Grid>
        <Grid item xs={8}>
          {purchaseOrder.vendorName}
        </Grid>

        <Grid item xs={4}>
          Received
        </Grid>
        <Grid item xs={8}>
          {purchaseOrder.receivedQuantity}
        </Grid>

        <Grid item xs={4}>
          Remain
        </Grid>
        <Grid item xs={8}>
          {purchaseOrder.remainingQuantity}
        </Grid>

        <Grid item xs={4}>
          Created
        </Grid>
        <Grid item xs={8}>
          {formatDateOffset(purchaseOrder.dateCreated, "DD/MM/YYYY")}
        </Grid>

        <Grid item xs={4}>
          Released
        </Grid>
        <Grid item xs={8}>
          {formatDateOffset(purchaseOrder.dateIssued, "DD/MM/YYYY")}
        </Grid>

        <Grid item xs={4}>
          Expected
        </Grid>
        <Grid item xs={8}>
          {formatDateOffset(purchaseOrder.dateExpected, "DD/MM/YYYY")}
        </Grid>

        <Grid item xs={4}>
          Status
        </Grid>
        <Grid item xs={8}>
          {purchaseOrder.status}
        </Grid>
      </Grid>
    ),
  }));

  return (
    <Grid container>
      <Grid item xs={12} marginTop={1.5}>
        <TextField label="Search for Location" value={searchText} onChange={(e) => setSearchText(e)} />
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Accordion
            id="purchase-orders-accordion"
            items={accordionItems}
            defaultActiveIndex={null}
            withBodyBackground
            unmountOnClose
          />
        )}
      </Grid>
    </Grid>
  );
};
