import moment from "moment";

const momentDateFormatSql = "YYYY-MM-DDTHH:mm:ssZ";
const defaultDateTimeFormat = "D MMM YYYY h:mm A";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const dateStrToMoment = (dateStr: string): moment.Moment => {
  return moment(dateStr, momentDateFormatSql);
};

export const formatDateOffset = (dateStr: string, format?: string): string => {
  if (!dateStr) {
    return "";
  }

  return moment.parseZone(dateStr).format(format ?? defaultDateTimeFormat);
};

export const formatMoment = (date: moment.Moment, format?: string): string => {
  return date.format(format ?? defaultDateTimeFormat);
};

export const pascalEnumToString = (text: string) => {
  if (!text) {
    return "";
  }

  return text.replace(/([A-Z])/g, " $1").trim();
}

export const boolToYesNo = (bool: boolean) => {
  return bool ? "Yes" : "No";
}

export const getNthString = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const formatCurrency = (value: any) => {
  if (!value) {
    return null;
  }

  return currencyFormatter.format(Number(value));
};