import { Grid, GridProps } from "@mui/material";
import { COUNTRIES } from "src/components/Context/CountryContext";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { GetProductResponse } from "src/models/product";
import { boolToYesNo, formatDateOffset } from "src/utils/formatUtil";

type GeneralProps = GridProps & {
  product: GetProductResponse;
  isRanged: boolean;
  showTitle?: boolean;
};

export const General: React.FC<GeneralProps> = ({ product, isRanged, showTitle, ...rest }: GeneralProps) => {
  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);
  const manufacturersWarranty =
    product?.specifications?.find((m) => m.isEnabled && m.name === "Manufacturer's warranty")?.values?.[0]?.value ?? "";

  return (
    <Grid container {...rest}>
      {showTitle && (
        <Grid item xs={12} component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
          General
        </Grid>
      )}

      <Grid item xs={6} md={8} lg={6}>
        Model
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {product.model}
      </Grid>

      {product.artist && product.department === "MUSIC" && (
        <>
          <Grid item xs={6} md={8} lg={6}>
            Artist
          </Grid>
          <Grid item xs={6} md={8} lg={6}>
            {product.artist}
          </Grid>
        </>
      )}

      <Grid item xs={6} md={8} lg={6}>
        Product Group
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {product.productGroup}
      </Grid>

      <Grid item xs={6} md={8} lg={6}>
        Season Code
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {product.season}
      </Grid>

      {product.sellerName && product.isMarketplace && (
        <>
          <Grid item xs={6} md={8} lg={6}>
            Seller
          </Grid>
          <Grid item xs={6} md={4} lg={6}>
            {product.sellerName}
          </Grid>
        </>
      )}

      <Grid item xs={6} md={8} lg={6}>
        Manufacturers Warranty
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {manufacturersWarranty}
      </Grid>

      {shouldHideForNZ && !product.isMarketplace && (
        <>
          <Grid item xs={6} md={8} lg={6}>
            Ranged
          </Grid>
          <Grid item xs={6} md={4} lg={6}>
            {boolToYesNo(isRanged)}
          </Grid>
        </>
      )}

      <Grid item xs={6} md={8} lg={6}>
        Category
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {product.category}
      </Grid>

      <Grid item xs={6} md={8} lg={6}>
        Release Date
      </Grid>
      <Grid item xs={6} md={4} lg={6}>
        {formatDateOffset(product.releaseDate, "DD MMM YYYY")}
      </Grid>
    </Grid>
  );
};
