import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, CircularProgress } from "@mui/material";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { MobileProductDetail } from "./Mobile/MobileProductDetail";
import { DesktopProductDetail } from "./Desktop/DesktopProductDetail";
import { useIsMobile } from "src/hooks/useIsMobile";
import { useProductDetails } from "src/hooks/http/useProductDetails";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  circularProgress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

type ProductDetailRouteParams = {
  sku: string;
};

type ProductDetailProps = {
  userProfile: UserProfile;
};

export const ProductDetail: React.FC<ProductDetailProps> = ({ userProfile }: ProductDetailProps) => {
  const classes = useStyles();

  const isMobile = useIsMobile();
  const { sku } = useParams<ProductDetailRouteParams>();

  const { isLoading, error, product } = useProductDetails(sku);

  if (isLoading) {
    return (
      <Box className={classes.mainContainer}>
        <CircularProgress color="primary" className={classes.circularProgress} />
      </Box>
    );
  }

  if (error || !product || typeof product.sku === "undefined") {
    return (
      <Box className={classes.mainContainer}>
        An error occurred while loading Sku {sku}. Error: {error}
      </Box>
    );
  }

  return isMobile ? (
    <MobileProductDetail product={product} userProfile={userProfile} />
  ) : (
    <DesktopProductDetail product={product} userProfile={userProfile} />
  );
};
