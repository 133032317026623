export const generateErrorMessages = (obj: any, parentKey: string = ''): string[] => {
  let errorMessages: string[] = [];

  for (const key in obj) {
    if ('message' in obj[key]) {
      errorMessages.push(obj[key].message);
      continue;
    }

    if (key === 'ref' || key === 'type') {
      continue;
    }

    const newParentKey = parentKey !== '' ? `${parentKey} / ${key}` : key;
    errorMessages = errorMessages.concat(generateErrorMessages(obj[key], newParentKey));
  }

  return errorMessages;
};