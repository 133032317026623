import { FC } from "react";
import { Stack } from "@mui/material";
import { AddOutlined, DeleteOutline } from "@mui/icons-material";
import { SearchResult } from "src/models/search";
import { Button } from "src/components/Form";
import { Image } from "src/components/Product/Image";

type ProductRowProps = {
  product: SearchResult;
  isAdded: boolean;
  onClick: (product: SearchResult) => void;
};

export const ProductRow: FC<ProductRowProps> = ({ product, isAdded, onClick }: ProductRowProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      paddingY={2}
      borderBottom={1}
      borderColor={(theme) => theme.palette.grey[400]}
      width="100%"
    >
      <Image src={product.imageUrl} maxHeight={50} />
      <div style={{ flexGrow: 1 }}>
        <div>
          <strong>{product.description}</strong>
        </div>
        <div>SKU: {product.sku}</div>
      </div>
      {isAdded ? (
        <Button type="link" onClick={() => onClick(product)}>
          <DeleteOutline />
        </Button>
      ) : (
        <Button type="iconOnly" icon={<AddOutlined />} onClick={() => onClick(product)} />
      )}
    </Stack>
  );
};
