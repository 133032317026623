import { GlobalState } from "little-state-machine"
import { SearchResult } from "src/models/search";

export const updatePinnedProductDetails = (
  state: GlobalState,
  payload: SearchResult[]
) => {
  if (!payload) {
    return {
      ...state,
      pinnedProductDetails: []
    };
  }

  return {
    ...state,
    pinnedProductDetails: [
      ...payload
    ]
  };
}
