import React from "react";
import { styled, TextField as MuiTextField, InputAdornment, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    content: "none",
    borderRadius: 0,
    backgroundColor: "white",
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    boxShadow: "2px 2px 0 0 rgba(0, 0, 0, 0.08)",
    "&:hover": { backgroundColor: "white", border: "1px solid", borderBottom: "1px solid" },
    "&.Mui-focused": {
      border: "1px solid",
      borderColor: "black",
      backgroundColor: "white",
      boxShadow: "none",
      borderBottom: "1px solid",
    },
    padding: "0px 0px",
  },
  "& .MuiInputLabel-root": {
    marginTop: "-3px",
  },
  "& .MuiInputBase-input": {
    marginTop: "-10px",
    fontSize: "16px",
  },
  "& .MuiInputBase-root": {
    "&:before": { content: "none", borderBottom: "0px" },
  },
  "& .MuiInputLabel-shrink": {
    marginTop: "-3px",
    fontSize: "15px",
  },
  "& .MuiFilledInput-root:after": {
    content: "none",
  },
}));

type TextFieldProps = {
  id?: string;
  name?: string;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const TextField: React.FC<TextFieldProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  onKeyDown,
}: TextFieldProps) => {
  return (
    <StyledTextField
      id={id}
      name={name}
      autoComplete="off"
      fullWidth
      variant="filled"
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: value && (
          <InputAdornment position="end" sx={{ padding: "12px" }}>
            <IconButton edge="end" onClick={() => onChange("")}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
