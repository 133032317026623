import { useContext } from "react";
import PageContext from "src/components/Context/PageContext";
import { COUNTRIES, useCountry } from "src/components/Context/CountryContext";

const defaultOnlineLocationByCountry: { [key in COUNTRIES]: number } = {
  [COUNTRIES.NZ]: 30,
  [COUNTRIES.AU]: 115,
};

const defaultSupportOfficeLocationByCountry: { [key in COUNTRIES]: number } = {
  [COUNTRIES.NZ]: 10,
  [COUNTRIES.AU]: 1,
};

type CurrentUserStoreResult = {
  currentLocationId: number;
  inventoryLocationId: number;
  isSupportOffice: boolean;
};

export const useCurrentUserStore = (): CurrentUserStoreResult => {
  const { country } = useCountry();

  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;

  const supportOfficeLocation = defaultSupportOfficeLocationByCountry[country];

  if (userProfile?.storeNumber) {
    return {
      currentLocationId: userProfile.storeNumber,
      inventoryLocationId: userProfile.storeNumber,
      isSupportOffice: userProfile.storeNumber === supportOfficeLocation,
    };
  }

  return {
    currentLocationId: supportOfficeLocation,
    inventoryLocationId: supportOfficeLocation,
    isSupportOffice: true,
  };
};
