export enum ProductSpecifications {
  ButtonBatteries = "Contains button battery",
  DroneWeightClass = "Drone weight class [2023 CASA regulation]",
  GasCylinder = "Contains gas cylinder (delivery unavailable)",
  Rideables = "Rideables device type",
  ProductCondition = "Product condition",
  ApparelType = "Apparel type",
  GameType = "Game type",
  BlindBoxProduct = "Blind box product",
  WallMountableDryer = "Wall mountable dryer",
  LicensedElectrician = "Installation requires a licensed electrician",
  LicensedTradesman = "Installation requires a licensed tradesperson",
  DisplaySizingChart = "Display sizing chart",
  QueenslandMarketOnly = "Queensland market only",
  NonQueenslandMarketOnly = "Non-Queensland market only"
}

export const productSpecificationsOptions = [
  {
    name: ProductSpecifications.ButtonBatteries,
    dataType: "String",
    value: "Yes",
    label: "Contains button battery",
    category: "Safety"
  },
  {
    name: ProductSpecifications.DroneWeightClass,
    dataType: "String",
    value: "Below 250g",
    label: "Drone weight class [2023 CASA regulation] < 250g",
    category: "Drones"
  },
  {
    name: ProductSpecifications.DroneWeightClass,
    dataType: "String",
    value: "250g and above",
    label: "Drone weight class [2023 CASA regulation] >= 250g",
    category: "Drones"
  },
  {
    name: ProductSpecifications.GasCylinder,
    dataType: "String",
    value: "Yes",
    label: "Contains gas cylinder (delivery unavailable)",
    category: "Safety"
  },
  {
    name: ProductSpecifications.Rideables,
    dataType: "String",
    value: "Electric scooters",
    label: "Rideables device type [Electric scooter]",
    category: "Safety"
  },
  {
    name: ProductSpecifications.ProductCondition,
    dataType: "String",
    value: "Refurbished",
    label: "Refurbished product",
    category: "Product Condition"
  },
  {
    name: ProductSpecifications.ProductCondition,
    dataType: "String",
    value: "Renewed",
    label: "Renewed product",
    category: "Product Condition"
  },
  {
    name: ProductSpecifications.ProductCondition,
    dataType: "String",
    value: "Renewed Apple Watch",
    label: "Renewed product - Apple watch",
    category: "Product Condition"
  },
  {
    name: ProductSpecifications.ProductCondition,
    dataType: "String",
    value: "Ex-display",
    label: "Ex-display",
    category: "Product Condition"
  },
  {
    name: ProductSpecifications.GameType,
    dataType: "String",
    value: "Trading card games",
    label: "No return policy",
    category: "Collectibles & Merchandise"
  },
  {
    name: ProductSpecifications.BlindBoxProduct,
    dataType: "String",
    value: "Yes",
    label: "Blind box",
    category: "Collectibles & Merchandise"
  },
  {
    name: ProductSpecifications.WallMountableDryer,
    dataType: "String",
    value: "No",
    label: "Not wall mountable",
    category: "Installation"
  },
  {
    name: ProductSpecifications.LicensedElectrician,
    dataType: "String",
    value: "Yes",
    label: "Installation requires a licensed electrician",
    category: "Installation"
  },
  {
    name: ProductSpecifications.LicensedTradesman,
    dataType: "String",
    value: "Yes",
    label: "Installation requires a licensed tradesman",
    category: "Installation"
  },
  {
    name: ProductSpecifications.DisplaySizingChart,
    dataType: "String",
    value: "Yes",
    label: "Display sizing chart",
    category: "Collectibles & Merchandise"
  },
  {
    name: ProductSpecifications.QueenslandMarketOnly,
    dataType: "String",
    value: "Yes",
    label: "Queensland market only",
    category: "Market Restrictions"
  },
  {
    name: ProductSpecifications.NonQueenslandMarketOnly,
    dataType: "String",
    value: "Yes",
    label: "Non-Queensland market only",
    category: "Market Restrictions"
  }
];
