import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import TextField from "@jbhi-fi/lanyard-ui/components/TextField/TextField";
import DataGrid from "../Product/Desktop/components/DataGrid";
import { JBPricingHistoryResponse } from "src/models/product/JBPricingHistoryResponse";
import { fetchJBPricingHistoryAsync } from "src/infrastructure/http/appApiService";
import { useCountry } from "src/components/Context/CountryContext";
import { booleanType, currencyType, dateTimeType } from "src/utils/dataGridFormat";
import PageContext from "src/components/Context/PageContext";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";

const JBHistoricPricing: React.FC = () => {
  const { country } = useCountry();

  const [searching, setSearching] = useState(false);
  const [product, setProduct] = useState<JBPricingHistoryResponse[]>();
  const [sku, setSku] = useState<string>();
  const [hasError, setHasError] = useState(false);

  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;

  if (!hasPermission(UserRoles.PricingHistoricPricing, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Time",
      ...dateTimeType,
      flex: 1,
    },
    {
      field: "displayPriceInc",
      headerName: "Price",
      ...currencyType,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "wasPrice",
      headerName: "Was Price",
      ...currencyType,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "displayWasPrice",
      headerName: "Display Was Price",
      ...booleanType,
      flex: 1,
    },
    {
      field: "promosActive",
      headerName: "Active Promo",
      width: 150,
      renderCell: (value) => {
        return value.value.join(" ");
      },
    },
    {
      field: "promosApplied",
      headerName: "Applied Promo",
      width: 150,
      renderCell: (value) => {
        return value.value.join(" ");
      },
    },
  ];

  const handleSearch = async () => {
    try {
      setHasError(false);
      setSearching(true);
      const response = await fetchJBPricingHistoryAsync(sku, country);
      if (response === undefined || response.length === 0) {
        setHasError(true);
      } else {
        setProduct(response);
      }
      setSearching(false);
    } catch (e) {
      console.log(e);
      setSearching(false);
      setHasError(true);
    }
  };

  return (
    <>
      <Grid container paddingBottom={2}>
        <Grid item xs={12} paddingBottom={2}>
          <h2>Pricing History</h2>
        </Grid>

        <Grid item paddingRight={2}>
          <TextField
            id="pricingHistorySearch"
            name="pricingHistorySearch"
            hasError={hasError}
            errorMsg="SKU not found."
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            placeholder="Search By SKU"
            onChange={(e) => setSku(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button type={"primary"} onClick={handleSearch} loading={searching} disabled={searching}>
            SEARCH
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {product && (
          <DataGrid
            columns={columns}
            rows={product}
            isLoading={searching}
            getRowId={(row) => row.dataGridRowId}
            hideFooter
            autoHeight
            disableColumnResize
            getRowHeight={() => "auto"}
            sx={{
              "& .MuiDataGrid-row": {
                minHeight: "52px !important",
              },
            }}
          ></DataGrid>
        )}
      </Grid>
    </>
  );
};

export default JBHistoricPricing;
