const freeTextRegexString = ".+?";
const freeTextWithNoHtmlRegexString = "[^<>]+?";
const linkRegexString = "http[s]?:\\/\\/.+?";

const ctaOpeningTagMatchRegex = /&lt;cta href=/g;
const ctaClosingTagMatchRegex = /&lt;\/cta&gt;/g;
const ctaCompleteTagMatchRegex = new RegExp(`&lt;cta href="${linkRegexString}"&gt;${freeTextWithNoHtmlRegexString}&lt;\/cta&gt;`, "g");

const chatOpeningTagMatchRegex = /&lt;chat department=/g;
const chatClosingTagMatchRegex = /&lt;\/chat&gt;/g;
const chatCompleteTagMatchRegex = new RegExp(`&lt;chat department="${freeTextWithNoHtmlRegexString}"&gt;${freeTextWithNoHtmlRegexString}&lt;\/chat&gt;`, "g");

const disclaimerOpeningTagMatchRegex = /&lt;disclaimer&gt;/g;
const disclaimerClosingTagMatchRegex = /&lt;\/disclaimer&gt;/g;
const disclaimerCompleteTagMatchRegex = new RegExp(`&lt;disclaimer&gt;${freeTextRegexString}&lt;\/disclaimer&gt;`, "g");

export const validateMessageCustomTags = (content, openingTagMatchRegex, closingTagMatchRegex, completeTagMatchRegex) => {
  const openingTagsCount = (content.match(openingTagMatchRegex) || []).length;
  if (openingTagsCount > 0) {
    const closingTagsCount = (content.match(closingTagMatchRegex) || []).length;
    const completeTagsCount = (content.match(completeTagMatchRegex) || []).length;
    if (openingTagsCount !== closingTagsCount || openingTagsCount !== completeTagsCount) {
      return {
        isValid: false,
        errorMessage: ''
      };
    }
  }
  return { isValid: true, errorMessage: "" };
};

export const validateDescriptionTags = (description) => {
  if (!description) return { disclaimerValidation: { isValid: true, errorMessage: "" }, ctaValidation: { isValid: true, errorMessage: "" }, chatValidation: { isValid: true, errorMessage: "" } }; // Allow null or empty values

  const disclaimerValidation = validateMessageCustomTags(description, disclaimerOpeningTagMatchRegex, disclaimerClosingTagMatchRegex, disclaimerCompleteTagMatchRegex);
  const ctaValidation = validateMessageCustomTags(description, ctaOpeningTagMatchRegex, ctaClosingTagMatchRegex, ctaCompleteTagMatchRegex);
  const chatValidation = validateMessageCustomTags(description, chatOpeningTagMatchRegex, chatClosingTagMatchRegex, chatCompleteTagMatchRegex);

  return { disclaimerValidation, ctaValidation, chatValidation };
};
