import { useState, useRef } from "react";
import LoadingContext from "./LoadingContext";
import LoadingDialog from "../Loading/LoadingDialog";

const LoadingProvider = ({ children, ...props }) => {
  const [progress, setProgress] = useState({ isOpen: false, message: null });
  const progressContext = useRef(null);

  const show = (message = null) => {
    setProgress({ isOpen: true, message: message || "Loading" });
  };

  const hide = () => {
    setProgress({ isOpen: false, message: null });
  };

  progressContext.current = {
    show,
    hide,
  };

  return (
    <LoadingContext.Provider value={progressContext}>
      <LoadingDialog isOpen={progress.isOpen} message={progress.message} />
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
