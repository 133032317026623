import { Grid } from "@mui/material";

export const GenericItem = (props) => {
  const { title, caption } = props;

  return (
    <Grid key={title} item xs={12} paddingBottom={1}>
      <p>
        <strong>{title}</strong>
      </p>
      <p>{caption}</p>
    </Grid>
  );
};
