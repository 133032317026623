import { useState, useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import { Alert, AlertTitle, Box } from "@mui/material";
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { validateDescriptionTags } from "src/validationSchemas/descriptionValidationUtils";
import { ValidationLabel } from "src/components/Form/ValidationLabel";
import htmlEditButton from "quill-html-edit-button";

Quill.register('modules/htmlEditButton', htmlEditButton);

function insertDisclaimer(quill) {
  const cursorPosition = quill.getSelection().index;
  const disclaimerText = "<disclaimer>INSERT TEXT</disclaimer>";
  quill.insertText(cursorPosition, disclaimerText);
  quill.setSelection(cursorPosition + disclaimerText.length);
}

function insertCta(quill) {
  const cursorPosition = quill.getSelection().index;
  const ctaText = "<cta href=\"http://example.com\">INSERT LABEL</cta>";
  quill.insertText(cursorPosition, ctaText);
  quill.setSelection(cursorPosition + ctaText.length);
}

function insertChat(quill) {
  const cursorPosition = quill.getSelection().index;
  const chatText = "<chat department=\"INSERT DEPARTMENT\">INSERT TEXT</chat>";
  quill.insertText(cursorPosition, chatText);
  quill.setSelection(cursorPosition + chatText.length);
}

function clearFormat(quill) {
  const range = quill.getSelection();
  if (range) {
    quill.removeFormat(range.index, range.length);
  }
}

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ql-toolbar": {
      borderColor: (props: any) => (props.hasError ? theme.palette.error.main : theme.palette.grey[400]),
      cursor: 'text !important',
      textDecoration: 'none !important',
      position: 'relative'
    },
    "& .ql-container": {
      borderColor: (props: any) => (props.hasError ? theme.palette.error.main : theme.palette.grey[400]),
      "& .ql-editor": {
        minHeight: (props: any) => props.minHeight,
      },
    },
    "& .ql-insertCta": {
      width: 'auto',
      minWidth: '38px',
      padding: '0 8px',
      fontSize: '15px',
      color: '#454545 !important ',
      marginRight: '0px',
    },
    "& .ql-insertCta::first-letter": {
      letterSpacing: '-1.5px',
    },
    "& .ql-clearFormat": {
      color: '#454545',
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center'
    },
    "& .ql-insertChat": {
      color: '#454545 !important ',
    },
    "& .ql-insertChat:hover": {
      color: '#207AD3 !important',
    },
    "& .ql-insertDisclaimer": {
      color: '#454545 !important ',
    },
    "& .ql-insertDisclaimer:hover": {
      color: '#207AD3 !important',
    },
    "& button": {
      color: '#FFFFFF !important',
      margin: '0 !important',
      display: 'flex'
    },
    "& .ql-insertCta:hover": {
      color: '#207AD3 !important',
    },
    "& button:last-child": {
      //need to change this so that it is moved to the far right
      display: 'hidden',
      position: 'absolute',
      right: '0px',
      pointerEvents: 'auto',
      cursor: 'text',
      title: '',
      "&:hover": {
        cursor: 'text',
      },
      "&::before, &::after": {
        display: 'none !important',
      }
    },
  }
}));

const MessageRichTextEditor = ({ value, onChange, hasError, errorMessage, minHeight, isTypeMessage }) => {
  const classes = useStyles({ minHeight, hasError, isTypeMessage });
  const [editorHtml, setEditorHtml] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [validationMessages, setValidationMessages] = useState([]);
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) return;

    quillRef.current = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: {
          container: '#toolbar',
          handlers: {
            insertDisclaimer: () => insertDisclaimer(quillRef.current),
            insertCta: () => insertCta(quillRef.current),
            insertChat: () => insertChat(quillRef.current),
            clearFormat: () => clearFormat(quillRef.current),
          },
        },
        htmlEditButton: {}
      },
    });

    quillRef.current.on('text-change', () => {
      const html = quillRef.current.root.innerHTML;
      handleChange(html);
    });
  }, []);

  useEffect(() => {
    if (quillRef.current && value !== editorHtml) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange(html);

    const { disclaimerValidation, ctaValidation, chatValidation } = validateDescriptionTags(html);

    const messages = [];

    if (!disclaimerValidation.isValid) {
      messages.push(`Invalid disclaimer tag. Required format: <disclaimer>INSERT TEXT</disclaimer>`);
    }

    if (!ctaValidation.isValid) {
      messages.push(`Invalid cta tag. Required format: <cta href=\"http://example.com\">INSERT LABEL</cta>`);
    }

    if (!chatValidation.isValid) {
      messages.push(`Invalid chat tag. Required format: <chat department="INSERT DEPARTMENT">INSERT TEXT</chat>`);
    }

    setValidationMessages(messages);
    setIsValid(messages.length === 0);
  };

  return (
    <div className={classes.richTextEditor}>
      <div id="toolbar">
        <button className="ql-bold" title="Bold" />
        <button className="ql-italic" title="Italic" />
        <button className="ql-link" title="Link" />
        <button className="ql-list" value="ordered" title="Ordered list" />
        <button className="ql-list" value="bullet" title="Unordered list" />
        <button className="ql-clearFormat" title="Clear format">
          <svg
            width="15"
            height="15"
            viewBox="0 0 12 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="ql-clearFormat"
          >
            <path
              d="M11.2495 10.4437H6.3295L11.0714 5.70187C11.2578 5.51307 11.3623 5.25843 11.3623 4.99312C11.3623 4.72781 11.2578 4.47316 11.0714 4.28437L8.1445 1.35936C7.95619 1.17205 7.70136 1.06689 7.43575 1.06689C7.17013 1.06689 6.91532 1.17205 6.727 1.35936L0.937001 7.16062C0.749681 7.34892 0.644531 7.60376 0.644531 7.86937C0.644531 8.13498 0.749681 8.38979 0.937001 8.57812L3.4195 11.0625C3.45482 11.1021 3.49815 11.1339 3.54664 11.1555C3.59511 11.1772 3.64765 11.1883 3.70075 11.1881H11.2495C11.3403 11.1769 11.4239 11.1329 11.4846 11.0643C11.5452 10.9958 11.5787 10.9074 11.5787 10.8159C11.5787 10.7244 11.5452 10.636 11.4846 10.5675C11.4239 10.4989 11.3403 10.4549 11.2495 10.4437ZM7.25762 1.88999C7.30534 1.84293 7.36967 1.81654 7.43668 1.81654C7.50371 1.81654 7.56803 1.84293 7.61575 1.88999L10.5407 4.81499C10.5877 4.86239 10.614 4.92641 10.614 4.99312C10.614 5.05983 10.5877 5.12384 10.5407 5.17124L9.14575 6.56249L5.8645 3.28312L7.25762 1.88999Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button className="ql-insertDisclaimer" title="Insert disclaimer" style={{ display: isTypeMessage ? "none" : "block" }}>
          <InfoIcon classes={{ root: "ql-insertDisclaimer" }} />
        </button>
        <button className="ql-insertChat" title="Insert chat" style={{ display: isTypeMessage ? "none" : "block" }}>
          <ChatBubbleIcon classes={{ root: "ql-insertChat" }} />
        </button>
        <button className="ql-insertCta" title="Insert cta" style={{ display: isTypeMessage ? "none" : "block" }}>
          CTA
        </button>
      </div>
      <div id="editor" />
      {hasError && <ValidationLabel errorMessage={errorMessage} />}
      {!isValid && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {validationMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Alert>
        </Box>
      )}
    </div>
  );
};

export default MessageRichTextEditor;