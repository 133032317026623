import { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import JbShort from "@jbhi-fi/lanyard-ui/components/Icons/JbShort";

const useStyles = makeStyles((theme) => ({
  productImage: {
    maxHeight: (props: any) => props.maxHeight,
    maxWidth: "100%",
    height: "auto",
  },
  emptyImageContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    display: "inline-block",
    maxHeight: (props: any) => props.maxHeight,
    maxWidth: "100%",
    height: "auto",
  },
  emptyImage: {
    color: theme.palette.grey[400],
    maxWidth: "100%",
    fontSize: (props: any) => props.maxHeight - 18,
  },
}));

type ImageProps = {
  src: string;
  maxHeight: number;
};

export const Image: React.FC<ImageProps> = ({ src, maxHeight }: ImageProps) => {
  const classes = useStyles({ maxHeight });

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsError(true);
  };

  if (!src || isError) {
    return (
      <Box className={classes.emptyImageContainer}>
        <JbShort className={classes.emptyImage} />
      </Box>
    );
  }

  return (
    <>
      {isLoading && (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{ display: "inline-block" }}
          width={maxHeight}
          height={maxHeight}
        />
      )}

      <img src={src} alt="" className={classes.productImage} onLoad={handleImageLoaded} onError={handleImageError} />
    </>
  );
};
