import { FC } from "react";
import { Box } from "@mui/material";

interface WasPriceUsageProps {
  isVisible: boolean;
}

export const WasPriceUsage: FC<WasPriceUsageProps> = ({ isVisible }: WasPriceUsageProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box margin={2} sx={{ fontSize: 12 }}>
      <Box fontWeight={600}>Usage</Box>
      <div>
        1: Add Skus by clicking the ADD SKU button
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; or bulk upload by downloading the provided template and using the bulk upload function.
      </div>
      <div>
        2: Choose the start and end dates using the Date Pickers. Verify that the Start and End Dates are correct.
      </div>
      <div>
        3: Add the comment, this should reference why you are loading this was price override. <br />
        &nbsp;&nbsp;&nbsp;&nbsp;(e.g. to align to printed catalogue starting 31/10/23).
      </div>
      <div>
        4: Add the Was Prices to the Data Grid below for viewing and verify that the values and end dates are correct.
        <br />
      </div>
      <div>
        5: After you have reviewed the table at the bottom, click the 'Submit' button to load the Was Prices into the
        database.
      </div>
      <Box marginTop={2} fontWeight={600}>
        Notes
      </Box>
      <div>
        Only one end date can be generated for each list of skus.
        <br />
        If you need to generate was prices for multiple dates please process them seperately.
        <br />
      </div>
    </Box>
  );
};
