import React, { useState } from "react";
import copy from "copy-to-clipboard";
import { MenuItem } from "@mui/material";
import { SnackbarAlert } from "../SnackbarAlert/SnackbarAlert";

export const CopyLinkMenuItem: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    copy(window.location.href);
    setOpen(true);
  };

  return (
    <>
      <MenuItem id="copy-link" onClick={onClick} sx={{ fontSize: 14 }}>
        Copy link
      </MenuItem>

      <SnackbarAlert open={open} onClose={() => setOpen(false)} message="Link copied!" severity="success" />
    </>
  );
};
