import { useStateMachine } from "little-state-machine";
import { useCountry } from "src/components/Context/CountryContext";
import { fetchPinnedProductsAsync, updatePinnedProductsAsync } from "src/infrastructure/http/appApiService";
import { updatePinnedProductDetails } from "src/store/actions";

type TogglePinResult = {
  onPinClickCallback: (sku: string) => Promise<void>
};

export const useTogglePin = (): TogglePinResult => {
  const { country } = useCountry();
  const { state, actions } = useStateMachine({ updatePinnedProductDetails });

  const mergePinnedProducts = async (sku: string) => {
    let updatedSkus = [...state.pinnedProductDetails.map(m => m.sku)];
    const isPinned = updatedSkus.includes(sku);

    if (isPinned) {
      updatedSkus = updatedSkus.filter(m => m !== sku);
    } else {
      updatedSkus.push(sku);
    }

    await updatePinnedProductsAsync(updatedSkus, country);
  };

  const fetchPinnedProducts = async () => {
    try {
      const pinnedProductDetails = await fetchPinnedProductsAsync(country);
      actions.updatePinnedProductDetails(pinnedProductDetails);
    } catch (e) {
    }
  };

  const onPinClickCallback = async (sku: string) => {
    await mergePinnedProducts(sku);
    await fetchPinnedProducts();
    document.dispatchEvent(new CustomEvent("onTrackPins"));
  };

  return { onPinClickCallback };
};