import { useMemo, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import Checkbox from "@jbhi-fi/lanyard-ui/components/Checkbox";
import _ from "lodash";
import { Accordion } from "../../Shared/Accordion";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import Permission from "src/components/Permission/Permission";
import { BooleanDisplay } from "../../Shared/BooleanDisplay";
import { GetProductResponse } from "src/models/product";
import { TextField } from "src/components/Form/TextField";
import { SortByOrder, SortOption, Sortable } from "./Sortable";

type SohTabProps = {
  product: GetProductResponse;
  userProfile: UserProfile;
};

const SortByField = {
  Distance: "distanceKms",
  AvailableSOH: "available",
};

const sortByFields: SortOption[] = [
  {
    field: SortByField.AvailableSOH,
    label: "Avail SOH",
  },
  {
    field: SortByField.Distance,
    label: "Distance",
  },
];

export const SohTab: React.FC<SohTabProps> = ({ product, userProfile }: SohTabProps) => {
  const [availableOnly, setAvailableOnly] = useState(false);
  const [warehouseOnly, setWarehouseOnly] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortByField, setSortByField] = useState(SortByField.Distance);
  const [sortByOrder, setSortByOrder] = useState(SortByOrder.Asc);

  const inventoryItems = useMemo(() => {
    let dataList = _.chain(product?.inventory);

    if (availableOnly) {
      dataList = dataList.filter((item) => item.available > 0);
    }

    if (warehouseOnly) {
      dataList = dataList.filter((item) => item.locationType === "Warehouse");
    }

    if (searchText) {
      const search = searchText.toLowerCase();
      dataList = dataList.filter(
        (item) => _.includes(item.locationName?.toLowerCase(), search) || _.includes(item.state?.toLowerCase(), search)
      );
    }

    return dataList.orderBy([sortByField], [sortByOrder]).value();
  }, [product?.inventory, availableOnly, warehouseOnly, searchText, sortByField, sortByOrder]);

  const accordionItems = inventoryItems?.map((inventory) => ({
    head: (
      <Grid container columns={12}>
        <Grid item xs={12}>
          <strong>{inventory.locationName}</strong>
        </Grid>

        <Grid item xs={5}>
          Store No: <strong>{inventory.locationId}</strong>
        </Grid>
        <Grid item xs={7}>
          Distance: <strong>{inventory.distanceKms} km</strong>
        </Grid>

        <Grid item xs={5}>
          Avail SOH: <strong>{inventory.available}</strong>
        </Grid>
        <Grid item xs={4}>
          In transit: <strong>{inventory.inTransit}</strong>
        </Grid>
        <Grid item xs={3}>
          PO: <strong>{inventory.purchaseOrder}</strong>
        </Grid>
      </Grid>
    ),
    closeIcon: <RemoveOutlined />,
    openIcon: <AddOutlined />,
    content: (
      <Grid container padding={1}>
        <Grid item xs={4}>
          Phone
        </Grid>
        <Grid item xs={8}>
          <strong>{inventory.locationPhone}</strong>
        </Grid>

        <Grid item xs={12} marginY={0.5} />

        <Grid item xs={4}>
          Address
        </Grid>
        <Grid item xs={8}>
          {inventory.locationAddress?.line1 && (
            <>
              <strong>{inventory.locationAddress?.line1}</strong>
              <br />
            </>
          )}
          {inventory.locationAddress?.line2 && (
            <>
              <strong>{inventory.locationAddress?.line2}</strong>
              <br />
            </>
          )}
          {inventory.locationAddress?.line3 && (
            <>
              <strong>{inventory.locationAddress?.line3}</strong>
              <br />
            </>
          )}
          <strong>
            {inventory.locationAddress?.suburb}, {inventory.locationAddress?.state}{" "}
            {inventory.locationAddress?.postcode}
          </strong>
        </Grid>

        <Grid item xs={12} marginY={1}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          Total SOH
        </Grid>
        <Grid item xs={8}>
          <strong>{inventory.soh}</strong>
        </Grid>

        <>
          <Grid item xs={4}>
            Type
          </Grid>
          <Grid item xs={8}>
            {inventory.locationType}
          </Grid>

          {inventory.state && (
            <>
              <Grid item xs={4}>
                State
              </Grid>
              <Grid item xs={8}>
                {inventory.state}
              </Grid>
            </>
          )}

          <Grid item xs={4}>
            Area Manager
          </Grid>
          <Grid item xs={8}>
            {inventory.areaManager}
          </Grid>
        </>

        <Grid item xs={12} marginY={1}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Grid container paddingRight={0.5} alignItems="center">
            <Grid item xs={8}>
              Customer Order
            </Grid>
            <Grid item xs={4}>
              {inventory.customerOrder}
            </Grid>

            <Grid item xs={8}>
              In Store CO
            </Grid>
            <Grid item xs={4}>
              {inventory.inStoreCustomerOrder}
            </Grid>

            <Grid item xs={8}>
              Faulty
            </Grid>
            <Grid item xs={4}>
              {inventory.faulty}
            </Grid>

            <Grid item xs={8}>
              Display
            </Grid>
            <Grid item xs={4}>
              {inventory.display}
            </Grid>

            <Grid item xs={8}>
              Repack
            </Grid>
            <Grid item xs={4}>
              {inventory.repack}
            </Grid>

            <Permission userRoles={userProfile.roles} componentName={UserRoles.InventoryDetails}>
              <>
                <Grid item xs={8}>
                  Trf Out
                </Grid>
                <Grid item xs={4}>
                  {inventory.transferOut}
                </Grid>

                <Grid item xs={8}>
                  Avail Delivery
                </Grid>
                <Grid item xs={4}>
                  {inventory.availableForDelivery}
                </Grid>

                <Grid item xs={8}>
                  Avail Click & Collect
                </Grid>
                <Grid item xs={4}>
                  {inventory.availableForClickAndCollect}
                </Grid>

                <Grid item xs={8}>
                  Avail Cash & Carry
                </Grid>
                <Grid item xs={4}>
                  {inventory.availableForCashAndCarry}
                </Grid>

                <Grid item xs={8}>
                  Avail Online
                </Grid>
                <Grid item xs={4}>
                  {inventory.onlineAvailable}
                </Grid>
              </>
            </Permission>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container paddingLeft={0.5} alignItems="center">
            <Grid item xs={8}>
              Receive+
            </Grid>
            <Grid item xs={4}>
              {inventory.pendingReceive}
            </Grid>

            <Grid item xs={8}>
              SOR
            </Grid>
            <Grid item xs={4}>
              {inventory.jbrl}
            </Grid>

            <Grid item xs={8}>
              Adjust-
            </Grid>
            <Grid item xs={4}>
              {inventory.pendingAdjustSub}
            </Grid>

            <Grid item xs={8}>
              Fraud Check
            </Grid>
            <Grid item xs={4}>
              {inventory.fraudCheck}
            </Grid>

            <Grid item xs={8}>
              Pending Credit
            </Grid>
            <Grid item xs={4}>
              {inventory.pendingCredit}
            </Grid>

            <Permission userRoles={userProfile.roles} componentName={UserRoles.InventoryDetails}>
              <>
                <Grid item xs={8}>
                  Buffer
                </Grid>
                <Grid item xs={4}>
                  {inventory.buffer}
                </Grid>

                <Grid item xs={8}>
                  Is Avail Delivery
                </Grid>
                <Grid item xs={4}>
                  <BooleanDisplay value={true} useIcons />
                </Grid>

                <Grid item xs={8}>
                  Is Avail Click & Collect
                </Grid>
                <Grid item xs={4}>
                  <BooleanDisplay value={inventory.isAvailableForClickAndCollect} useIcons />
                </Grid>

                <Grid item xs={8}>
                  Is Avail Cash & Carry
                </Grid>
                <Grid item xs={4}>
                  <BooleanDisplay value={inventory.isAvailableForCashAndCarry} useIcons />
                </Grid>

                <Grid item xs={8}>
                  Ranged
                </Grid>
                <Grid item xs={4}>
                  <BooleanDisplay value={inventory.isRanged} useIcons />
                </Grid>
              </>
            </Permission>
          </Grid>
        </Grid>
      </Grid>
    ),
  }));

  const toggleAvailableOnly = () => {
    setAvailableOnly(!availableOnly);
  };

  const toggleWarehouseOnly = () => {
    setWarehouseOnly(!warehouseOnly);
  };

  return (
    <Grid container>
      <Grid item xs={7} marginTop={1.5}>
        <TextField label="Search for Location or State" value={searchText} onChange={(e) => setSearchText(e)} />
      </Grid>

      <Grid item xs={5} marginTop={1.5}>
        <Sortable
          fields={sortByFields}
          sortByField={sortByField}
          sortByOrder={sortByOrder}
          onFieldChange={(f) => setSortByField(f)}
          onOrderChange={(o) => setSortByOrder(o)}
        />
      </Grid>

      <Grid item xs={6} marginTop={1.5}>
        <Checkbox
          id="available-only"
          name="available-only"
          label="Available Only"
          checked={availableOnly}
          onChange={toggleAvailableOnly}
        />
      </Grid>

      <Grid item xs={6} marginTop={1.5}>
        <Checkbox
          id="warehouse-only"
          name="warehouse-only"
          label="Warehouse Only"
          checked={warehouseOnly}
          onChange={toggleWarehouseOnly}
        />
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Accordion
          id="soh-accordion"
          items={accordionItems}
          defaultActiveIndex={null}
          withBodyBackground
          unmountOnClose
        />
      </Grid>
    </Grid>
  );
};
