import React, { useState } from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Tabs, Tab } from "@jbhi-fi/lanyard-ui/components/Tabs";
import { MyListsSelect } from "../../../components/Lists/MyListsSelect";
import { ProductInfoTab } from "./components/ProductInfoTab";
import { SohTab } from "./components/SohTab";
import { DetailsTab } from "./components/DetailsTab";
import { ProductActionMenu } from "src/components/Product/ProductActionMenu";
import usePromos from "src/hooks/http/usePromos";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import { OnlineTab } from "../Shared/Online/OnlineTab";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { PinProductButton } from "src/components/Product/PinProductButton";
import { TabTitle } from "../Shared/TabTitle";
import { PurchaseOrdersTab } from "./components/PurchaseOrdersTab";
import { GetProductResponse } from "src/models/product";
import { AddToListButton } from "src/components/Product/AddToListButton";
import { useShouldHideForCountry } from "src/hooks/useShouldHideForCountry";
import { COUNTRIES } from "src/components/Context/CountryContext";
import { MarketplaceTag } from "src/components/Product/MarketplaceTag";
import HistoricPricing from "../Shared/HistoricPricing";

const useStyles = makeStyles((theme) => ({
  labelTitle: {
    marginRight: theme.spacing(1),
  },
}));

enum MobileProductTab {
  ProductInfo = "ProductInfo",
  SOH = "SOH",
  Details = "Details",
  Online = "Online",
  PurchaseOrders = "PurchaseOrders",
  HistoricalPricing = "HistoricalPricing",
}

type MobileProductDetailProps = {
  product: GetProductResponse;
  userProfile: UserProfile;
};

export const MobileProductDetail: React.FC<MobileProductDetailProps> = ({
  product,
  userProfile,
}: MobileProductDetailProps) => {
  const classes = useStyles();
  const promos = usePromos(product.sku);
  const shouldHideForNZ = useShouldHideForCountry(COUNTRIES.NZ);

  const [myListsOpen, setMyListsOpen] = useState(false);

  const productTabs = [
    {
      tab: MobileProductTab.ProductInfo,
      title: "Product Info",
      shortTitle: "Info",
      minWidth: 110,
      permission: null,
      component: <ProductInfoTab product={product} userProfile={userProfile} promos={promos.data} />,
    },
    !product.isMarketplace
      ? {
          tab: MobileProductTab.SOH,
          title: "SOH",
          permission: null,
          component: <SohTab product={product} userProfile={userProfile} />,
        }
      : null,
    {
      tab: MobileProductTab.Details,
      title: "Details",
      permission: null,
      component: <DetailsTab product={product} />,
    },
    shouldHideForNZ && !product.isMarketplace
      ? {
          tab: MobileProductTab.PurchaseOrders,
          title: "Purchase Orders",
          shortTitle: "PO",
          minWidth: 140,
          permission: UserRoles.ProductPurchaseOrders,
          component: <PurchaseOrdersTab product={product} />,
        }
      : null,
       {
          tab: MobileProductTab.Online,
          title: "Online",
          permission: UserRoles.ProductOnlineDetails,
          component: <OnlineTab product={product} userProfile={userProfile} />,
        },
    product.isMarketplace
      ? {
          tab: MobileProductTab.HistoricalPricing,
          title: "Pricing History",
          minWidth: 140,
          permission: UserRoles.PricingHistoricPricing,
          component: <HistoricPricing product={product} />,
        }
      : null,
  ].filter(Boolean);

  return (
    <Grid container alignItems="center">
      <Grid item xs={7} component="h1">
        Product Details
      </Grid>

      <Grid item xs={5} textAlign="right">
        <PinProductButton sku={product.sku} />
        <AddToListButton onClick={() => setMyListsOpen(true)} />
        <ProductActionMenu sku={product.sku} />
      </Grid>

      {product.isMarketplace && (
        <Grid item xs={12} marginTop={1}>
          <MarketplaceTag />
        </Grid>
      )}

      <Grid item xs={12} marginY={1}>
        <h2>{product.description}</h2>
      </Grid>

      <Grid item xs={6}>
        <span className={classes.labelTitle}>SKU:</span>
        <strong>{product.sku}</strong>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <span className={classes.labelTitle}>PLU:</span>
        <strong>{product.plu}</strong>
      </Grid>

      <Grid item xs={12} marginTop={1}>
        <Tabs name="tabs" activeTab={MobileProductTab.ProductInfo}>
          {productTabs.map((tab) => {
            if (tab.permission === null || hasPermission(tab.permission, userProfile.roles)) {
              return (
                <Tab
                  key={tab.tab}
                  label={<TabTitle title={tab.title} shortTitle={tab.shortTitle} minWidth={tab.minWidth} />}
                  value={tab.tab}
                  variant="Modern"
                >
                  {tab.component}
                </Tab>
              );
            }

            return <React.Fragment key={tab.tab}></React.Fragment>;
          })}
        </Tabs>
      </Grid>

      <MyListsSelect open={myListsOpen} handleClose={() => setMyListsOpen(false)} sku={product.sku} />
    </Grid>
  );
};
