import { Theme, createTheme } from "@mui/material/styles";

declare module '@mui/system' {
  interface DefaultTheme extends Theme { }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    white?: string;
    error?: string;
    yellow?: string;
  }

  interface SimplePaletteColorOptions {
    white?: string;
    error?: string;
    yellow?: string;
  }

  interface Palette {
    custom?: {
      jbAppBar?: {
        background?: string;
        color?: string;
        boxShadow?: string;
      };
      search?: {
        buttonHover?: string;
      };
      vendorInvoice?: {
        errorColor?: string;
        errorBackground?: string;
        qtyChangeBackground?: string;
      };
      vendorInvoiceDetail?: {
        lightBlue?: string;
        darkBlue?: string;
        lightRed?: string;
        darkRed?: string;
        lightGreen?: string;
        darkGreen?: string;
        lightOrange?: string;
        darkOrange?: string;
      };
      menu?: {
        yellow?: string;
        default?: string;
        red?: string;
        green?: string;
      };
      warn?: string;
      shade?: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      jbAppBar?: {
        background?: string;
        color?: string;
        boxShadow?: string;
      };
      search?: {
        buttonHover?: string;
      };
      vendorInvoice?: {
        errorColor?: string;
        errorBackground?: string;
        qtyChangeBackground?: string;
      };
      vendorInvoiceDetail?: {
        lightBlue?: string;
        darkBlue?: string;
        lightRed?: string;
        darkRed?: string;
        lightGreen?: string;
        darkGreen?: string;
        lightOrange?: string;
        darkOrange?: string;
      };
      menu?: {
        yellow?: string;
        default?: string;
        red?: string;
        green?: string;
      };
      warn?: string;
      shade?: string;
    };
  }
}

const jbColour = {
  yellow: "#ffec0f",
  orange: {
    primary: "#de8803",
    border: "#da6a00",
    background: "#fff9f0",
  },
  grey: {
    light: "#ebebeb",
  },
  blue: {
    border: "#095CA0",
    background: "#E8FAFF",
  },
  green: {
    primary: "#028702",
    border: "#028702",
    background: "#F2F9F2",
  },
  red: {
    primary: "#ff0000",
    border: "#e02020",
    background: "#fdf2f2",
  },
};

const muiTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '6px 16px'
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#000000"
        }
      }
    },
  },
  palette: {
    primary: {
      main: "#000000",
      light: "#cccccc",
      dark: "#000000",
      white: "#ffffff",
      yellow: jbColour.yellow
    },
    secondary: {
      main: jbColour.green.primary,
      error: jbColour.red.primary,
    },
    custom: {
      jbAppBar: {
        background: jbColour.yellow,
        color: "#000000",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
      },
      search: {
        buttonHover: "#000000",
      },
      vendorInvoice: {
        errorColor: "#e65050",
        errorBackground: "#fdf2f2",
        qtyChangeBackground: jbColour.grey.light,
      },
      vendorInvoiceDetail: {
        lightBlue: jbColour.blue.background,
        darkBlue: jbColour.blue.border,
        lightRed: jbColour.red.background,
        darkRed: jbColour.red.border,
        lightGreen: jbColour.green.background,
        darkGreen: jbColour.green.border,
        lightOrange: jbColour.orange.background,
        darkOrange: jbColour.orange.border,
      },
      menu: {
        yellow: jbColour.yellow,
        default: "#ffffff",
        red: "#ff0000",
        green: "#00ff00",
      },
      warn: jbColour.orange.primary,
      shade: jbColour.grey.light,
    },
  },
  shape: {
    borderRadius: 0,
  },
});

export const theme = muiTheme;