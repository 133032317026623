import { useState, useEffect, useContext } from "react";
import { useStateMachine } from "little-state-machine";
import PageContext from "../../components/Context/PageContext";
import {
  fetchInitialAppDataAsync,
  fetchMyListsAsync,
  fetchPinnedProductsAsync,
} from "../../infrastructure/http/appApiService";
import { useCountry } from "../../components/Context/CountryContext";
import { updateMyLists, updatePinnedProductDetails } from "src/store/actions";

const useInitialAppData = () => {
  const [isLoadingAppData, setIsLoadingAppData] = useState(false);
  const [appDataError, setAppDataError] = useState(null);

  const [isLoadingAppState, setIsLoadingAppState] = useState(false);
  const [appStateError, setAppStateError] = useState(null);

  const [contextData, setContextData] = useContext(PageContext);
  const { country } = useCountry();

  const { actions } = useStateMachine({ updateMyLists, updatePinnedProductDetails });

  const fetchInitialAppData = async () => {
    try {
      setIsLoadingAppData(true);
      const response = await fetchInitialAppDataAsync(country);
      setContextData({
        ...contextData,
        appData: response.appData,
        userSettings: response.userSettings,
      });
    } catch (e) {
      console.error(e.message);
      setAppDataError("An error occurred while loading the application data. Please reload the page and try again.");
    } finally {
      setIsLoadingAppData(false);
    }
  };

  const fetchInitialAppState = async () => {
    setIsLoadingAppState(true);

    try {
      const myLists = await fetchMyListsAsync(country);
      actions.updateMyLists(myLists);
    } catch (e) {
      console.error(e.message);
      setAppStateError("An error occurred while loading the product lists. Please reload the page and try again.");
    }

    try {
      const pinnedProductDetails = await fetchPinnedProductsAsync(country);
      actions.updatePinnedProductDetails(pinnedProductDetails);
    } catch (e) {
      console.error(e.message);
      setAppStateError("An error occurred while loading the pinned products. Please reload the page and try again.");
    }

    setIsLoadingAppState(false);
  };

  useEffect(() => {
    fetchInitialAppData();
    fetchInitialAppState();
  }, []);

  return {
    isLoading: isLoadingAppData || isLoadingAppState,
    error: appDataError || appStateError,
  };
};

export default useInitialAppData;
