import React from "react";
import { IconButton, CssBaseline, AppBar, Toolbar, Hidden, Box } from "@mui/material";
import { UserDetail } from "./UserDetail";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import { useCountry } from "../Context/CountryContext";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    marginLeft: theme.spacing(1.5),
    whiteSpace: "nowrap",
  },
}));

type JbAppBarProps = {
  onMenuClick: () => void;
  searchBar: React.ReactNode;
  children: React.ReactNode;
  logout: () => void;
  userPhoto: string;
  userProfile: any;
};

export const JbAppBar: React.FC<JbAppBarProps> = (props: JbAppBarProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { country } = useCountry();

  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar style={theme.palette.custom.jbAppBar}>
        <Toolbar>
          <IconButton edge="start" color="primary" onClick={props.onMenuClick} size="large">
            <MenuIcon />
          </IconButton>
          <Hidden mdDown>
            <Box className={classes.title}>{`Products ${country}`}</Box>
          </Hidden>

          <Box marginX={1.5} flexGrow={1}>
            {props.searchBar}
          </Box>

          <UserDetail logout={props.logout} userPhoto={props.userPhoto} userProfile={props.userProfile} />
        </Toolbar>
      </AppBar>

      {props.children}
    </React.Fragment>
  );
};
