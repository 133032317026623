import React, { useState } from "react";
import { Button, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { Check, North, South } from "@mui/icons-material";

export enum SortByOrder {
  Asc = "asc",
  Desc = "desc",
}

export type SortOption = {
  field: string;
  label: string;
};

type SortableProps = {
  fields: SortOption[];
  sortByField: string;
  sortByOrder: SortByOrder;
  onFieldChange: (field: string) => void;
  onOrderChange: (order: SortByOrder) => void;
};

export const Sortable: React.FC<SortableProps> = ({
  fields,
  sortByField,
  sortByOrder,
  onFieldChange,
  onOrderChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const label = fields.find((field) => field.field === sortByField)?.label;

  const handleLabelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = (field: string) => {
    onFieldChange(field);
    handleMenuClose();
  };

  const handleOrderChange = () => {
    onOrderChange(sortByOrder === SortByOrder.Asc ? SortByOrder.Desc : SortByOrder.Asc);
  };

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item flexGrow={1} textAlign="right">
        <Button
          variant="text"
          sx={{ paddingX: 1 }}
          onClick={handleLabelClick}
          aria-controls={isMenuOpen ? "sortable-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          {label}
        </Button>

        <Menu
          id="sortable-menu"
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorEl={anchorEl}
          MenuListProps={{
            sx: { paddingY: 0 },
          }}
        >
          {fields.map((field) => (
            <MenuItem
              key={field.field}
              selected={field.field === sortByField}
              onClick={() => handleFieldChange(field.field)}
              sx={{ fontSize: 14 }}
            >
              {field.label}
              {field.field === sortByField && <Check sx={{ fontSize: 16, marginLeft: 1 }} />}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item>
        <IconButton sx={{ paddingX: 0.5 }} onClick={() => handleOrderChange()}>
          {sortByOrder === SortByOrder.Asc ? <North /> : <South />}
        </IconButton>
      </Grid>
    </Grid>
  );
};
