import React, { useState } from "react";
import { MyListsSelect } from "../../../components/Lists/MyListsSelect";
import { ProductCard } from "./ProductCard";
import { SearchResultState } from "src/models/search";
import { Paging } from "./Paging";
import { EmptyListState } from "src/components/EmptyState/EmptyListState";
import { SearchOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

type SearchResultsProps = {
  pageSize: number;
  searchResultState: SearchResultState;
  onPageChange: (newPage: number) => void;
  onRowClick: (sku: string) => void;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  pageSize,
  searchResultState,
  onPageChange,
  onRowClick,
}: SearchResultsProps) => {
  const [myListsOpen, setMyListsOpen] = useState(false);
  const [myListsSku, setMyListsSku] = useState("");

  const handleAddToListClick = (sku: string) => {
    setMyListsSku(sku);
    setMyListsOpen(true);
  };

  if (searchResultState.rows === 0) {
    return (
      <Box marginTop={2.5}>
        <EmptyListState
          icon={SearchOutlined}
          title="No products found in this category..."
          message="Try adjusting your search criteria to view more results..."
          paddingY={5}
        />
      </Box>
    );
  }

  return (
    <>
      {searchResultState.results?.map((searchResult, idx) => (
        <ProductCard key={idx} product={searchResult} onRowClick={onRowClick} onAddToListClick={handleAddToListClick} />
      ))}

      {searchResultState.rows > pageSize && (
        <Paging
          pageSize={pageSize}
          rowCount={searchResultState.rows}
          page={searchResultState.page}
          onPageChange={onPageChange}
        />
      )}

      <MyListsSelect open={myListsOpen} handleClose={() => setMyListsOpen(false)} sku={myListsSku} />
    </>
  );
};
