import React, { useMemo, useState } from "react";
import { useStateMachine } from "little-state-machine";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, List, ListItem } from "@mui/material";
import { PushPin } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { EmptyListState } from "../../../components/EmptyState/EmptyListState";
import { Paging } from "./Paging";
import { useTogglePin } from "src/hooks/useTogglePin";
import { useIsMobile } from "src/hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  resultList: {
    marginBottom: theme.spacing(1),

    "& li": {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderBottom: 0,
      padding: theme.spacing(1),
    },

    "& li:last-child": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  description: {
    fontWeight: "bold",
  },
  sku: {
    color: theme.palette.grey[500],
  },
}));

type PinnedProductsProps = {
  onRowClick: (sku: string) => void;
};

const PinnedProducts: React.FC<PinnedProductsProps> = ({ onRowClick }: PinnedProductsProps) => {
  const classes = useStyles();

  const { onPinClickCallback } = useTogglePin();
  const { state } = useStateMachine();
  const isMobile = useIsMobile();

  const productsPerPage = isMobile ? 5 : 10;
  const [currentPage, setCurrentPage] = useState(0);

  const products = useMemo(() => {
    return state.pinnedProductDetails.slice(0, (currentPage + 1) * productsPerPage);
  }, [state.pinnedProductDetails, productsPerPage, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  if (!state.pinnedProductDetails || state.pinnedProductDetails.length === 0) {
    return (
      <EmptyListState
        icon={PushPin}
        title="Looks like you have no pinned products..."
        message="To pin products, tap the pin icon at the top of the product detail page for the product you wish to pin."
      />
    );
  }

  return (
    <>
      <List className={classes.resultList} disablePadding>
        {products.map((pinnedProduct, idx) => (
          <ListItem key={idx} onClick={() => onRowClick(pinnedProduct.sku)}>
            <Grid container alignItems="center" flexWrap="nowrap">
              <Grid item>
                <img src={pinnedProduct.thumbnailUrl} width="48" height="48" alt="" />
              </Grid>
              <Grid item marginX={1} flexGrow={1}>
                <p className={classes.description}>{pinnedProduct.description}</p>
                <p className={classes.sku}>
                  <strong>SKU:</strong> {pinnedProduct.sku}
                </p>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={async (e) => {
                    e.stopPropagation();
                    await onPinClickCallback(pinnedProduct.sku);
                  }}
                  size="small"
                  style={{ padding: 0 }}
                >
                  <PushPin />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>

      {state.pinnedProductDetails.length > productsPerPage && (
        <Paging
          rowCount={state.pinnedProductDetails.length}
          pageSize={productsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default PinnedProducts;
