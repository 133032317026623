import { useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useIsPinned } from "src/hooks/useIsPinned";
import { useTogglePin } from "src/hooks/useTogglePin";
import { PushPin, PushPinOutlined } from "@mui/icons-material";

type PinButtonProps = {
  sku: string;
  size?: "small" | "medium" | "large";
};

export const PinProductButton: React.FC<PinButtonProps> = ({ sku, size }: PinButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const isPinned = useIsPinned(sku);
  const { onPinClickCallback } = useTogglePin();

  const handlePinClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the event from bubbling up to the parent, i.e. the row click event on the product cards
    event.stopPropagation();
    setIsLoading(true);
    await onPinClickCallback(sku);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <IconButton size={size} disabled>
        <CircularProgress size={20} />
      </IconButton>
    );
  }

  return (
    <IconButton size={size} onClick={handlePinClick}>
      {isPinned ? <PushPin /> : <PushPinOutlined />}
    </IconButton>
  );
};
