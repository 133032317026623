import { useState, useEffect } from "react";
import { fetchInventoryTransactionsAsync } from "../../infrastructure/http/appApiService";
import { useCountry } from "../../components/Context/CountryContext";
import { InventoryTransaction } from "src/models/product";

const useInventoryTransactions = (sku: string, locationId: number) => {
  const [data, setData] = useState<InventoryTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { country } = useCountry();

  useEffect(() => {
    const fetchData = async (sku: string, locationId: number) => {
      try {
        if (!sku && !locationId) return;
        setIsLoading(true);
        const response = await fetchInventoryTransactionsAsync(sku, locationId, country);
        setData(response);
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData(sku, locationId);
  }, [sku, locationId, country]);

  return { isLoading, data, error };
};

export default useInventoryTransactions;