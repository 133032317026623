import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "42px !important",
    lineHeight: "46px !important",
  },
}));

type TitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export const Title: React.FC<TitleProps> = ({ className, children, ...rest }: TitleProps) => {
  const classes = useStyles();

  return (
    <h1 className={`${classes.title} ${className}`} {...rest}>
      {children}
    </h1>
  );
};
