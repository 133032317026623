import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { ReleaseNoteDetails } from "./ReleaseNoteDetails";

type ReleaseNotesModalProps = {
  releaseNotes: Array<any>;
};

export const ReleaseNotesModal: React.FC<ReleaseNotesModalProps> = ({ releaseNotes }: ReleaseNotesModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  if (!releaseNotes || releaseNotes?.length === 0) {
    return <></>;
  }

  return (
    <Modal
      title="Whats new?"
      isOpen={isOpen}
      primaryButtonName="Ok got it!"
      onPrimaryButtonClick={() => setIsOpen(false)}
    >
      <ReleaseNoteDetails releaseNotes={releaseNotes} />
    </Modal>
  );
};
