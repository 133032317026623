import { useEffect, useState } from "react";
import { useStateMachine } from "little-state-machine";
import { useHistory, useParams } from "react-router-dom";
import { MoreVert, PlaylistAdd } from "@mui/icons-material";
import { Box, CircularProgress, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { useCountry } from "src/components/Context/CountryContext";
import {
  deleteMyListAsync,
  deleteMyListItemAsync,
  fetchMyListDetailsAsync,
} from "src/infrastructure/http/appApiService";
import { ProductList as ListModel } from "src/models/appData";
import { getConsts } from "src/utils/consts";
import { ProductCard } from "./components/ProductCard";
import { EmptyListState } from "src/components/EmptyState/EmptyListState";

type ProductListRouteParams = {
  id: string;
};

export const ProductList: React.FC = () => {
  const { id } = useParams<ProductListRouteParams>();

  const history = useHistory();
  const { country } = useCountry();
  const { state } = useStateMachine();

  const [list, setList] = useState<ListModel>();
  const [listDetails, setListDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const consts = getConsts();

  useEffect(() => {
    const fetchData = async (listName: string) => {
      const results = await fetchMyListDetailsAsync(listName, country);
      setListDetails(results);
      setIsLoading(false);
    };

    const list = state.myLists.find((m) => m.id === id);
    if (!list) {
      history.replace(`${consts.routerPath.home}`);
      return;
    }

    setList(list);
    setIsLoading(true);
    fetchData(list.name);
  }, [id]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListDelete = async () => {
    await deleteMyListAsync(list.name, country);
    history.push(consts.routerPath.home);
  };

  const handleRowClick = (sku: string) => {
    history.push(`${consts.routerPath.product}/${sku}`);
  };

  const handleProductDeleted = async (sku: string) => {
    await deleteMyListItemAsync(list.name, sku, country);
    const results = await fetchMyListDetailsAsync(list.name, country);
    setListDetails(results);
  };

  if (!list) {
    return null;
  }

  const Header = () => (
    <Grid container marginBottom={1} alignItems="center" flexWrap="nowrap">
      <Grid item flexGrow={1} component="h3">
        {list.name}
      </Grid>
      <Grid item>
        <IconButton aria-haspopup="true" size="small" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="list-actions-menu"
          aria-labelledby="list-actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            sx: { paddingY: 0 },
          }}
        >
          <MenuItem onClick={() => handleListDelete()} sx={{ fontSize: 14 }}>
            Delete list
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );

  const Body = () =>
    listDetails && listDetails.length > 0 ? (
      <>
        {listDetails.map((product) => (
          <ProductCard
            key={product.sku}
            product={product}
            onRowClick={handleRowClick}
            onProductDeleted={handleProductDeleted}
          />
        ))}
      </>
    ) : (
      <EmptyListState
        icon={PlaylistAdd}
        title="Looks like this list is empty..."
        message="Add products to this list by tapping the 'add to list' icon at the top of the product detail page."
      />
    );

  return (
    <Box component="section" maxWidth={725} marginX="auto">
      <Header />
      {isLoading ? <CircularProgress /> : <Body />}
    </Box>
  );
};
