import React, { useContext, useEffect, useRef, useState } from "react";
import { parse, ParseResult } from "papaparse";
import { Moment } from "moment-timezone";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarIcon, DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { sendWasPricesAsync } from "../../infrastructure/http/appApiService";
import { COUNTRIES, useCountry } from "src/components/Context/CountryContext";
import { WasPriceUsage } from "./WasPriceUsage";
import { WasPriceConfirmationDialog } from "./Dialogs/WasPriceConfirmationDialog";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import PageContext from "src/components/Context/PageContext";
import { Close, Download } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import ResetConfirmationDialog from "./Dialogs/ResetConfirmationDialog";
import AddSkuDialog from "./Dialogs/AddSkuDialog";
import { useTooltipMessages } from "./Hooks/useToolTipMessages";
import TooltipWrapper from "./TooltipWrapper";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";

var moment = require("moment-timezone");
moment.tz.setDefault("Australia/Sydney");
const localDateFormat = "DD/MM/YYYY HH:mm:ss";
const UTCFormat = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

type TypeOption = "Public" | "Perks";

export interface WasPriceOverride {
  id: number;
  Sku: string;
  WasPriceOverride: number;
  StartTime: string;
  EndTime: string;
  StartDate: string;
  EndDate: string;
  Error: string;
  Comment: string;
  AuthorisedBy: string;
  Type: TypeOption;
  LocationId: number;
}

export interface WasPrice {
  id: number;
  Sku: string;
  WasPriceOverride: number;
}

const columns: GridColDef[] = [
  { field: "Sku", headerName: "Sku", width: 100 },
  {
    field: "WasPriceOverride",
    headerName: "Was Price",
    width: 100,
  },
  {
    field: "StartTime",
    headerName: "Start Date",
    width: 170,
    disableExport: true,
  },
  {
    field: "EndTime",
    headerName: "End Date",
    width: 170,
    disableExport: true,
  },
  { field: "Type", headerName: "Type", width: 100 },
  { field: "AuthorisedBy", headerName: "Authorised By", width: 200 },
  { field: "Comment", headerName: "Comment", width: 400 },
];

const WasPricesComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { country } = useCountry();
  const timeZone = country === COUNTRIES.AU ? "Australia/Sydney" : "Pacific/Auckland";
  const momentInstance = moment.tz.setDefault(timeZone);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [endTime, setEndTime] = useState<Moment | null>(moment().hour(23).minute(59)); // default to 23:59
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;

  //Single SKU Overrides
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);

  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  function CustomToolbar() {
    return <GridToolbarContainer>{endDate && comment && generated && <GridToolbarExport />}</GridToolbarContainer>;
  }

  //State to store the values
  const [values, setValues] = useState<WasPrice[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [headerError, setHeaderError] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [type, setType] = useState<any>("Public");
  const [generated, setGenerated] = useState<boolean>();
  const [postingToApi, setPostingToApi] = useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<boolean>(false);
  const [startNow, setStartNow] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "info",
  });

  const verifyValues = (wasPrices: WasPrice[]) => {
    let csvErrors = [];

    wasPrices.forEach((item, index) => {
      if (!item?.Sku.trim() || item.WasPriceOverride === undefined || isNaN(item.WasPriceOverride)) {
        csvErrors.push(`Row ${index + 1}: Empty Sku or invalid WasPriceOverride`);
      } else if (item.WasPriceOverride < 0) {
        csvErrors.push(`Row ${index + 1}: WasPriceOverride cannot be negative`);
      }

      // Check if Sku contains only alphanumeric characters
      if (!/^[a-zA-Z0-9]+$/.test(item.Sku)) {
        csvErrors.push(`Row ${index + 1}: Sku contains invalid characters`);
      }
    });

    if (csvErrors.length) {
      setErrors(csvErrors);
    } else {
      setErrors([]);
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderError("");
    setGenerated(false);
    const expectedHeaders = ["Sku", "WasPriceOverride"];

    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    parse(file, {
      header: true,
      skipEmptyLines: true,
      beforeFirstChunk: function (chunk) {
        const rows = chunk.split("\n");
        if (rows.length > 0) {
          const headers = rows[0].split(",").map((h) => h.trim());
          if (!_.isEqual(headers, expectedHeaders)) {
            setHeaderError("CSV headers do not match the provided template.");
          }
        }
      },
      complete: function (results: ParseResult<Record<any, any>>) {
        const valuesArray: WasPrice[] = results.data as WasPrice[];

        for (let i = 0; i < valuesArray.length; i++) {
          valuesArray[i].id = i;
          valuesArray[i].WasPriceOverride = parseFloat(valuesArray[i].WasPriceOverride as unknown as string);
          valuesArray[i].Sku = valuesArray[i].Sku.trim();
        }
        verifyValues(valuesArray);
        setValues(valuesArray);
      },
    });
    event.target.value = "";
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const generateDocument = () => {
    const utcStartDate = startDate.tz(timeZone).utc().format(UTCFormat);
    let utcEndDate;

    if (endDate && endTime) {
      const combinedEndDateTime = endDate.clone().tz(timeZone).hour(endTime.hour()).minute(endTime.minute());
      utcEndDate = combinedEndDateTime.utc().format(UTCFormat);
    } else if (endDate) {
      const endDateTime = moment(endDate);
      utcEndDate = endDateTime.utc().format(UTCFormat);
    }

    if (!utcEndDate) {
      console.error("End Date and Time are not properly set");
      return;
    }

    const localEndDate = moment.utc(utcEndDate).tz(timeZone).format(localDateFormat);
    const localStartDate = moment.utc(utcStartDate).tz(timeZone).format(localDateFormat);

    values.forEach(function (item: WasPriceOverride, index: number) {
      item.StartTime = localStartDate;
      item.EndTime = localEndDate;
      item.EndDate = utcEndDate;
      item.AuthorisedBy = userProfile.displayName;
      item.Comment = comment!;
      item.StartDate = utcStartDate;
      item.Type = type;
    });
    setValues([...values]);
    setGenerated(true);
  };

  const resetValues = () => {
    setComment("");
    setType("Public");
    setValues([]);
    setGenerated(false);
    setEndDate(null);
    setStartDate(null);
    setStartNow(false);
    setCustomStartDate(false);
    setHeaderError("");
  };

  const resetStartDate = () => {
    setStartDate(null);
    setStartNow(false);
    setCustomStartDate(false);
  };

  const postToPricingApi = async () => {
    const jsonArray = Object.values(values);
    setPostingToApi(true);
    try {
      await sendWasPricesAsync(jsonArray, country);
      setSnackbar({
        open: true,
        message: "Was prices override completed successfully",
        severity: "success",
      });
      resetValues();
    } catch (error) {
      let errorMessage = "Error occurred while overriding was prices.";

      if (error.response && typeof error.response.data === "string") {
        errorMessage = error.response.data;
      } else if (error.response && error.response.data) {
        errorMessage = JSON.stringify(error.response.data);
      } else if (error.request) {
        errorMessage = "No response was received from the server.";
      } else {
        errorMessage = error.message;
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      setPostingToApi(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "comment") {
      setComment(value);
      setGenerated(false);
    }
  };

  const [usage, setUsage] = useState<boolean>(() => {
    const savedUsage = localStorage.getItem("showWasPriceUsage");
    if (savedUsage !== null) {
      return JSON.parse(savedUsage);
    }
    return true;
  });

  const handleClick = () => {
    const newUsage = !usage;
    setUsage(newUsage);
    localStorage.setItem("showWasPriceUsage", JSON.stringify(newUsage));
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddSku = (sku, wasPrice) => {
    if (wasPrice !== null) {
      const newValue = {
        id: values.length,
        Sku: sku,
        WasPriceOverride: wasPrice,
      };
      setValues([...values, newValue]);
      handleCloseDialog();
    } else {
      console.error("Was Price is not set or invalid.");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (generated) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [generated]);

  const { noSkusTooltip, generateTooltip } = useTooltipMessages({
    values,
    endDate,
    startDate,
    comment,
    errors,
  });

  if (!hasPermission(UserRoles.PricingWasPrices, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={momentInstance}>
      <Box sx={{ maxWidth: "1400px", margin: "0 auto", boxSizing: "border-box" }}>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            margin: "16px",
          }}
        >
          Was Price Override Generator
        </h2>
        <Stack direction={isSmallScreen ? "column" : "row"} spacing={2} alignItems="stretch">
          <Button fullWidth variant="text" size="small" onClick={handleClick} sx={{ margin: "0px", minWidth: "128px" }}>
            {usage ? <span>Hide Usage</span> : <span>Show Usage</span>}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="small"
            component={Link}
            href="/was-price-template.csv"
            download="was-price-template.csv"
            startIcon={<Download />}
          >
            Template
          </Button>
          <Button
            sx={{
              backgroundColor: values.length === 0 ? "primary.yellow" : "black",
              color: values.length === 0 ? "black" : "white",
              ":hover": {
                bgcolor: "primary.main", // theme.palette.primary.main
                color: "primary.yellow",
              },
            }}
            fullWidth
            variant="contained"
            component="label"
            disabled={values.length !== 0}
          >
            Bulk Upload
            <input type="file" ref={fileInputRef} name="file" onChange={changeHandler} accept=".csv" hidden />
          </Button>
          <Button
            sx={{
              backgroundColor: values.length === 0 ? "primary.yellow" : "black",
              color: values.length === 0 ? "black" : "white",
              ":hover": {
                bgcolor: "primary.main", // theme.palette.primary.main
                color: "primary.yellow",
              },
            }}
            fullWidth
            variant="contained"
            onClick={handleOpenDialog}
            disabled={generated}
          >
            Add Sku
          </Button>
          <Button fullWidth variant="contained" onClick={() => setResetDialogOpen(true)}>
            Reset
          </Button>
        </Stack>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxWidth: "1400px", marginTop: "16px" }}>
          <Grid xs={12} sm={10} md={8} sx={{ maxWidth: "630px", width: "100%" }}>
            <WasPriceUsage isVisible={usage} />
          </Grid>
        </Grid>
        <AddSkuDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onConfirm={handleAddSku} />
        <ResetConfirmationDialog
          open={resetDialogOpen}
          onClose={() => setResetDialogOpen(false)}
          onConfirm={resetValues}
        />
        <TooltipWrapper title={noSkusTooltip}>
          <Grid container spacing={2} justifyContent="center" alignItems="center" pt={6}>
            {headerError && (
              <Grid xs={12}>
                <Alert severity="error">{headerError}</Alert>
              </Grid>
            )}
            <Grid xs={7} md={3}>
              {!customStartDate ? (
                <Stack spacing={2} justifyContent="center" direction="row">
                  <Chip
                    disabled={values.length === 0}
                    variant={startNow ? "filled" : "outlined"}
                    label="Start Now"
                    onClick={() => {
                      setStartNow(true);
                      setCustomStartDate(false);
                      setStartDate(moment());
                      setGenerated(false);
                    }}
                    onDelete={
                      startNow
                        ? () => {
                            setStartNow(false);
                            setCustomStartDate(false);
                            setStartDate(null);
                            setGenerated(false);
                          }
                        : undefined
                    }
                  />
                  {!startNow && (
                    <Chip
                      disabled={values.length === 0}
                      variant="outlined"
                      label="Schedule Start"
                      onClick={() => {
                        setStartNow(false);
                        setCustomStartDate(true);
                        setGenerated(false);
                      }}
                    />
                  )}
                </Stack>
              ) : (
                <DateTimePicker
                  open={startDateOpen}
                  onClose={() => setStartDateOpen(false)} // This is called when the picker requests to be closed
                  onAccept={() => setStartDateOpen(false)}
                  sx={{ width: "100%" }}
                  disabled={values.length === 0}
                  label="Start Time"
                  value={startDate}
                  onChange={(newValue: Moment | null) => {
                    const errorMsg = "Invalid date and time format";

                    if (newValue && newValue.isValid()) {
                      setStartDate(newValue);
                      setGenerated(false);
                      setErrors((prevErrors) => prevErrors.filter((err) => err !== errorMsg));
                    } else {
                      if (!errors.includes(errorMsg)) {
                        setErrors((prevErrors) => [...prevErrors, errorMsg]);
                      }
                    }
                  }}
                  format="DD/MM/YYYY HH:mm"
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setStartDateOpen(true);
                                }}
                                edge="end"
                              >
                                <CalendarIcon />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton onClick={resetStartDate} edge="end">
                                <Close />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      },
                    },
                  }}
                />
              )}
            </Grid>
            <Grid xs={5} md={2}>
              <DatePicker
                sx={{ width: "100%" }}
                disabled={values.length === 0}
                label="End Date"
                value={endDate}
                onChange={(newValue: Moment | null) => {
                  const genericErrorMsg = "End date is invalid";
                  const futureErrorMsg = "End date cannot be before the current date";
                  const minValidDate = moment();
                  let newErrors = [...errors];
                  if (newValue && newValue.isValid()) {
                    newErrors = newErrors.filter((err) => err !== genericErrorMsg);
                  } else {
                    if (!newErrors.includes(genericErrorMsg)) {
                      newErrors.push(genericErrorMsg);
                    }
                  }
                  if (newValue && newValue.isSameOrAfter(minValidDate, "day")) {
                    newErrors = newErrors.filter((err) => err !== futureErrorMsg);
                  } else {
                    if (!newErrors.includes(futureErrorMsg)) {
                      newErrors.push(futureErrorMsg);
                    }
                  }
                  setErrors(newErrors);
                  setGenerated(false);
                  if (newErrors.length === 0) {
                    setEndDate(newValue);
                  }
                }}
                format="DD/MM/YYYY"
                minDate={moment()}
              />
            </Grid>
            <Grid xs={4} md={2}>
              <TimePicker
                disabled={values.length === 0}
                label="End Time"
                value={endTime}
                onChange={(newTime: Moment) => {
                  setEndTime(newTime);
                  setGenerated(false);
                }}
                format="HH:mm"
                minutesStep={1}
              />
            </Grid>
            <Grid xs={5} md={3}>
              <TextField
                required
                fullWidth
                name="comment"
                value={comment}
                disabled={values.length === 0}
                label="Comment"
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={3} md={2}>
              <FormControl fullWidth variant="outlined" disabled={values.length === 0}>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  required
                  disabled={values.length === 0}
                  labelId="type-label"
                  value={type}
                  onChange={(event) => {
                    setType(event.target.value as TypeOption);
                    setGenerated(false);
                  }}
                  label="Type"
                >
                  <MenuItem value="Public">Public</MenuItem>
                  <MenuItem value="Perks">Perks</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </TooltipWrapper>
        <Box display="flex" justifyContent="center" mt={3}>
          <TooltipWrapper title={generateTooltip}>
            <LoadingButton
              loading={postingToApi}
              variant="contained"
              disabled={endDate == null || !comment || !type || errors.length > 0 || startDate == null}
              onClick={!generated ? generateDocument : () => setOpenDialog(true)}
              sx={{
                minWidth: "200px",
                backgroundColor: generated ? "primary.yellow" : "black",
                color: generated ? "black" : "white",
                ":hover": {
                  backgroundColor: generated ? "primary.yellow" : "black",
                  color: generated ? "black" : "white",
                },
              }}
            >
              {!generated ? "Generate" : `Submit for ${type} ${country}`}
            </LoadingButton>
          </TooltipWrapper>
        </Box>
        {/* Table */}
        <Box sx={{ height: "800px", width: "100%", mt: 4 }}>
          <DataGrid
            density="compact"
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fields: ["Sku", "StartDate", "EndDate", "WasPriceOverride", "AuthorisedBy", "Comment"],
                },
              },
            }}
            rows={values}
            sx={{ padding: "0px 32px", maxWidth: "1400px", margin: "auto" }}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
        <WasPriceConfirmationDialog
          perks={type === "Perks"}
          loading={postingToApi}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={async () => {
            setPostingToApi(true);
            await postToPricingApi();
            setPostingToApi(false);
            setOpenDialog(false);
          }}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar((prevState) => ({ ...prevState, open: false }))}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setSnackbar((prevState) => ({ ...prevState, open: false }))}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default WasPricesComponent;
