import { Grid, GridProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";

type InventoryTotalsProps = GridProps & {
  product: GetProductResponse;
  showTitle?: boolean;
};

export const InventoryTotals: React.FC<InventoryTotalsProps> = ({
  product,
  showTitle,
  ...rest
}: InventoryTotalsProps) => {
  return (
    <Grid container {...rest}>
      {showTitle && (
        <Grid item xs={12} component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
          Inventory Totals
        </Grid>
      )}

      <Grid item xs={6} borderRight={0}>
        <Grid container paddingRight={0.5}>
          <Grid item xs={8}>
            Available SOH
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.available}
          </Grid>

          <Grid item xs={8}>
            Total SOH
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.soh}
          </Grid>

          <Grid item xs={8}>
            Purchase Order
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.purchaseOrder}
          </Grid>

          <Grid item xs={8}>
            Customer Order
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.customerOrder}
          </Grid>

          <Grid item xs={8}>
            Repack
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.repack}
          </Grid>

          <Grid item xs={8}>
            Faulty
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.faulty}
          </Grid>

          <Grid item xs={8}>
            Ranged
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.numberOfRangedLocations}
          </Grid>

          <Grid item xs={8}>
            Display
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.display}
          </Grid>

          <Grid item xs={8}>
            Buffer
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.buffer}
          </Grid>

          <Grid item xs={8}>
            SOR
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.jbrl}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} borderRight={0}>
        <Grid container paddingLeft={0.5}>
          <Grid item xs={8}>
            In Transit
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.inTransit}
          </Grid>

          <Grid item xs={8}>
            % In Stock
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.percentRangedLocationsInStock}%
          </Grid>

          <Grid item xs={8}>
            Delivery
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.availableForDelivery}
          </Grid>

          <Grid item xs={8}>
            Click & Collect
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.availableForClickAndCollect}
          </Grid>

          <Grid item xs={8}>
            Cash & Carry
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.availableForCashAndCarry}
          </Grid>

          <Grid item xs={8}>
            Online WH Avail
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.availableFromOnlineWarehouse}
          </Grid>

          <Grid item xs={8}>
            Bulky WH Avail
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.availableFromBulkyWarehouse}
          </Grid>

          <Grid item xs={8}>
            Online Avail
          </Grid>
          <Grid item xs={4}>
            {product.inventoryTotals.onlineAvailable}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
