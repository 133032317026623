import { useState, useEffect, useCallback, useContext } from "react";
import { authProvider, AuthenticationState } from "./infrastructure/auth/AuthProvider";
import { getUserProfileAsync, getUserPhotoAsync } from "./infrastructure/auth/AuthUtils";
import PageContext from "./components/Context/PageContext";
import { Splash } from "./components/Loading/Splash";
import { Lock } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useCountry, COUNTRIES, Country } from "./components/Context/CountryContext";
import AppLogo from "./images/AppLogo";
import { AppProps } from "./App";
import { UserRoles } from "./infrastructure/auth/UserRoles";
import { hasPermission } from "./infrastructure/auth/PermissionUtils";
import { setRouterPath } from "./utils/consts";

const useStyles = makeStyles((theme) => ({
  noAccessIcon: {
    color: theme.palette.primary.white,
    fontSize: 64,
  },
}));

const getDefaultCountryBasedOnRoles = (roles): Country => {
  if (roles.includes(UserRoles.AccessAU)) {
    return COUNTRIES.AU;
  }
  if (roles.includes(UserRoles.AccessNZ)) {
    return COUNTRIES.NZ;
  }
  // fallback to AU if no country specific access roles have been assigned, but the user has been assigned access to Product UI in Enterprise Applications
  return COUNTRIES.AU;
};

const AzureADApp = (Component: React.ComponentType<AppProps>) => {
  const AzureAdAppComponent = () => {
    const classes = useStyles();

    const [authenticationState, setAuthenticationState] = useState(authProvider.authenticationState);
    const [account, setAccount] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [userPhoto, setUserPhoto] = useState(null);
    const [error, setError] = useState(null);

    const [contextData, setContextData] = useContext(PageContext);
    const { setCountry, setCanSwitchCountries } = useCountry();

    const loginCallBack = useCallback(() => {
      if (authenticationState !== AuthenticationState.UnAuthenticated || error) {
        return;
      }

      setAuthenticationState(AuthenticationState.InProgress);
      authProvider.login();
    }, [authenticationState, error]);

    const logout = () => {
      authProvider.logout();
    };

    useEffect(() => {
      function handleResponse(tokenResponse) {
        if (tokenResponse) {
          setAccount(tokenResponse.account);
          setAccessToken(tokenResponse.accessToken);
          setAuthenticationState(AuthenticationState.Authenticated);
        } else {
          loginCallBack();
        }
      }

      authProvider
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((authError) => {
          setAuthenticationState(AuthenticationState.UnAuthenticated);
          console.error("An error occurred while attempting to log in", authError);
          setError(authError.errorMessage);
        });
    }, [loginCallBack]);

    useEffect(() => {
      if (!accessToken) {
        return;
      }

      const setupUserDetailsAsync = async () => {
        const userProfileFromGraph = await getUserProfileAsync(accessToken);
        const userProfileWithRoles = { ...userProfileFromGraph, roles: account.idTokenClaims.roles };

        setUserProfile(userProfileWithRoles);
        setContextData({ ...contextData, userProfile: userProfileWithRoles, accessToken });

        const photo = await getUserPhotoAsync(accessToken);
        setUserPhoto(photo);
      };

      setupUserDetailsAsync();
    }, [accessToken]);

    useEffect(() => {
      if (!account) {
        return;
      }

      const roles = account.idTokenClaims.roles;
      
      if(!roles.some(o => o === UserRoles.AccessAU || o === UserRoles.AccessNZ)) {
        roles.push(UserRoles.AccessAU);
      }
      
      const defaultCountry = getDefaultCountryBasedOnRoles(roles);
      const selectedCountry = localStorage.getItem("country") || defaultCountry;
      setCountry(selectedCountry as Country);

      if (!hasPermission(`Access.${selectedCountry}`, roles)) {
        setRouterPath(defaultCountry.toLowerCase())
        window.location.href = `/${defaultCountry.toLowerCase()}`;
      }

      const canSwitch = roles.includes(UserRoles.AccessAU) && roles.includes(UserRoles.AccessNZ);
      setCanSwitchCountries(canSwitch);
    }, [account, setCountry]);

    const isLoaded = () => contextData !== null && Object.keys(contextData).length > 0;

    if (!isLoaded() || authenticationState === AuthenticationState.InProgress) {
      return <Splash title="Product App" secondaryText="Authenticating..." icon={<AppLogo />} showProgress={true} />;
    }

    if (error?.includes("assigned to a role")) {
      return (
        <Splash
          title="Product App"
          icon={<Lock className={classes.noAccessIcon} />}
          secondaryText="No Access! Contact IT Engineering via Zendesk."
          showProgress={false}
        />
      );
    }

    return <Component userProfile={userProfile} userPhoto={userPhoto} error={error} logout={logout} />;
  };

  return AzureAdAppComponent;
};

export default AzureADApp;
