import type { FC } from "react";
import { useDropzone } from "react-dropzone";
import type { Accept, DropzoneOptions } from "react-dropzone";
import { Box, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface FileDropzoneProps extends DropzoneOptions {
  dropZoneText: string;
  accept: Accept;
}

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { accept, disabled, dropZoneText, maxFiles = 1, maxSize, minSize, onDrop } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    multiple: false,
    disabled,
  });

  return (
    <>
      {disabled ? (
        <LoadingButton loading={true} sx={{ height: "400px" }} />
      ) : (
        <div>
          <Box
            sx={{
              minHeight: "200px",
              alignItems: "center",
              border: 1,
              borderRadius: 1,
              borderColor: "divider",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              outline: "none",
              p: 6,
              backgroundColor: "background.paper",
              ...(isDragActive && {
                backgroundColor: "action.active",
                opacity: 0.5,
              }),
              "&:hover": {
                backgroundColor: "action.hover",
                cursor: "pointer",
                opacity: 0.5,
              },
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Box
              sx={{
                "& img": {
                  width: 80,
                },
              }}
            >
              <img alt="Select file" src="/static/undraw_add_file2_gvbb.svg" />
            </Box>
            <Box sx={{ p: 2 }}>
              <p>{dropZoneText}</p>
              <Box sx={{ mt: 2 }}>
                <p>
                  {`Drop file or${maxFiles && maxFiles === 1 ? "" : "s"}`}{" "}
                  <Link color="primary" underline="always">
                    browse
                  </Link>{" "}
                  your computer.
                </p>
                <p>Supported file types: .png</p>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default FileDropzone;
