import { useState } from "react";
import { useCountry } from "src/components/Context/CountryContext";
import { patchProductDetailAsync } from "src/infrastructure/http/appApiService";
import { GetProductResponse } from "src/models/product";
import { SnackbarAlert } from "src/components/SnackbarAlert/SnackbarAlert";
import { Grid } from "@mui/material";
import _ from "lodash";
import { Seo } from "src/models/product/Seo";
import Checkbox from "@jbhi-fi/lanyard-ui/components/Checkbox/Checkbox";
import TextField from "@jbhi-fi/lanyard-ui/components/TextField/TextField";
import TextArea from "@jbhi-fi/lanyard-ui/components/TextArea/TextArea";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: theme.spacing(1),
  },
  textbox: {
    "& textarea": { paddingTop: theme.spacing(1.5) },
  },
}));

type ProductManagementTabProps = {
  product: GetProductResponse;
};

export const ProductManagementTab: React.FC<ProductManagementTabProps> = ({ product }: ProductManagementTabProps) => {
  const classes = useStyles();
  const [seoState, setSeoState] = useState<Seo>(_.cloneDeep(product.seo));

  const [loading, setLoading] = useState(false);
  const { country } = useCountry();

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
  });

  const onSaveClick = async () => {
    try {
      setLoading(true);
      window.addEventListener("beforeunload", handleLeavePage);
      product.seo = seoState;
      await patchProductDetailAsync(product, country);
      window.removeEventListener("beforeunload", handleLeavePage);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAlertState({
        open: true,
        message: `Error while updating product, please try again later shortly. If issue persists please contact Support with error. Error message: ${e.message}`,
      });
    }
  };

  const handleLeavePage = (e) => {
    e.preventDefault();
    const confirmationMessage = "Changes you made may not be saved.";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const onDescriptionChange = (event) => {
    setSeoState({ ...seoState, description: event.target.value });
  };

  const onAutoGenerateChange = (event) => {
    setSeoState({ ...product.seo, autoGenerated: event.target.checked });
  };

  const onTitleChange = (event) => {
    setSeoState({ ...seoState, title: event.target.value });
  };
  return (
    <Grid container spacing={0} justifyContent="center" marginTop={5}>
      <Grid item width="50%" border={(theme) => `solid 1px ${theme.palette.grey[400]}`}>
        <Grid container paddingTop={4} paddingX={5}>
          <Grid item xs={12} paddingBottom={2}>
            <h2>Search Engine Optimisation (SEO)</h2>
          </Grid>
          <Grid>
            <Checkbox
              name={"SEOcheckbox"}
              label={"Auto Generated"}
              onChange={onAutoGenerateChange}
              checked={seoState.autoGenerated}
            />
          </Grid>

          <Grid item className={classes.title} xs={12}>
            <label>Title</label>
            <TextField
              id={"defaultTextField"}
              name={"TitleTextField"}
              disabled={seoState.autoGenerated}
              placeholder={seoState.title}
              onChange={onTitleChange}
            />
          </Grid>
          <Grid item className={classes.title} xs={12}>
            <label>Description</label>
            <TextArea
              id={"defaultTextArea"}
              name={"DescriptionTextArea"}
              placeholder={seoState.description}
              disabled={seoState.autoGenerated}
              onChange={onDescriptionChange}
              rows={3}
              className={classes.textbox}
            />
          </Grid>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={onSaveClick}
            loading={loading}
            style={{ marginBottom: 20, marginTop: 10 }}
          >
            Save
          </LoadingButton>

          <SnackbarAlert
            open={alertState.open}
            onClose={() => setAlertState({ open: false, message: "" })}
            message={alertState.message}
            severity="error"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductManagementTab;
