export enum MessageSubtypes {
  Warning = "Warning",
  General = "General",
  Important = "Important",

  JBGiftCard = "JBGiftCard",
  Cashback = "Cashback",
  CashCard = "CashCard",
  Competition = "Competition",
  SignedOffer = "SignedOffer",
  PreOrderBonus = "PreOrderBonus",
  SupplierRedemption = "SupplierRedemption",
  BundleBuy = "BundleBuy",
  TradeIn = "TradeIn",
  BYOPhonePlan = "ByoPhonePlan",
  Subscription = "Subscription",
  WarrantyExtension = "WarrantyExtension",

  Delivery = "Delivery",
  Installation = "Installation",
  SetUp = "SetUp",
}

export const messageSubtypesOptions = [
  { value: MessageSubtypes.Warning, label: "Warning [RHS-Orange]", defaultPriority: 1, defaultOnlineTag: "" },
  { value: MessageSubtypes.General, label: "General [LongDesc-Blue]", defaultPriority: 2, defaultOnlineTag: "" },
  { value: MessageSubtypes.Important, label: "Important [LongDesc-Orange]", defaultPriority: 3, defaultOnlineTag: "" },
];

export const specialOfferSubtypesOptions = [
  { value: MessageSubtypes.JBGiftCard, label: "JB gift Card", defaultPriority: 1, defaultOnlineTag: "JB Gift Card" },
  { value: MessageSubtypes.Cashback, label: "Cashback", defaultPriority: 2, defaultOnlineTag: "Cashback" },
  { value: MessageSubtypes.CashCard, label: "Cash card", defaultPriority: 3, defaultOnlineTag: "Bonus Offer" },
  { value: MessageSubtypes.Competition, label: "Competition", defaultPriority: 4, defaultOnlineTag: "Competition" },
  { value: MessageSubtypes.SignedOffer, label: "Signed Offer", defaultPriority: 5, defaultOnlineTag: "Signed Offer" },
  {
    value: MessageSubtypes.PreOrderBonus,
    label: "Bonus Offer",
    defaultPriority: 6,
    defaultOnlineTag: "Bonus Offer",
  },
  {
    value: MessageSubtypes.SupplierRedemption,
    label: "Supplier redemption",
    defaultPriority: 7,
    defaultOnlineTag: "Bonus Offer",
  },
  { value: MessageSubtypes.BundleBuy, label: "Bundle buy", defaultPriority: 10, defaultOnlineTag: "" },
  { value: MessageSubtypes.TradeIn, label: "Trade-in", defaultPriority: 11 },
  { value: MessageSubtypes.BYOPhonePlan, label: "BYO / Phone plan", defaultPriority: 13, defaultOnlineTag: "" },
  { value: MessageSubtypes.Subscription, label: "Subscription", defaultPriority: 15, defaultOnlineTag: "" },
  { value: MessageSubtypes.WarrantyExtension, label: "Warranty extension", defaultPriority: 17, defaultOnlineTag: "" },
];

export const servicesSubtypesOptions = [
  { value: MessageSubtypes.Delivery, label: "Delivery", defaultPriority: 1, defaultOnlineTag: "" },
  { value: MessageSubtypes.Installation, label: "Installation", defaultPriority: 2, defaultOnlineTag: "" },
  { value: MessageSubtypes.SetUp, label: "Set-Up", defaultPriority: 3, defaultOnlineTag: "" },
];

export const allSubtypesOptions = [
  ...messageSubtypesOptions,
  ...specialOfferSubtypesOptions,
  ...servicesSubtypesOptions,
];
