import * as yup from "yup";
import moment from "moment";

export const isGreaterThanOrEqual = (errorMessage: string) => {
  return ([otherFieldsDate, otherFieldsTime, thisFieldsTime]: string[], schema: yup.Schema): yup.Schema => {
    return schema.test({
      test: (thisFieldsValue: string) => {
        if (!thisFieldsValue || !otherFieldsDate) {
          return true; // Let required validation handle this
        }

        const thisFieldDateTime = moment(thisFieldsValue + " " + thisFieldsTime, "DD/MM/YYYY HH:mm:ss");
        const otherFieldDateTime = moment(otherFieldsDate + " " + otherFieldsTime, "DD/MM/YYYY HH:mm:ss");

        if (!thisFieldDateTime.isValid() || !otherFieldDateTime.isValid()) {
          return true;
        }

        return thisFieldDateTime.isSame(otherFieldDateTime) || thisFieldDateTime.isAfter(otherFieldDateTime);
      },
      message: errorMessage,
    });
  };
};
