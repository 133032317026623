import React from "react";
import { styled } from "@mui/material";

const P = styled("p")`
  color: ${({ theme }) => theme.palette.error.main}};
`;

type ValidationLabelProps = {
  errorMessage?: React.ReactNode;
};

export const ValidationLabel: React.FC<ValidationLabelProps> = ({ errorMessage }) => {
  if (!errorMessage) {
    return <></>;
  }

  return <P>{errorMessage}</P>;
};
