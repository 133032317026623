import { MessageSubtypes } from './MessageSubtypes';

export const subTypeLabels: { [key in MessageSubtypes]: string } = {
  [MessageSubtypes.Warning]: "Warning [RHS-Orange]",
  [MessageSubtypes.General]: "General [LongDesc-Blue]",
  [MessageSubtypes.Important]: "Important [LongDesc-Orange]",
  [MessageSubtypes.JBGiftCard]: "JB gift Card",
  [MessageSubtypes.Cashback]: "Cashback",
  [MessageSubtypes.CashCard]: "Cash card",
  [MessageSubtypes.Competition]: "Competition",
  [MessageSubtypes.SignedOffer]: "Signed Offer",
  [MessageSubtypes.PreOrderBonus]: "Bonus Offer",
  [MessageSubtypes.SupplierRedemption]: "Supplier redemption",
  [MessageSubtypes.BundleBuy]: "Bundle buy",
  [MessageSubtypes.TradeIn]: "Trade-in",
  [MessageSubtypes.BYOPhonePlan]: "BYO / Phone plan",
  [MessageSubtypes.Subscription]: "Subscription",
  [MessageSubtypes.WarrantyExtension]: "Warranty extension",
  [MessageSubtypes.Delivery]: "Delivery",
  [MessageSubtypes.Installation]: "Installation",
  [MessageSubtypes.SetUp]: "Set-Up",
};

export const getSubTypeLabel = (subType: MessageSubtypes): string => {
  return subTypeLabels[subType] || subType;
};